import React, {useState} from 'react'
import { Button, Modal, Form, Input, Container, CheckboxProps, InputOnChangeData } from 'semantic-ui-react'
import { useErrorDisplay } from './ErrorDisplay'
import RiskFactorsApi from '../apiClients/RiskFactorsApi'
import RuleEditorPopup from './RuleEditorPopup'
import { IsEmpty, RealNumberCharactersOnly } from '../Helpers'

const RiskFactorRuleEditorPopup = ({ targetId = null, onAddEditAsync }) => {
    const scaffold = {
        Description: '',
        BooleanRule: '',
        IsLab: false,
        DisplayToClient: true,        
        Type1Weight: null,        
        Type2Weight: null,        
        Type15Weight: null,        
        Type3Weight: null,        
        Type4Weight: null,        
        Type5Weight: null
    }
    
    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState(false);
    const [modified, setModified] = useState(false);
    const { ErrorDisplay, setError } = useErrorDisplay();

    const [targetData, setTargetData] = useState({...scaffold});

    const api = new RiskFactorsApi();

    //Setup callback if it is passed in, default if not
    const onAddEditAsyncCallback = IsEmpty(onAddEditAsync) ? async () => { } : onAddEditAsync;

    const isEdit = !IsEmpty(targetId);

    

    const loadData = async () => {
        try
        {
            setBusy(true);
            if(isEdit)
            {
                //Go get the data here
                let data = await api.GetRiskFactorRule(targetId);
                setTargetData(data);
            }
            else
            {
                setTargetData({...scaffold});
            }

        }
        catch(error)
        {
            setError(true, error, "Failed to load the attribute data")
        }
        finally
        {
            setBusy(false);
        }
    }
        
    const handleSave = async () => {
        try
        {
            let id = targetId;
            setBusy(true);
            if(isEdit)
            {
                await api.UpdateRiskFactorRule(id, getSaveObject());
            }
            else
            {
                id = await api.AddRiskFactorRule(getSaveObject());
            }

            setOpen(false);
            setModified(false);
            await onAddEditAsyncCallback(id);
        }
        catch(error)
        {
            setError(true, error, "Failed to save the data")
        }
        finally
        {
            setBusy(false);
        }
    }

    function getSaveObject()
    {
        const ret = {
            ...targetData,
            Type1Weight: IsEmpty(targetData.Type1Weight) ?  0 : parseFloat(targetData.Type1Weight),
            Type2Weight: IsEmpty(targetData.Type2Weight) ?  0 : parseFloat(targetData.Type2Weight),
            Type15Weight: IsEmpty(targetData.Type15Weight) ?  0 : parseFloat(targetData.Type15Weight),
            Type3Weight: IsEmpty(targetData.Type3Weight) ?  0 : parseFloat(targetData.Type3Weight),
            Type4Weight: IsEmpty(targetData.Type4Weight) ?  0 : parseFloat(targetData.Type4Weight),
            Type5Weight: IsEmpty(targetData.Type5Weight) ?  0 : parseFloat(targetData.Type5Weight)
        };
        console.log(ret);
        return ret;
    }

    const canSave: () => boolean = () => {
        let ret = !busy;
        ret = ret && modified;
        ret = ret && targetData.Description.length > 2
        ret = ret && targetData.BooleanRule.length > 2

        return ret;
    }

    const handleChange: (e: any, data: InputOnChangeData) => void = (e, data) => {
        setModified(true);
        setTargetData({...targetData, [data.name]: data.value });
    }

    const handleCheckboxChange: (e: any, data: CheckboxProps) => void = (e, data) => {
        setModified(true);
        setTargetData({...targetData, [data.name]: data.checked });
    }

    const handleNumericChange: (e: any, data: InputOnChangeData) => void = (e, data) => {
        let newData = RealNumberCharactersOnly(data.value);

        setModified(true);
        setTargetData({...targetData, [data.name]: newData });
    }
    
    return (
        <>
            <ErrorDisplay />
            <Modal
                open={open}
                onOpen={async () => {
                    loadData();
                    setBusy(false);
                    setOpen(true)
                }}
                trigger={isEdit ? <Button basic loading={busy} icon={{ name: 'edit outline', color: 'green' }} /> : <Button content='New Rule' loading={busy} labelPosition='left' icon='add' color='blue' compact basic /> }
            >
                <Modal.Header>{isEdit ? 'Edit' : 'Create New'}</Modal.Header>
                <Modal.Content image scrolling>
                    <Container fluid>
                        <Form loading={busy}>
                            <Form.Input label='Description' name='Description' placeholder='Description' value={targetData.Description ?? ''} onChange={handleChange} />
                            <Form.Group>
                                <Form.Checkbox label='Display to Client?' name='DisplayToClient' checked={targetData.DisplayToClient} onChange={handleCheckboxChange} />
                                <Form.Checkbox label='Lab?' name='IsLab' checked={targetData.IsLab} onChange={handleCheckboxChange} />
                            </Form.Group>
                            <Form.Field label='BooleanRule' control={() => (
                                <div className='ui action input'>
                                    <Input value={targetData.BooleanRule ?? ''} readOnly attached='right' />
                                    <RuleEditorPopup shouldBeBoolean={true} onAddEditAsync={async (value) => handleChange(null, { name: 'BooleanRule', value: value })} value={targetData.BooleanRule ?? ''} btnProps={{ attached: 'left', compact: true }} />
                                </div>
                            )} />
                            <Form.Input label='Inflammatory' name='Type1Weight' placeholder='Enter weight...' value={targetData.Type1Weight ?? ''} onChange={handleNumericChange} />
                            <Form.Input label='Atrophic' name='Type2Weight' placeholder='Enter weight...' value={targetData.Type2Weight ?? ''} onChange={handleNumericChange} />
                            <Form.Input label='Glycotoxic' name='Type3Weight' placeholder='Enter weight...' value={targetData.Type3Weight ?? ''} onChange={handleNumericChange} />
                            <Form.Input label='Toxic' name='Type15Weight' placeholder='Enter weight...' value={targetData.Type15Weight ?? ''} onChange={handleNumericChange} />
                            <Form.Input label='Vascular' name='Type4Weight' placeholder='Enter weight...' value={targetData.Type4Weight ?? ''} onChange={handleNumericChange} />
                            <Form.Input label='Traumatic' name='Type5Weight' placeholder='Enter weight...' value={targetData.Type5Weight ?? ''} onChange={handleNumericChange} />
                        </Form>
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Save"
                        onClick={handleSave}
                        positive
                        disabled={!canSave()}
                    />
                    <Button color='black' basic onClick={() => setOpen(false)} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default RiskFactorRuleEditorPopup;
