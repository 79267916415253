import React, { useEffect, useState } from 'react'
import { Checkbox, Form, Header, Icon, Segment, Button, Divider, Card, Message } from 'semantic-ui-react'
import { useErrorDisplay } from '../components/ErrorDisplay';
import { useLocation } from "react-router-dom";
import HealthHistoryRequestApi from '../apiClients/HealthHistoryRequestApi';
import { HealthHistoryRequestStatus } from '../Enums';
import ExecuteOnConfirm from '../components/ExecuteOnConfirm';
import { PatientAttributeValue } from '../apiClients/PatientApi';
import { useRef } from 'react';
import { GuidEmpty, IsEmpty } from '../Helpers';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const HealthHistoryQuestionnaire = () => {
    const query = useQuery();
    const [notes, setNotes] = useState<string>(null);
    const [completedBy, setCompletedBy] = useState(null);
    const [displayText, setDisplayText] = useState<string>(null);
    const [history, setHistory] = useState<PatientAttributeValue[]>([]);
    const [busy, setBusy] = useState(true);
    const initialized = useRef(false);
    const [loaded, setLoaded] = useState(false);
    const [modified, setModified] = useState(false);
    const [requestStatus, setRequestStatus] = useState('');
    const [savedVisible, setSavedVisible] = useState(false);
    
    const { ErrorDisplay, setError } = useErrorDisplay();
    const api = new HealthHistoryRequestApi();

    const healthHistoryRequestId = query.get("id");

    const handleChange = (e, data) => {
        const newHistory = [ ...history]
        newHistory[newHistory.findIndex(x => x.AttributeDefinitionId == data.name)].Data = data.checked;
        setHistory(newHistory);
        setModified(true);
        setSavedVisible(false);
    };

    const loadData = async () => {        
        try
        {
            setBusy(true);
            var status = await api.GetHealthHistoryRequestStatus(healthHistoryRequestId);
            setRequestStatus(status);
            if(status === HealthHistoryRequestStatus.InProgress)
            {
                var data = await api.GetHealthHistoryAttributeQuestions(healthHistoryRequestId);
                console.log(`Getting Health History Questions and Answers for request id: ${healthHistoryRequestId}`);
                console.log(data.Attributes);
                data.Attributes.forEach(h => {
                    h['Data'] = h['ValueString']?.toLowerCase() === 'true';
                });
                setHistory(data.Attributes);
                setNotes(data.Notes);
                setDisplayText(data.DisplayText);
                setCompletedBy(data.CompletedBy);
            }
            
            setLoaded(true);
            
        }
        catch(error)
        {
            setError(true, error, "Could not get the health history questions");
        }
        finally
        {
            setBusy(false);
        }
    }

    const handleSave = async () => {
        let outItems = [];
        
        try
        {
            if(requestStatus !== HealthHistoryRequestStatus.InProgress)
                return;
            setBusy(true);           
            
            history.forEach(x => outItems.push({ AttributeValueOrDefinitionId: (!x.Id || x.Id === GuidEmpty ? x.AttributeDefinitionId : x.Id), DataValue: `${x.Data}` }));
            console.log("Saving Health History");
            console.log(outItems);
            await api.UpdateHealthHistoryRequest(healthHistoryRequestId, { Notes: notes, Attributes: outItems, CompletedBy: completedBy }, false);
            await loadData();
            setModified(false);
            setSavedVisible(true);
        }
        catch(ex)
        {
            setError(true, ex, "Unable to add/modify health history");
        }
        finally
        {
            setBusy(false);
        }
    };

    const handleSaveAndComplete = async () => {
        let outItems = [];
        
        try
        {
            if(requestStatus !== HealthHistoryRequestStatus.InProgress)
                return;
            if(IsEmpty(completedBy))
            {
                setError(true, "Please fill out the field specifying who is filling out the form", "Unable to complete the form");
                return;
            }
            setBusy(true);
             
            history.forEach(x => outItems.push({ AttributeValueOrDefinitionId: (!x.Id || x.Id == GuidEmpty ? x.AttributeDefinitionId : x.Id), DataValue: `${x.Data}` }));
            await api.UpdateHealthHistoryRequest(healthHistoryRequestId, { Notes: notes, Attributes: outItems, CompletedBy: completedBy }, true);
            setRequestStatus(HealthHistoryRequestStatus.Completed);
            setHistory([]);
            setSavedVisible(false);
            console.log("Saved and completed Health History");
        }
        catch(ex)
        {
            setError(true, ex, "Unable to add/modify health history");
        }
        finally
        {
            setBusy(false);
        }
    };

    function ItemDisplay()
    {
        let cat = '';
        return (history == null || history.length <= 0) ? null : history.map(item => {
            if(item.SectionName != cat)
            {
                cat = item.SectionName;
                
                return (
                    <Form.Field as='div' key={item.AttributeDefinitionId}>
                        <Header as='h3' style={{ marginTop: '30px'}}>
                            {cat}
                            <Header.Subheader>Instructions: Please Check all that apply</Header.Subheader>
                        </Header>
                        <Checkbox name={item.AttributeDefinitionId} label={item.Question} checked={item.Data} onChange={handleChange} />
                    </Form.Field>
                )
            }
            return (
                <Form.Field as='div' key={item.AttributeDefinitionId}>
                    <Checkbox name={item.AttributeDefinitionId} label={item.Question} checked={item.Data} onChange={handleChange} />
                </Form.Field>
            )
        })
    }
    function canSave(preQualification: boolean) : boolean
    {
        let ret = preQualification;
        ret = ret && !busy;

        return ret;
    }

    useEffect(() => {
        if(initialized.current || IsEmpty(healthHistoryRequestId))
            return;   
        initialized.current = true;
             
        loadData().then(() => {
            
            setSavedVisible(false);
        });
      },[]);
      
    function StatusDisplay({ content, error = false })
    {
        return (
            <>
                <Header as='h2' color={error ? 'red' : 'blue'} style={{ marginTop: '200px', textAlign: 'center' }}>{content}</Header>
            </>
        );
    }
    
    return (
        <>
            <ErrorDisplay />            
            {requestStatus === HealthHistoryRequestStatus.InProgress ? (
                            <Segment loading={busy}  style={{ minWidth: '300px', maxWidth: '1200px'}}>                                
                                <Header as='h1'>
                                    <Icon name='history' />
                                    <Header.Content>
                                        Health History Questionnaire
                                        <Header.Subheader>Please check all that apply and submit when finished</Header.Subheader>
                                    </Header.Content>
                                </Header>
                                <h2>Client Initials: <pre style={{ display: 'inline-block', color: 'blue', margin: '0px' }}>{displayText}</pre></h2>
                                <Divider />
                                <Card fluid style={{ marginTop: '25px'}}>
                                            <Card.Content>                                    
                                                <Card.Header>Instructions</Card.Header>
                                            </Card.Content>
                                            <Card.Content>
                                                <ul>
                                                    <li>If any part of a statement applies to the participant, please check the associated box</li>
                                                    <li>You may click the "Save Progress" button at any time to save your current progress</li>
                                                    <li>If you have saved your progress, you can close this browser and come back to finish at any time</li>
                                                    <li>When you have finished answering this Questionnaire, please click the "Save and Complete" button to submit your Questionnaire</li>
                                                </ul>
                                            </Card.Content>
                                        </Card>
                                <Form>
                                    <Form.Input label='Who is filling out this form?' placeholder='Please put your full name here' value={completedBy ?? ''} onChange={(e, data) => {setCompletedBy(data.value); setModified(true);}} required error={IsEmpty(completedBy)} />
                                    <br />
                                    {ItemDisplay()}
                                    <br />
                                    <Form.TextArea name='notes' label='In the text area below, please list any additional medications, supplements, or details about your health that may be relative to your cognitive health' value={notes ?? ''} placeholder='Please fill out any comments you have for the clinician' onChange={(e, data) => { setModified(true); setNotes(`${data.value}`); }} />                    
                                </Form>
                                <br />
                                <br /> 
                                    <Button content='Save Progress' color='green' icon='bolt' disabled={!canSave(modified)} onClick={handleSave} />
                                    <ExecuteOnConfirm displayText='Have you answered all the questions and want to submit this questionnaire?' trigger={<Button content='Save and Complete' color='blue' icon='check' disabled={!canSave(true)} />} executeAsync={handleSaveAndComplete} />
                                {savedVisible ? (
                                    <Message positive>
                                        <Message.Header>Changes Saved</Message.Header>
                                        <p>The changes were successfully Saved.</p>
                                    </Message>
                                ) : null } 
                            </Segment>
            )
            : requestStatus === HealthHistoryRequestStatus.Completed ? <StatusDisplay content='This questionnaire has been completed!  Thank you!' />
            : requestStatus === HealthHistoryRequestStatus.Expired ? <StatusDisplay content={<>This questionnaire has expired! <br /><br />  Please contact A Mind For All Seasons to get another link!</>} error />
            : loaded ? <StatusDisplay content={<>Unknown Error! <br /> <br /> Please contact A Mind For All Seasons to get another link!</>} error /> : null }            
        </>
    )
}

export default HealthHistoryQuestionnaire