import React from 'react'

import { Table } from 'semantic-ui-react'

const UserInfo = ({keycloak}) => {
  let { email } = keycloak['idTokenParsed'] ?? {};
  
  let content = <div />;
  if (keycloak.authenticated)
  {
    
    content = (
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='2'>User Token Info</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Email</Table.Cell>
              <Table.Cell>{email}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ID Token</Table.Cell>
              <Table.Cell><p style={{width: 800, wordBreak: 'break-all' }}>{keycloak?.idToken ?? ''}</p></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Access Token</Table.Cell>
              <Table.Cell><p style={{width: 800, wordBreak: 'break-all' }}>{keycloak?.token ?? ''}</p></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
    )
  }
      
  return content;
}

export default UserInfo
