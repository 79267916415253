import React, { createRef, useEffect, useState } from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import HealthHistoryDisplay from './HealthHistoryDisplay'
import PatientPersonalInfoDisplay from './PatientPersonalInfoDisplay'
import PatientRiskFactorDisplay from './PatientRiskFactorDisplay'
import PatientCharacteristicsDisplay from './PatientCharacteristicsDisplay'
import LabsDisplay from './LabsDisplay'
import PatientDocumentDisplay from './PatientDocumentDisplay'
import { Roles } from '../Enums'
import { useWindowDimensions } from './WindowDimensions'
import { OnlyForRole } from '../Helpers'
import PatientOrderDisplay from './PatientOrderDisplay'

export interface PatientDetailProps
{
    patientId: string;
    showOrders?: boolean;
}
const PatientDetail = ({ patientId, showOrders = true }) => {
    const [width, setWidth] = useState<number>(0);
    const divRef = createRef<HTMLDivElement>();
    const dimChanged = useWindowDimensions(divRef);
    
    const getColumns = () => {
        
        if( width < 1000)
            return 1;
        else if(width < 1600)
            return 2;
        else
            return 3;
    }
    useEffect(() => {
        setWidth(divRef.current.offsetWidth)
    }, [dimChanged]);
    return patientId == null ? (<Segment placeholder></Segment>) : ( 
        <div ref={divRef}>
            <Grid columns={getColumns()} doubling stackable padded stretched>            
                {OnlyForRole(Roles.client_info_view, <Grid.Column ><PatientPersonalInfoDisplay patientId={patientId}  /></Grid.Column>)}
                {OnlyForRole(Roles.riskfactors_view, <Grid.Column ><PatientRiskFactorDisplay patientId={patientId}  /></Grid.Column>)}
        
                {OnlyForRole(Roles.client_characteristics_view, <Grid.Column><PatientCharacteristicsDisplay patientId={patientId}  /></Grid.Column>)}
                {OnlyForRole(Roles.documents_view, <Grid.Column ><PatientDocumentDisplay patientId={patientId}  /></Grid.Column>)}
            
                {OnlyForRole(Roles.healthhistory_view, <Grid.Column ><HealthHistoryDisplay patientId={patientId}  /></Grid.Column>)}
                {OnlyForRole(Roles.labs_view, <Grid.Column ><LabsDisplay patientId={patientId}  /></Grid.Column>)}
                {showOrders ? OnlyForRole(Roles.orders_view, <Grid.Column><PatientOrderDisplay patientId={patientId} /></Grid.Column>) : null}
            </Grid>
        </div>
    )
}

export default PatientDetail;