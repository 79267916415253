import React, {useState, useEffect, useRef } from 'react'
import { Form, Message, Button, Divider, Icon, Header, Segment, InputOnChangeData, DropdownProps } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import PatientApi, { PatientData } from '../apiClients/PatientApi';
import GlobalVariables from '../GlobalVariables'
import { useErrorDisplay } from './ErrorDisplay'
import { ClientEvents, Roles } from '../Enums'
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';
import { GuidEmpty, HasRole, IsEmpty } from '../Helpers';
import OrganizationApi, { NameIdSlim } from '../apiClients/OrganizationApi';

const PatientPersonalInfoDisplay = ({ patientId }) => {
    const [originalPatient, setOriginalPatient] = useState(null);
    const [currentPatient, setCurrentPatient] = useState(null);
    const initialized = useRef<boolean>(false);
    const [messageVisible, setMessageVisible] = useState(false);
    const [busy, setBusy] = useState(true);
    const [message, setMessage] = useState('')
    const [currentPatientModified, setCurrentPatientModified] = useState(false);
    const [ organizations, setOrganizations] = useState<NameIdSlim[]>([]);
    const [ locations, setLocations] = useState<NameIdSlim[]>([]);
    const { ErrorDisplay, setError } = useErrorDisplay();

    const api = new PatientApi();

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        let temp = Object.assign({}, currentPatient);

        let val = data.value;
       
        temp = Object.assign(temp, { [data.name]: val });
        if(data.name === 'OrganizationId')
        {
            try
            {
                setBusy(true);
                var locList = await new OrganizationApi().GetLocationList(val);
                setLocations(locList);
            }
            catch(error)
            {
                setError(true, error, "Failed to get location list");
            }
            finally
            {
                setBusy(false);
            }
        }
        setCurrentPatient(temp);
        setCurrentPatientModified(true);
        setMessageVisible(false);
    };
         
    const handleDateChange = (e: React.SyntheticEvent<Element, Event>, data: SemanticDatepickerProps) =>  {
        let temp = Object.assign({}, currentPatient);
        temp = Object.assign(temp, { [data.name]: JSON.parse(JSON.stringify(data.value)) });
        setCurrentPatient(temp);
        setCurrentPatientModified(true);
        setMessageVisible(false);
    };

    const handleSelectChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setCurrentPatient({...currentPatient, [data.name]: data.value });
        setCurrentPatientModified(true);
        setMessageVisible(false);
    }

    function assertValidation(patient: PatientData)
    {
        let validationErrors = [];
        
        if(patient.FirstName === null || patient.FirstName.length < 2)
        {
            validationErrors.push(<><b>First Name:</b> Please set a value</>);
        }
        if(patient.LastName === null || patient.LastName.length < 2)
        {
            validationErrors.push(<><b>Last Name:</b> Please set a value</>);
        }
        if(!patient.Gender)
        {
            validationErrors.push(<><b>Gender:</b> Please set a value</>);
        }
        if(patient.DOB === '')
        {
            validationErrors.push(<><b>DOB:</b> Please set a value</>);
        }
        if(validationErrors.length > 0)
            throw validationErrors.map((item, index) => (<span key={index}>{item}<br /></span>));
    }

    const updatePatient = async () => {
        try
        {
            setBusy(true);
            assertValidation(currentPatient);
            await api.UpdatePatient(patientId, currentPatient);
            setOriginalPatient({...currentPatient});
            setCurrentPatientModified(false);
            setMessage('Patient modifications were saved')
            setMessageVisible(true);
            window.dispatchEvent(new CustomEvent(ClientEvents.refreshRiskFactors));
        }
        catch(ex)
        {
            setError(true, ex, "Unable to update the patient");
        }
        finally
        {
            setBusy(false);
        }
    };

    const revertPatient = async () => {
        setCurrentPatient({...originalPatient});
        setCurrentPatientModified(false);
        setMessage('Patient modifications were reverted')
        setMessageVisible(true);
    };

    useEffect(() => {
        if(!initialized.current)
        {
            initialized.current = true;
            
            load();
        }
      });

    const load = async () => {
        try
        {
            setBusy(true);
            var p = await api.GetPatient(patientId);
            setOriginalPatient(p);
            setCurrentPatient({...p});
            var orgList = await new OrganizationApi().GetOrganizationList();
            setOrganizations(orgList);
            if(IsEmpty(p.OrganizationId) || p.OrganizationId === GuidEmpty)
            {
                setLocations([]);
            }
            else
            {
                var locList = await new OrganizationApi().GetLocationList(p.OrganizationId);
                setLocations(locList);
            }
            
            console.log(`Loaded Patient Personal Info, PatientId: ${patientId}`);
            setBusy(false);
        }
        catch(error)
        {
            setError(true, error, "Failed to load data");
        }
        finally
        {
            setBusy(false);
        }
        
    }

    return (
        <>
            <ErrorDisplay />
            <Segment color='blue' style={{ minWidth: '300px'}} loading={busy}>
                <Header as='h3'>
                    <Icon name='info' />
                    <Header.Content>
                        Client Information
                        <Header.Subheader>Manage Client info</Header.Subheader>
                    </Header.Content>
                </Header>
                <Divider />
                <Form style={{ padding: '5px' }}>
                    {currentPatient === null ? <></> : (
                    <>
                        <Form.Group widths='equal'>
                            <Form.Select name='OrganizationId' label="Organization" options={organizations.map((org, i) => { return { key: org.Id, text: org.Name, value: org.Id };})} value={currentPatient.OrganizationId} onChange={handleChange} error={IsEmpty(currentPatient.OrganizationId)} />
                            <Form.Select name='LocationId' label="Location" options={locations.map((org, i) => { return { key: org.Id, text: org.Name, value: org.Id };})} value={currentPatient.LocationId} onChange={handleChange} error={IsEmpty(currentPatient.LocationId)} />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input fluid label='First name' placeholder='First name' required value={currentPatient.FirstName ?? ''} name='FirstName' readOnly={!HasRole(Roles.client_info_modify)} onChange={handleChange} error={(currentPatient.FirstName?.length ?? 0) < 2} />
                            <Form.Input fluid label='Middle name' placeholder='Middle name' value={currentPatient.MiddleName ?? ''} name='MiddleName' readOnly={!HasRole(Roles.client_info_modify)} onChange={handleChange} />
                            <Form.Input fluid label='Last name' placeholder='Last name' required value={currentPatient.LastName ?? ''} name='LastName' readOnly={!HasRole(Roles.client_info_modify)} onChange={handleChange} error={(currentPatient.LastName?.length ?? 0) < 2}/>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <SemanticDatepicker label='Date of Birth' name='DOB' onChange={handleDateChange} format='MM/DD/YYYY' showToday={false} value={new Date(currentPatient.DOB)} readOnly={!HasRole(Roles.client_info_modify)}  />
                            <Form.Select label='Gender' options={GlobalVariables.genderOptions} placeholder='Gender' value={currentPatient.Gender} name='Gender' onChange={handleSelectChange} readOnly={!HasRole(Roles.client_info_modify)} fluid />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input fluid label='Phone' placeholder='Enter Phone...' value={currentPatient.Phone ?? ''} name='Phone' onChange={handleChange} readOnly={!HasRole(Roles.client_info_modify)} />
                            <Form.Input fluid label='Email' placeholder='Enter Email...' value={currentPatient.Email ?? ''} name='Email' onChange={handleChange} readOnly={!HasRole(Roles.client_info_modify)} />
                        </Form.Group>
                        <Form.Input fluid label='PCP' placeholder='<Enter Name>' value={currentPatient.PCP_Name ?? ''} name='PCP_Name' onChange={handleChange} readOnly={!HasRole(Roles.client_info_modify)} />
                        <Form.Group widths='equal'>                        
                            <Form.Input fluid label='PCP Phone' placeholder='000-000-0000' value={currentPatient.PCP_Phone ?? ''} name='PCP_Phone' onChange={handleChange} readOnly={!HasRole(Roles.client_info_modify)} />
                            <Form.Input fluid label='PCP Fax' placeholder='000-000-0000' value={currentPatient.PCP_Fax ?? ''} name='PCP_Fax' onChange={handleChange} readOnly={!HasRole(Roles.client_info_modify)} />
                        </Form.Group>
                    </>)}
                </Form>
                {currentPatientModified && HasRole(Roles.client_info_modify) ? (
                    <>
                        <Button icon='save outline' color='green' onClick={updatePatient} disabled={busy} />
                        <Button icon='window close' color='red' basic onClick={revertPatient} disabled={busy} /><br />
                    </>
                ) : <></>}
                
                {messageVisible ? (
                    <Message positive header='Success' content={message} onDismiss={() => setMessageVisible(false)} />
                ) : <></>}
            </Segment>
        </>
    );
};

export default PatientPersonalInfoDisplay;