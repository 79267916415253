import React, { useState, useEffect, useRef } from 'react'
import { Table, Icon, Header, Segment, Button, Popup } from 'semantic-ui-react'
import TemplatesApi, { TemplateSlim } from '../apiClients/TemplatesApi';
import { Roles } from '../Enums';
import { OnlyForRole } from '../Helpers';
import AddTemplateDialog from './AddTemplateDialog';
import AddTemplateRevisionDialog from './AddTemplateRevisionDialog';
import EditContentControlsDialog from './EditContentControlsDialog';
import { useErrorDisplay } from './ErrorDisplay';
import ExecuteOnConfirm from './ExecuteOnConfirm';

const TemplatesDisplay = () => {
    const api = new TemplatesApi();
    
    const [templates, setTemplates] = useState<TemplateSlim[]>([]);
    const { ErrorDisplay, setError } = useErrorDisplay();
    const [isLoading, setIsLoading] = useState(false);
    const initialized = useRef<boolean>(false);
    
    useEffect(() => {
        if(initialized.current)
            return;
        initialized.current = true;
        
        const load = async () => {
            setIsLoading(true);
            try {
                await loadTemplates();
            }
            finally {
                setIsLoading(false);
            }
        };
        load().catch(error => setError(true, error, 'Failed to load data'));
    }, []);

    const loadTemplates = async () => {
        var templates = await api.GetTemplates(true);
        setTemplates(templates);
    }

    const handleDelete: (item: TemplateSlim) => Promise<void> = async item => {
        try
        {
            setIsLoading(true);
            await api.DeleteTemplate(item.TemplateId);
            await loadTemplates();
        }
        catch(error)
        {
            setError(true, error, 'Failed to delete the template');
        }
        finally
        {
            setIsLoading(false);
        }        
    };

    const handleAddNew = async () => {
        //const newTemplates = templates.concat(newItem);
        //setTemplates(newTemplates);
        try
        {
            setIsLoading(true);
            await loadTemplates();
        }
        catch(error)
        {
            setError(true, error, 'Failed to load the templates');
        }
        finally
        {
            setIsLoading(false);
        }
        // TODO: show the edit content controls dialog
    };


    const downloadTemplate = async (item) => {
        const api = new TemplatesApi();
        api.DownloadTemplate(item.TemplateId);
    };

    const TemplateItem = ({ item }: { item: TemplateSlim; }) => {
        return (
            <Table.Row>
                <Table.Cell style={{width:'50px'}}>
                    <Button.Group basic compact size='tiny'>
                        {OnlyForRole(Roles.settings_template_modify, <EditContentControlsDialog template={item} />)}
                        <Popup content='Download current template' trigger={<Button basic compact icon={{ name: 'download', color: 'blue' }} onClick={() => downloadTemplate(item)} />} />
                        {OnlyForRole(Roles.settings_template_modify, <AddTemplateRevisionDialog templateId={item.TemplateId} onAddSuccess={loadTemplates} />)}
                    </Button.Group>
                </Table.Cell>
                <Table.Cell>{item.Name}</Table.Cell>
                <Table.Cell>{item.LastUpdated}</Table.Cell>
                <Table.Cell>{item.ModifiedBy}</Table.Cell>
                <Table.Cell style={{ width: '25px'}}>
                    <Button.Group compact basic size='tiny'>
                        {OnlyForRole(Roles.settings_template_delete, <ExecuteOnConfirm displayText='Are you sure you want to delete this template?' hoverText="Delete Template" executeAsync={() => handleDelete(item)}/>)}
                    </Button.Group>
                </Table.Cell>
            </Table.Row>
        );
    };
    

    return (
        <>
            <ErrorDisplay />
            <Segment color='yellow' loading={isLoading} style={{ width: '800px'}}>
                <div style={{ display: 'inline-block', width: '70%' }}>
                    <Header as='h3'>
                        <Icon name='file alternate outline' />
                        <Header.Content>
                            Document Templates
                            <Header.Subheader>
                                Add, Modify, and Revise Document Templates
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                </div>
                <div style={{ display: 'inline-block', textAlign: 'right', verticalAlign: 'top', width: '30%' }}>
                    {OnlyForRole(Roles.settings_template_create, <AddTemplateDialog onAddSuccess={handleAddNew} />)}
                </div>

                <Table celled striped sortable size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Last Updated</Table.HeaderCell>
                            <Table.HeaderCell>Modified By</Table.HeaderCell>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {templates.map(item => <TemplateItem key={item.TemplateId} item={item} />)}
                    </Table.Body>
                </Table>
            </Segment>
        </>
    );
};


export default TemplatesDisplay;