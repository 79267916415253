import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react'
import { Table, Segment, Button, Modal, Dropdown, Grid, Header, Icon, Popup } from 'semantic-ui-react'
import OrderApi, { OrderSlim, OrderType } from '../apiClients/OrderApi';
import { Roles } from '../Enums';
import GlobalVariables from '../GlobalVariables';
import { FormatDateTime, IsEmpty, OnlyForRole } from '../Helpers';
import ExecuteOnConfirm from './ExecuteOnConfirm';

const PatientOrderDisplay = ({ patientId }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const initialized = useRef<string>();
    const [orderList, setOrderList] = useState<OrderSlim[]>([]);
    const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);
    const [selectedOrderType, setSelectedOrderType] = useState<OrderType>();

    const loadData = async () => {
        setLoading(true);
        try {
            const response = await new OrderApi().GetOrders(patientId);
            setOrderList(response);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(initialized.current === patientId)
            return;
        initialized.current = patientId;
        loadData();
    }, [patientId]);

    const handleDisplayAddOrder = async () => {
        try
        {
            setLoading(true);
            setSelectedOrderType(undefined);
            var ot = await new OrderApi().GetOrderTypes();
            console.log(ot);
            setOrderTypes(ot);
            if(ot.length === 1)
                setSelectedOrderType(ot[0]);
            setOpen(true);
        }
        catch(error)
        {

        }
        finally
        {
            setLoading(false);
        }
    }

    const handleCreateOrder = async () => {
        try
        {
            setOpen(false);
            setLoading(true);
            let request = await new OrderApi().GetOrderRequestScaffold(selectedOrderType.Filename);
            request.Notes.push({
                Timestamp: moment().format('YYYY-MM-DDTHH:mm:ss'),
                Note: 'Created Request',
                Username: GlobalVariables.UserName,
                State: request.States[0].State,
                Action: 'Create New'
            });
            await new OrderApi().CreateOrder(patientId, request);
            await loadData();
            
        }
        catch(error)
        {
            setOpen(true);
        }
        finally
        {
            setLoading(false);
        }
    }

    const handleDeleteOrder = async (orderId: string) => {
        try
        {
            setLoading(true);
            await new OrderApi().DeleteOrder(orderId);
            await loadData();
            
        }
        catch(error)
        {
            
        }
        finally
        {
            setLoading(false);
        }
    }

    return (
        <>
            <Segment color='yellow' loading={loading} style={{maxHeight: '550px', display: 'grid' }}>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as='h3'>
                                <Icon name='cart' />
                                <Header.Content>
                                    Orders
                                    <Header.Subheader>Client Orders</Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            {OnlyForRole(Roles.orders_create, 
                                <Button.Group compact basic size='tiny'>
                                    <Popup content='Add an order to this client' trigger={<Button compact size='tiny' icon={{ name: 'plus cart', color: 'blue' }} onClick={handleDisplayAddOrder} />}/>
                                </Button.Group>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Table celled striped sortable compact size='small' className='TableFixedHeader' verticalAlign='top'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>Timestamp</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>State</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {orderList.map(order => (
                            <Table.Row key={order.Id}>
                                <Table.Cell style={{width: '20px'}}></Table.Cell>
                                <Table.Cell>{FormatDateTime(order.OrderedTimestamp)}</Table.Cell>
                                <Table.Cell>{order.OrderType}</Table.Cell>
                                <Table.Cell>{order.CurrentState}</Table.Cell>
                                <Table.Cell style={{width: '20px'}}>{OnlyForRole(Roles.orders_delete, <ExecuteOnConfirm displayText='Are you sure you want to delete this order?' executeAsync={() => handleDeleteOrder(order.Id)} />)}</Table.Cell>
                            </Table.Row>
                        ))}
                        
                    </Table.Body>
                </Table>
            </Segment>
            <Modal size='mini' open={open}>
                <Modal.Header>Add Order</Modal.Header>
                <Modal.Content>
                    <Dropdown search selection options={orderTypes.map(x => {return { text: x.Text, value: x.Filename, key: x.Filename }})} value={selectedOrderType?.Filename ?? ''} onChange={(e, data) => setSelectedOrderType(orderTypes.find(ot => ot.Filename === data.value))} />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Save"
                        onClick={handleCreateOrder}
                        positive
                        disabled={IsEmpty(selectedOrderType)}
                    />
                    <Button color='black' basic onClick={() => setOpen(false)} disabled={loading}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default PatientOrderDisplay;