import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Breadcrumb, Button, Checkbox, Grid, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import OrderApi, { OrderRequest } from '../apiClients/OrderApi';
import GlobalVariables from '../GlobalVariables';
import { IsEmpty } from '../Helpers';
import { useErrorDisplay } from './ErrorDisplay';
import ExportButton from './ExportButton';
import NoteButton from './NoteButton';

export interface OrderRequestManagementViewProps
{
    orderId: string;
    onChange?: (orderId: string) => (void | Promise<void>)
}

const OrderRequestManagementView = ({ orderId, onChange } : OrderRequestManagementViewProps) => {
    const initialized = useRef<string>();
    const [orderRequest, setOrderRequest] = useState<OrderRequest>();
    const [busy, setBusy] = useState<boolean>(false);
    const { ErrorDisplay, setError } = useErrorDisplay();

    const loadData = async () => {
        try {
            setBusy(true);
            var response = await new OrderApi().GetOrderRequest(orderId);
            console.log(response);
            setOrderRequest(response);
        } catch (error) {
            setError(true, error, 'Failed to get order request');
        } finally {
            setBusy(false);
        }
    };

    useEffect(() => {
        if(initialized.current === orderId)
            return;
        initialized.current = orderId;
        loadData();
    }, []);

    if(IsEmpty(orderRequest))
        return null;

    const currentStepIndex = orderRequest.States.findIndex(x => x.Active);
    const isFirstStep = currentStepIndex === 0;
    const isLastStep = currentStepIndex === orderRequest.States.length - 1;
    const previousStep = isFirstStep ? '' : orderRequest.States[currentStepIndex - 1].State;
    const nextStep = isLastStep ? '' : orderRequest.States[currentStepIndex + 1].State;

    const handleMoveStep = async (increment: number, note: string) => {
        try {
            setBusy(true);
            orderRequest.States.forEach((value, index) => value.Active = index === (currentStepIndex + increment));
            orderRequest.Notes = [
                { 
                    Timestamp: moment().format('YYYY-MM-DDTHH:mm:ss'), 
                    Note: note, 
                    Username: GlobalVariables.UserName,
                    State: orderRequest.States[currentStepIndex].State,
                    Action: `Move to: ${orderRequest.States.filter(x => x.Active)[0].State}`
                }, ...orderRequest.Notes];
            await new OrderApi().UpdateOrderRequest(orderId, orderRequest);
            await onChange(orderId);
            setOrderRequest({...orderRequest});
        } catch (error) {
            setError(true, error, 'Failed to change steps');
        } finally {
            setBusy(false);
        }
    };

    const handleAddNote = async (note: string) => {
        try {
            setBusy(true);
            orderRequest.Notes = [
                { 
                    Timestamp: moment().format('YYYY-MM-DDTHH:mm:ss'), 
                    Note: note, 
                    Username: GlobalVariables.UserName,
                    State: orderRequest.States[currentStepIndex].State,
                    Action: 'Add Note'
                }, ...orderRequest.Notes];
            await new OrderApi().UpdateOrderRequest(orderId, orderRequest);
            await onChange(orderId);
            setOrderRequest({...orderRequest});
        } catch (error) {
            setError(true, error, 'Failed to add Note');
        } finally {
            setBusy(false);
        }
    }
    
    const handleChangeChecklist = async (e, { name, checked }) => {
        try {
            setBusy(true);
            var item = orderRequest.States[currentStepIndex].Checklist.find(x => x.Id === name);
            item.Completed = checked;        
            await new OrderApi().UpdateOrderRequest(orderId, orderRequest);
            await onChange(orderId);
            setOrderRequest({...orderRequest});
        } catch (error) {
            setError(true, error, 'Failed to updated checklist');
        } finally {
            setBusy(false);
        }
        
    }
    return (    
        <>
            <ErrorDisplay /> 
            <Segment color='teal'>
                <Header>
                    <Icon name='settings' />
                    Order Work Flow
                </Header>
                <Breadcrumb size='small' style={{ marginBottom: '20px'}}>
                    {orderRequest.States.map((step, index) => (
                        <>
                            <Popup content={step.Description} trigger={
                                <Breadcrumb.Section active={step.Active}>
                                    {step.State}                             
                                </Breadcrumb.Section>
                            } />
                            {index + 1 === orderRequest.States.length ? null : <Breadcrumb.Divider icon='right chevron' />}
                        </>
                    ))}
                </Breadcrumb>
            
            <Grid columns={2} stackable>
                <Grid.Column>
                    <Segment color='green' loading={busy}>
                    <Table compact size='small'>                
                        <Table.Row>
                            <Table.Cell singleLine style={{ fontWeight: 'bold', color: 'blue', fontSize: '20px' }} textAlign='left'>Current Step:</Table.Cell>
                            <Table.Cell singleLine textAlign='left'><span style={{ fontWeight: 'bold', color: 'Red', fontSize: '18px'}}>{orderRequest.States.filter(x => x.Active)[0].State}</span></Table.Cell>
                            
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='2'>
                                <Header as='h3'>Step Description:</Header>
                                <p>{orderRequest.States[currentStepIndex].Description}</p>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='2'>
                                <Header as='h3' style={{marginTop: "10px"}}>Checklist:</Header>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='2'>
                                <ul style={{listStyleType: 'none', margin: '0px', padding: '0px'}}>
                                    {orderRequest.States[currentStepIndex].Checklist.map((cli, index) => (
                                        <li><Checkbox name={cli.Id} checked={cli.Completed} style={cli.Required && !cli.Completed ? {color: 'red'} : null} label={cli.Text} onChange={handleChangeChecklist} /></li>
                                    ))}
                                </ul>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='2'>
                                <Header as='h3' style={{marginTop: "10px"}}>Step Actions:</Header>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='2' textAlign='right'>
                            {isFirstStep ? null 
                                : <Popup content={
                                    <div>
                                        <p>Go to previous step: {previousStep}</p>
                                        <p>Description: {orderRequest.States[currentStepIndex - 1].Description}</p>
                                        </div>
                                    } 
                                    trigger={
                                        <NoteButton basic negative
                                            content='Return to Previous Step' 
                                            icon={{ name: 'arrow left'}} 
                                            labelPosition='right' 
                                            onClick={(note) => handleMoveStep(-1, note)} />
                                        } />}
                            {isLastStep ? null 
                                : <Popup content={
                                    <div>
                                        <p>Go to next step: {nextStep}</p>
                                        <p>Description: {orderRequest.States[currentStepIndex + 1].Description}</p>
                                    </div>
                                    } 
                                    trigger={
                                        <NoteButton basic positive
                                            requireNote={orderRequest.States[currentStepIndex + 1].RequireNoteOnNextStep ?? false}
                                            content='Next Step' 
                                            icon={{ name: 'arrow right'}} 
                                            disabled={orderRequest.States[currentStepIndex].Checklist.findIndex(cli => cli.Required && !cli.Completed) >= 0} 
                                            labelPosition='left' 
                                            header={`Enter note for move to step: [${nextStep}]`} 
                                            onClick={(note) => handleMoveStep(1, note)} />} />}
                            </Table.Cell>
                        </Table.Row>
                    </Table>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    {IsEmpty(orderRequest.Notes) ? null : (
                        <Segment loading={busy}  color='blue'>
                            <Header as='h5'>Notes:</Header>
                            <Table celled striped sortable compact size='small'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell sorted='descending'>Timestamp</Table.HeaderCell>
                                        <Table.HeaderCell>User</Table.HeaderCell>
                                        <Table.HeaderCell>Step</Table.HeaderCell>
                                        <Table.HeaderCell>Action</Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Note
                                            <Button.Group floated='right' compact basic size='tiny'>
                                                <NoteButton size='tiny' hoverContent='Add Note' compact basic icon={{ name: 'add', color: 'blue' }} onClick={handleAddNote} />
                                                <ExportButton filename='Notes' data={orderRequest.Notes} colNames={[ 'Timestamp', 'Username', 'State', 'Action' ]} preprocessor={(name, value) => { 
                                                    if(name === 'Timestamp')
                                                        return moment(value).format('MM/DD/YYYY hh:mm A');
                                                    return value;
                                                }}/>
                                        </Button.Group>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {orderRequest.Notes.map(note => 
                                        <Table.Row>
                                            <Table.Cell width={1} singleLine>{moment(note.Timestamp).format('MM/DD/YYYY hh:mm A')}</Table.Cell>
                                            <Table.Cell width={1} singleLine>{note.Username}</Table.Cell>
                                            <Table.Cell width={1} singleLine>{note.State}</Table.Cell>
                                            <Table.Cell width={1} singleLine>{note.Action}</Table.Cell>
                                            <Table.Cell>{note.Note}</Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        </Segment>
                    )}
                </Grid.Column>
            </Grid>      
             
            </Segment> 

            
            
        </>
    );
}

export default OrderRequestManagementView;