import moment, { Moment } from 'moment';
import React, { useState } from 'react'
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Checkbox, Dimmer, Grid, Header, Loader, Popup, Segment, Table } from 'semantic-ui-react'
import ReportApi, { PatientDocumentReport } from '../apiClients/ReportApi';
import { useErrorDisplay } from '../components/ErrorDisplay';
import { DownloadAsCSV, IsEmpty } from '../Helpers';

const Metrics = () => {
  const [startMoment, setStartMoment] = useState<Moment>(moment().startOf('month'));
  const [endMoment, setEndMoment] = useState<Moment>(moment());
  const [includeAllPatients, setIncludeAllPatients] = useState(false);
  const [busy, setBusy] = useState<boolean>(false);
  const [data, setData] = useState<PatientDocumentReport[]>([]);
  const { ErrorDisplay, setError } = useErrorDisplay();

  const api = new ReportApi();
  const search = async () => {
    try {
      setBusy(true);
      var response = await api.GetHealthHistoryAttributeQuestions({
        StartDate: startMoment.format('YYYY-MM-DD'),
        EndDate: endMoment.format('YYYY-MM-DD'),
        IncludeAllPatients: includeAllPatients
      });
      setData(response);
    }
    catch (error) {
      setError(true, error, "Failed to get report data");
    }
    finally {
      setBusy(false);
    }
  };

  return (
    <>
      <ErrorDisplay />
      <Dimmer.Dimmable as={Segment} blurring={busy} basic>
        <Segment color='grey' attached='top'>
          <Grid columns={2}>
            <Grid.Column>
              <Header>
                Report Parameters
                <Header.Subheader>
                  Select a time range
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column textAlign='right'>
              <Button.Group size='mini' compact basic>
                <Popup content='Download as CSV' trigger={<Button icon='download' basic compact disabled={IsEmpty(data)} onClick={() => DownloadAsCSV(`Metrics`, ['FullName', 'Organization', 'Location', 'NumberReports'], data)} />} />
              </Button.Group>
            </Grid.Column>
          </Grid>


          <Grid columns={4} style={{ margin: '10px' }}>
            <Grid.Row>
              <DateInput dateFormat='MM/DD/YYYY' closable value={startMoment?.format('MM/DD/YYYY') ?? ''} error={startMoment === null}
                onChange={(e, { value }) => {
                  const newStart = moment(value)
                  if (newStart.isValid())
                    setStartMoment(newStart.startOf('day'))
                }} />
              <DateInput dateFormat='MM/DD/YYYY' closable value={endMoment?.format('MM/DD/YYYY') ?? ''} error={endMoment === null}
                onChange={(e, { value }) => {
                  const newEnd = moment(value)
                  if (newEnd.isValid())
                    setEndMoment(newEnd.endOf('day'))
                }} />
              <Button content='Run' onClick={search} />
              <Grid.Column textAlign='left' verticalAlign='middle'>
                <Checkbox label='Include all clients' toggle
                  checked={includeAllPatients}
                  onChange={(e, { checked }) => { setIncludeAllPatients(checked) }} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Segment>
        {IsEmpty(data) ? null : (
          <Table celled attached='bottom'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Client Name</Table.HeaderCell>
                <Table.HeaderCell>Organization</Table.HeaderCell>
                <Table.HeaderCell>Location</Table.HeaderCell>
                <Table.HeaderCell>Number Reports</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((item, i) => (
                <Table.Row key={item.FullName}>
                  <Table.Cell>{item.FullName}</Table.Cell>
                  <Table.Cell>{item.Organization}</Table.Cell>
                  <Table.Cell>{item.Location}</Table.Cell>
                  <Table.Cell>{item.NumberReports}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        <Dimmer active={busy}>
          <Loader active={busy} />
        </Dimmer>
      </Dimmer.Dimmable>
    </>
  );
}

export default Metrics