import ApiBase from './base/ApiBase'

class OrderApi extends ApiBase
{
    private controllerName: string = 'Order';

    constructor() {
        super()
      }

    async CompleteOrder(orderId: string, request: OrderRequest) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/${orderId}/Complete`, request);
    }

    async CreateOrder(patientId: string, request: OrderRequest) : Promise<string>
    {
        return this.PostAsync(`${this.controllerName}?patientId=${patientId}`, request);
    }

    async DeleteOrder(orderId: string) : Promise<string>
    {
        return this.DeleteAsync(`${this.controllerName}/${orderId}`);
    }

    async GetOrderRequest(orderId: string) : Promise<OrderRequest>
    {
        return this.GetAsync(`${this.controllerName}/${orderId}/Request`);
    }

    async GetOrders(patientId: string) : Promise<OrderSlim[]>
    {
        return this.GetAsync(`${this.controllerName}?patientId=${patientId}`);
    }

    async GetOpenOrders() : Promise<DashboardOrder[]>
    {
        return this.GetAsync(`${this.controllerName}/Open`);
    }

    async UpdateOrderRequest(orderId: string, request: OrderRequest) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/${orderId}`, request);
    }

    async GetOrderTypes() : Promise<OrderType[]>
    {
        return this.GetAsync(`OrderConfig/OrderTypes.json`);
    }

    async GetOrderRequestScaffold(orderFilename: string) : Promise<OrderRequest>
    {
        return this.GetAsync(`OrderConfig/${orderFilename}`);
    }
}

export interface OrderType
{
    Text: string;
    Filename: string;
}

export interface DashboardOrder
{
    Id: string;
    PatientId: string;
    OrderType: string;
    CurrentState: string;
    OrderedTimestamp: string;
    LocationId: string;
    LocationName: string;
    OrganizationId: string;
    OrganizationName: string;
    PatientName: string;
    PatientEmail: string;
    Dashboards: string[];
}

export interface OrderRequest
{
    Name: string;
    States: OrderStateDetail[];
    Notes: OrderNote[];
}

export interface OrderNote
{
    Note: string;
    Timestamp: string;
    Username: string;
    State: string;
    Action: string;
}

export interface OrderStateDetail
{
    Active: boolean;
    Dashboards: string[];
    Description: string;
    State: string;
    Checklist: CheckListItem[];
    RequireNoteOnNextStep: boolean;
}

export interface CheckListItem
{
    Id: string;
    Text: string;
    Required: boolean;
    Completed: boolean;
}
export interface OrderSlim
{
    Id: string;
    PatientId: string;
    OrderType: string;
    CurrentState: string;
    OrderedTimestamp: string;
}

export default OrderApi;