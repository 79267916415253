import React, {useState} from 'react'
import { Button, Modal, Form, Container, Popup, Card, Label, Grid, Input } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import PatientApi, { PatientAttribute, PatientData } from '../apiClients/PatientApi';
import GlobalVariables from '../GlobalVariables'
import { useErrorDisplay } from './ErrorDisplay'
import AttributeDefinitionApi, { PatientAttributeScaffold } from '../apiClients/AttributeDefinitionApi';
import { AttributeDataType, Roles, SourceType } from '../Enums';
import { GuidEmpty, IsEmpty, FormatPhone, RealNumberCharactersOnly, HasRole, FormatSSN, IsNotEmpty } from '../Helpers';
import OrganizationApi, { NameIdSlim } from '../apiClients/OrganizationApi';

function NewPatientState() : PatientData
{
    return {
        Id: GuidEmpty,
        FirstName: "",
        MiddleName: "",
        LastName: "",
        Phone: "",
        Email: "",
        DOB: "",
        Gender: null,
        PCP_Name: "",
        PCP_Phone: "",
        PCP_Fax: "",
        OrganizationId: '',
        LocationId: ''
    }
}

const AddPatientModal = ({setSelectedPatient, icon = null, iconColor = null}) => {
    const [open, setOpen] = useState(false);
    const [patient, setPatient] = useState<PatientData>(NewPatientState());
    const [patientAttributes, setPatientAttributes] = useState<PatientAttributeScaffold[]>([]);
    const { ErrorDisplay, setError } = useErrorDisplay();
    const [ organizations, setOrganizations] = useState<NameIdSlim[]>([]);
    const [ locations, setLocations] = useState<NameIdSlim[]>([]);
    const [busy, setBusy] = useState(false);
    const handleDateChange = (e, data) => {
        const newState = { ...patient}
        newState[data.name] = JSON.parse(JSON.stringify(data.value));
        setPatient(newState);
    };

    const canSave = () => {
        let ret = true;
        ret = ret && !IsEmpty(patient.OrganizationId);
        ret = ret && !IsEmpty(patient.LocationId);
        ret = ret && !IsEmpty(patient.FirstName);
        ret = ret && !IsEmpty(patient.LastName);        
        ret = ret && !IsEmpty(patient.Gender);
        ret = ret && !IsEmpty(patient.DOB);
        return ret;
    }
    const handleChange = async (e, { name, value }) => {
        const newState = { ...patient}
        let val = value;
        
        if(name === 'SSN')
        {
            val = FormatSSN(val);
        }
        else if(name === 'PCP_Phone' || name === 'PCP_Fax')
        {
            val = FormatPhone(val);
        }

        if(newState[name] !== val)
        {
            newState[name] = val;
            setPatient(newState);
        }

        if(name === 'OrganizationId')
        {
            var locList = await new OrganizationApi().GetLocationList(val);
            setLocations(locList);
        }
    };

    const handleAdd = async () => {
        try
        {
            setBusy(true);
            let patientId = await addPatient();
            if(patientId === undefined)
                return;
            
            let newPatient = {...patient, Id: patientId };
            setSelectedPatient(newPatient);
            setOpen(false);
        }
        catch(ex)
        {
            setError(true, ex, "Could not add a Client")
        }
        finally
        {
            setBusy(false);
        }
    };

    function assertValidation()
    {
        let validationErrors = [];
        
        if(IsEmpty(patient.FirstName) || patient.FirstName.length < 2)
        {
            validationErrors.push(<><b>First Name:</b> Please set a value</>);
        }
        if(IsEmpty(patient.LastName) || patient.LastName.length < 2)
        {
            validationErrors.push(<><b>Last Name:</b> Please set a value</>);
        }
        if(IsEmpty(patient.Gender))
        {
            validationErrors.push(<><b>Gender:</b> Please set a value</>);
        }
        if(IsEmpty(patient.OrganizationId))
        {
            validationErrors.push(<><b>Organization:</b> Please set a value</>);
        }
        if(IsEmpty(patient.LocationId))
        {
            validationErrors.push(<><b>Location:</b> Please set a value</>);
        }
        if(IsEmpty(patient.DOB))
        {
            validationErrors.push(<><b>DOB:</b> Please set a value</>);
        }
        if(validationErrors.length > 0)
            throw validationErrors.map((item, index) => (<span key={index}>{item}<br /></span>));
    }

    const addPatient = async () => {
        assertValidation();
        const api = new PatientApi();
        let attrValues: PatientAttribute[] = patientAttributes.map((item, i) => { 
            let value = item.Data;
            if(IsEmpty(value))
                value = item.DataType === AttributeDataType.Boolean ? 'false' : null;

            return { 
                AttributeValueOrDefinitionId: item.AttributeDefinitionId, 
                DataValue: value 
            };
        });
        const newPatientGuid = await api.AddPatient(patient, attrValues.filter(x => x.DataValue !== null));
        console.log(`new patient added: ${newPatientGuid}`);
        return newPatientGuid;
    };

    const handleNumericChange = (e, data) => {
        //remove any nonNumeric data except . and -
        var val = RealNumberCharactersOnly(data.value);
        let newData = [];
        patientAttributes.forEach(item => {
            if(item.AttributeDefinitionId === data.id)
            {
                var newItem = {...item};
                if(newItem.Data !== val)
                {
                    newItem.Data = val;
                }
                
                newData.push(newItem);
            }
            else
                newData.push(item);
        });
        setPatientAttributes(newData);
    };

    const handleStringChange = (e, data) => {
        var val = data.value;
        let newData = [];
        patientAttributes.forEach(item => {
            if(item.AttributeDefinitionId === data.id)
            {
                var newItem = {...item};
                if(newItem.Data !== val)
                {
                    newItem.Data = val;
                }
                
                newData.push(newItem);
            }
            else
                newData.push(item);
        });
        setPatientAttributes(newData);
    };

    const handleBooleanChange = (e, data) => {
        var val = data.checked;
        let newData = [];
        patientAttributes.forEach(item => {
            if(item.AttributeDefinitionId === data.id)
            {
                var newItem = {...item};
                if(newItem.Data !== val)
                {
                    newItem.Data = val;
                    newItem.Data = val ? 'true' : 'false'
                }
                
                newData.push(newItem);
            }
            else
                newData.push(item);
        });
        setPatientAttributes(newData);
    };

    const ItemDisplay = ({ item }: { item: PatientAttributeScaffold }) =>
    {
        if(item.DataType == AttributeDataType.Boolean)
        {
            return (
                <Form.Checkbox label={item.DisplayText} 
                    id={item.AttributeDefinitionId}
                    //style={{marginBottom: '3px', marginTop: '3px' }}
                    name={item.Name}
                    readOnly={!HasRole(Roles.client_characteristics_modify)}
                    checked={!IsEmpty(item.Data) && item.Data.toLowerCase() === 'true'}
                    onChange={handleBooleanChange}/>
            );
        }
        else if (item.DataType == AttributeDataType.Numeric)
        {
            return (
                <Form.Field key={item.AttributeDefinitionId}>
                    <label>{item.DisplayText}</label>
                    <Input fluid
                        id={item.AttributeDefinitionId}
                        name={item.Name}
                        readOnly={!HasRole(Roles.client_characteristics_modify)}
                        labelPosition={ !IsEmpty(item.UOM) ? 'right' : null }
                        value={item.Data ?? ''}
                        onChange={handleNumericChange}>
                        <input />
                        { !IsEmpty(item.UOM) ? <Label content={item.UOM ?? ''} basic /> : null }
                    </Input>
                </Form.Field>
            );
        }
        else if (item.DataType == AttributeDataType.String)
        {
            return (
                <Form.Field key={item.AttributeDefinitionId}>
                    <label>{item.DisplayText}</label>
                    <Input fluid
                        id={item.AttributeDefinitionId}
                        name={item.Name}
                        readOnly={!HasRole(Roles.client_characteristics_modify)}
                        value={item.Data ?? ''}
                        onChange={handleStringChange} />
                </Form.Field>
            );
        }
    }

    const triggerOpen = async () => {
        try
        {
            setBusy(true);
            setLocations([]);
            
            let newPatient = NewPatientState();
            const api = new AttributeDefinitionApi();
            var attrs = await api.GetAttributeScaffoldBySourceType(SourceType.PatientVisit);
            var orgList = await new OrganizationApi().GetOrganizationList();
            setOrganizations(orgList);
            if(orgList.length === 1)
                newPatient.OrganizationId = orgList[0].Id;
            if(IsNotEmpty(newPatient.OrganizationId))
            {
                var locList = await new OrganizationApi().GetLocationList(newPatient.OrganizationId);
                setLocations(locList);
            }
            
            attrs = attrs.filter(x => !x.IsCalculation);
            setPatientAttributes(attrs);
            setPatient(newPatient);
            setOpen(true);
        }
        catch(error)
        {
            setError(true, error, "Failed to get attribute scaffold");
        }
        finally
        {
            setBusy(false);
        }
        
    };
    return (
        <>
            
            <ErrorDisplay />
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                onOpen={triggerOpen}
                trigger={<Popup content='Add a new Client' trigger={<Button basic compact loading={busy} icon={{ name: icon ?? 'add square', color: iconColor ?? 'blue' }} onClick={triggerOpen} />} />}
            >
                <Modal.Header>Add a new patient</Modal.Header>
                <Modal.Content image scrolling>
                    <Container fluid>
                        <Form loading={busy}>
                            <Form.Group widths='equal'>
                                <Form.Select name='OrganizationId' label="Organization" options={organizations.map((org, i) => { return { key: org.Id, text: org.Name, value: org.Id };})} value={patient.OrganizationId} onChange={handleChange} error={IsEmpty(patient.OrganizationId)} />
                                <Form.Select name='LocationId' label="Location" options={locations.map((org, i) => { return { key: org.Id, text: org.Name, value: org.Id };})} value={patient.LocationId} onChange={handleChange} error={IsEmpty(patient.LocationId)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Input width='3' fluid label='First name' placeholder='First name' value={patient.FirstName} name='FirstName' onChange={handleChange} error={(patient.FirstName?.length ?? 0) < 2} required />
                                <Form.Input width='3' fluid label='Middle name' placeholder='Middle name' value={patient.MiddleName} name='MiddleName' onChange={handleChange} />
                                <Form.Input width='4' fluid label='Last name' placeholder='Last name' value={patient.LastName} name='LastName' onChange={handleChange} error={(patient.LastName?.length ?? 0) < 2} required/>
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <SemanticDatepicker label='Date of Birth' name='DOB' onChange={handleDateChange} showToday={false} format='MM/DD/YYYY' value={patient.DOB == '' ? null : new Date(patient.DOB)} error={patient.DOB === ''} required />  
                                <Form.Select label='Gender' options={GlobalVariables.genderOptions} placeholder='Gender' value={patient.Gender} name='Gender' onChange={handleChange} error={(patient.Gender?.length ?? 0) < 1} required />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input fluid label='Phone' placeholder='Enter Phone...' value={patient.Phone} name='Phone' onChange={handleChange} />
                                <Form.Input fluid label='Email' placeholder='Enter Email...' value={patient.Email} name='Email' onChange={handleChange} />
                            </Form.Group>
                            
                            
                            <Form.Input fluid label='Primary Care Physician (PCP)' placeholder='Enter PCP' value={patient.PCP_Name} name='PCP_Name' onChange={handleChange} />
                            <Form.Group widths='equal'>                        
                                <Form.Input fluid label='PCP Phone' placeholder='000-000-0000' value={patient.PCP_Phone} name='PCP_Phone' onChange={handleChange} />
                                <Form.Input fluid label='PCP Fax' placeholder='000-000-0000' value={patient.PCP_Fax} name='PCP_Fax' onChange={handleChange} />
                            </Form.Group>
                            <Card fluid>
                                <Label attached='top' color='blue'>Patient Data</Label>
                                <Card.Content>
                                    <Grid columns={3} stackable doubling>
                                        {patientAttributes.length === 0 ? <></> : patientAttributes.map(item => (
                                            <Grid.Column key={item.AttributeDefinitionId} style={{minWidth: '150px'}}><ItemDisplay item={item} /></Grid.Column>
                                        ))}                                            
                                    </Grid>
                                </Card.Content>
                            </Card>
                        </Form>
                        
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => setOpen(false)} disabled={busy}>Cancel</Button>
                    <Button
                        content="Add"
                        labelPosition='right'
                        icon='add square'
                        onClick={handleAdd}
                        positive
                        disabled={busy || !canSave()}
                    />
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default AddPatientModal;
