import { HealthHistoryRequestStatus } from '../Enums';
import ApiBase from './base/ApiBase'
import { HealthHistoryContext, PatientAttributeValue } from './PatientApi';

class HealthHistoryRequestApi extends ApiBase
{
    private  controllerName: string = 'HealthHistoryRequest';
    constructor() {
        super()
      }

    //Attribute Section Handlers
    async CreateHealthHistoryRequestId(patientId: string) : Promise<string>
    {
        return this.PostAsync(`${this.controllerName}/${patientId}`);
    }

    async GetHealthHistoryAttributeQuestions(healthHistoryRequestId: string) : Promise<HealthHistoryRequestContext>
    {
        return this.GetAsync(`${this.controllerName}/${healthHistoryRequestId}/Questions`);
    }

    async GetHealthHistoryRequestStatus(healthHistoryRequestId: string) : Promise<HealthHistoryRequestStatus>
    {
        return this.GetAsync(`${this.controllerName}/${healthHistoryRequestId}/Status`);
    }

    async UpdateHealthHistoryRequest(healthHistoryRequestId: string, context: HealthHistoryContext, isCompleted: boolean) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/${healthHistoryRequestId}?isCompleted=${isCompleted}`, context);
    }
}


export class HealthHistoryRequestContext
{
    Attributes: PatientAttributeValue[];
    Notes: string;
    DisplayText: string;
    CompletedBy: string;
}

export default HealthHistoryRequestApi;