import React from 'react'
import { Grid } from 'semantic-ui-react';
import DashboardOrderDisplay from '../components/DashboardOrderDisplay';
import { Roles } from '../Enums';
import { OnlyForRole } from '../Helpers';

const Dashboard = () => {
  
    return ( 
        <Grid doubling stackable padded stretched>            
            {OnlyForRole(Roles.orders_view, <Grid.Column width={16}><DashboardOrderDisplay /></Grid.Column>)}
        </Grid>
    );
    }

export default Dashboard