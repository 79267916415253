import React, {useState, useRef } from 'react'
import { Button, Modal, Table, Header, Divider, Statistic, Popup, Loader, Dimmer, Segment, SemanticCOLORS } from 'semantic-ui-react'
import ReactToPrint from 'react-to-print';
import { useErrorDisplay } from './ErrorDisplay'
import '../css/columns.css';

import PatientApi, { HealthHistoryListItem, LabDetailResponse, PatientAttributeValue, PatientState } from '../apiClients/PatientApi'
import { SourceType } from '../Enums'
import moment from 'moment';
import '../css/printing.css'
import { IsEmpty, IsNotEmpty } from '../Helpers';

const PertinentPositivesPopup = ({ patientId, trigger = null }) => {
    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState(false);
    const [displayName, setDisplayName] = useState<String>('');
    const [showLabs, setShowLabs] = useState<boolean>(false);
    const [showLabRollup, setShowLabRollup] = useState<boolean>(false);
    const componentRef = useRef();
    const [patientState, setPatientState] = useState<PatientState>();
    const [lastLabDetail, setLastLabDetail] = useState<LabDetailResponse[]>([]);
    const [healthHistoryList, setHealthHistoryList] = useState<HealthHistoryListItem[]>([]);
    const { ErrorDisplay, setError } = useErrorDisplay();

    const api = new PatientApi();
    
    const loadData = async () => {
        try
        {
            setBusy(true);
            setShowLabs(false);
            setShowLabRollup(false);
            let data = await api.GetPatientCurrentState(patientId);
            let patient = await api.GetPatient(patientId);
            let labList = await api.GetLabList(patientId);
            setHealthHistoryList(await api.GetHealthHistoryList(patientId));
            if(!IsEmpty(labList) && labList.length > 0)
            {
                let labDetails = [];
                for(let i = 0; i < Math.min(labList.length, 3); i++)
                {
                    let lastLab = await api.GetLabDetail(patientId, labList[i].Id);
                    labDetails.push(lastLab);
                }
                setLastLabDetail(labDetails);
                setShowLabs(true);
                if(labList.length > 1)
                {
                    setShowLabRollup(true);
                }
            }
            setDisplayName(`${patient.LastName ?? ''}, ${patient.FirstName ?? ''} ${patient.MiddleName ?? ''}`);
            setPatientState(data);
        }
        catch(error)
        {
            setError(true, error, "Failed to load the Patient state")
        }
        finally
        {
            setBusy(false);
        }
    }
    
    
    const getSourceData: (sourceType: SourceType) => PatientAttributeValue[] = (sourceType, useCurrentState = true) => 
    {
        if(patientState === undefined)
            return [];
        let ret = [];
        if(sourceType === SourceType.HealthHistory)
        {
            ret = patientState.Attributes.filter(x => x.SourceType === sourceType && x.ValueString?.toLowerCase() == 'true');
        }
        else
        {
            ret = patientState.Attributes.filter(x => x.SourceType === sourceType && !IsEmpty(x.ValueString));
        }
        return ret ?? [];
    };

    const LabsView = () => 
    {
        if(patientState === undefined)
            return null;
        
        return  showLabs ? (
            <>
                {lastLabDetail?.map((detail, i) => (
                    <div key={detail.LabTakenDate} style={{pageBreakInside: 'avoid'}}>  
                        {i == 0 ? (
                            <Header color='blue'>
                                Last Lab Draws
                            </Header> 
                        ) : null }                     
                        <Header.Subheader>
                            Lab Draw Time: {moment(detail?.LabTakenDate).format('MM/DD/YYYY hh:mm A')}
                        </Header.Subheader>
                        <ul className='threeColumns' style={{ listStyleType: 'none' }}>
                            {detail.Values.filter(x => !IsEmpty(x.ValueString))?.map((item, i) => (
                                <li key={item.Id} className='twoColumns' style={{ marginRight: '25px'}}>
                                    <b style={{ marginRight: '10px'}}>{`${item.DisplayText}:`}</b>  
                                    <div style={{ alignContent: 'end'}}>                                 
                                        <span style={{ color: 'red'}}>{item.ValueString}</span>
                                        <span style={{ color: 'gray', opacity: '.7', marginLeft: '5px'}}>{item.UOM ?? ''}</span>
                                    </div> 
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </>
        ) : null;
    };

    const LabRollupView = () => {

        return (showLabRollup ? (
            <div  style={{pageBreakInside: 'avoid'}}>                        
                <Header color='blue'>
                    Lab Detail Rollup
                    <Header.Subheader>
                        Most current result for each data point entered
                    </Header.Subheader>
                </Header>                
                <ul className='threeColumns' style={{ listStyleType: 'none' }}>
                    {getSourceData(SourceType.LabResult).map((item, i) => (
                        <li key={item.Id} className='twoColumns' style={{ marginRight: '25px'}}>
                            <b style={{ marginRight: '10px'}}>{`${item.DisplayText}:`}</b>  
                            <div style={{ alignContent: 'end'}}>                                 
                                <span style={{ color: 'red'}}>{item.ValueString}</span>
                                <span style={{ color: 'gray', opacity: '.7', marginLeft: '5px'}}>{item.UOM ?? ''}</span>
                            </div> 
                        </li>
                    ))}
                </ul>
            </div>
        ) : null);
    }


    const HealthHistoryView = () => {
        var sd = getSourceData(SourceType.HealthHistory);
        return sd.length > 0 ? (
            <>
                <Header color='blue'>Health History</Header>
                <ul className='threeColumns'>
                    {getSourceData(SourceType.HealthHistory).map((item, i) => (
                        <li key={item.Id} style={{ marginLeft: '10px'}}>{item.DisplayText}</li>
                    ))}
                </ul>
                {healthHistoryList?.filter(x => IsNotEmpty(x.Notes))?.map((hh, i) => ( 
                    <>                                        
                        <Header as='h5'>
                            {`On ${moment(hh?.LastUpdatedTimestamp).format('MM/DD/YYYY hh:mm A')};  ${IsEmpty(hh.EnteredBy) ? hh.LastModifiedUser : hh.EnteredBy} noted:`}
                        </Header>
                        <p>{hh.Notes}</p>
                    </>
                ))}
            </>
        ) : null;
    }

    const getAttributeValue = (varName: string) => {
        let attr = patientState?.Attributes?.filter(x => x.Name.toLocaleLowerCase() === varName.toLowerCase()) ?? [];
        return attr.length > 0 ? attr[0].ValueString : null; 
    }

    const HealthHistoryRiskFactorView = () => {
        let data = patientState?.Weights.filter(x => !x.IsLab) ?? [];
        return data.length > 0 ? (
            <div style={{pageBreakInside: 'avoid'}}>
                <Header color='blue'>Health History Risk Factors</Header>
                <Table celled size='small' className='TableFixedHeader' >
                    <Table.Header>
                        <Table.Row >
                            <Table.HeaderCell singleLine>Name</Table.HeaderCell>
                            <Table.HeaderCell singleLine>Inflammatory</Table.HeaderCell>
                            <Table.HeaderCell singleLine>Atrophic</Table.HeaderCell>
                            <Table.HeaderCell singleLine>Glycotoxic</Table.HeaderCell>
                            <Table.HeaderCell singleLine>Toxic</Table.HeaderCell>
                            <Table.HeaderCell singleLine>Vascular</Table.HeaderCell>
                            <Table.HeaderCell singleLine>Traumatic</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    
                    <Table.Body>
                    
                            {data.map(item => (
                                <Table.Row key={item.Description} >
                                    <Table.Cell textAlign='left'><b>{item.Description}</b></Table.Cell>
                                    <Table.Cell textAlign='center'>{item.Type1Weight}</Table.Cell>
                                    <Table.Cell textAlign='center'>{item.Type2Weight}</Table.Cell>
                                    <Table.Cell textAlign='center'>{item.Type15Weight}</Table.Cell>
                                    <Table.Cell textAlign='center'>{item.Type3Weight}</Table.Cell>
                                    <Table.Cell textAlign='center'>{item.Type4Weight}</Table.Cell>
                                    <Table.Cell textAlign='center'>{item.Type5Weight}</Table.Cell>
                                </Table.Row>
                            ))}         
                    </Table.Body>                    
                </Table>
            </div>
        ) : null;
    }

    const LabsRiskFactorView = () => {
        let data = patientState?.Weights.filter(x => x.IsLab) ?? [];
        return data.length > 0 ? (
            <div style={{pageBreakInside: 'avoid', marginTop: '30px'}}>
                <Header color='blue'>Demographic and Lab based Risk Factors</Header>
                <Table celled size='small' className='TableFixedHeader'>
                    <Table.Header>
                        <Table.Row >
                            <Table.HeaderCell singleLine>Name</Table.HeaderCell>
                            <Table.HeaderCell singleLine>Inflammatory</Table.HeaderCell>
                            <Table.HeaderCell singleLine>Atrophic</Table.HeaderCell>
                            <Table.HeaderCell singleLine>Glycotoxic</Table.HeaderCell>
                            <Table.HeaderCell singleLine>Toxic</Table.HeaderCell>
                            <Table.HeaderCell singleLine>Vascular</Table.HeaderCell>
                            <Table.HeaderCell singleLine>Traumatic</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    
                    <Table.Body>
                    
                            {data.map(item => (
                                <Table.Row key={item.Description} >
                                    <Table.Cell textAlign='left'><b>{item.Description}</b></Table.Cell>
                                    <Table.Cell textAlign='center'>{item.Type1Weight}</Table.Cell>
                                    <Table.Cell textAlign='center'>{item.Type2Weight}</Table.Cell>
                                    <Table.Cell textAlign='center'>{item.Type15Weight}</Table.Cell>
                                    <Table.Cell textAlign='center'>{item.Type3Weight}</Table.Cell>
                                    <Table.Cell textAlign='center'>{item.Type4Weight}</Table.Cell>
                                    <Table.Cell textAlign='center'>{item.Type5Weight}</Table.Cell>
                                </Table.Row>
                            ))}   
                                        
                    </Table.Body>
                    
                </Table>
            </div>) : null;
    }

    const getColor: (weight:number) => SemanticCOLORS = (weight) => 
    {
        if(weight < 15)
            return 'grey';
        else
            return 'red'
    }

    const CalculatedWeightsView = () => {
        
        return IsEmpty(patientState) ? null : (
            <>
                <Header color='blue'>Calculated Weights</Header>
                <Statistic.Group size='mini'>
                    {patientState?.SummaryWeights === undefined ? <></> : patientState.SummaryWeights.map(item => (
                        <Statistic key={item.Name} color={getColor(item.Weight)}>                        
                            <Statistic.Label>{item.Name}</Statistic.Label>
                            <Statistic.Value>{item.Weight}%</Statistic.Value>
                        </Statistic>
                    ))}
                </Statistic.Group>
            </>
        );
    }

    const triggerOpen = async () => {                    
        setBusy(false);
        setOpen(true);
        await loadData();
    };

    return (
        <>
            <ErrorDisplay />
            <Modal
                open={open}
                trigger={ trigger ?? <Popup content='View Pertinent Positives' trigger={<Button loading={busy} basic compact icon={{ name: 'magnify', color: 'purple' }} onClick={triggerOpen} />} />}
            >
                <Modal.Header>
                    Patient Pertinent Positives
                    <div style={{ display: 'inline-block', float: 'right'}}>
                        <ReactToPrint
                            trigger={() => <Button icon={{ name: 'print', color: 'blue' }} basic />}
                            content={() => componentRef.current}
                        />
                    </div></Modal.Header>
                <Modal.Content scrolling>
                    <Segment>
                        <Dimmer active={busy}>
                            <Loader active={busy}/>
                        </Dimmer>
        
                        <div ref={componentRef} style={{ margin: '10px' }}>
                            <Header as='h2'>
                                Client: <span style={{color: 'blue', marginLeft: '10px'}}>{displayName}</span>
                                <Header.Subheader>
                                    <p>{getAttributeValue('gender')}, Age: {getAttributeValue('age')}</p>
                                </Header.Subheader>
                            </Header>
                            <Divider />
                            <CalculatedWeightsView />
                            <HealthHistoryView />
                            <LabsView />
                            
                            <LabRollupView />
                            <HealthHistoryRiskFactorView />
                            <LabsRiskFactorView />
                        </div>
                    </Segment>   
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' basic onClick={() => setOpen(false)} disabled={busy}>Close</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default PertinentPositivesPopup;