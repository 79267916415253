export enum AttributeDataType
{
    Numeric = 'Numeric',
    Boolean = 'Boolean',
    String = 'String'
}

export enum LabDisplayMode
{
    Request,
    DataEntry,
}

export enum Roles
{
    // AMFAS_Admin = 'AMFAS-Admin', //composite
    // Employee = 'AMFAS-User',//composite
    // ArmgaSys_Admin = 'ArmgaSys-Admin',//composite
    my_account_access = 'my_account_access',
    client_access = 'client_access',
    client_create = 'client_create',
    client_search = 'client_search',
    client_info_view = 'client_info_view',
    client_info_modify = 'client_info_modify',
    client_characteristics_view = 'client_characteristics_view',
    client_characteristics_modify = 'client_characteristics_modify',
    riskfactors_view = 'riskfactors_view',
    riskfactors_pertinent_positive_view = 'riskfactors_pertinent_positive_view',
    documents_view = 'documents_view',
    documents_generate = 'documents_generate',
    documents_upload = 'documents_upload',
    documents_download = 'documents_download',
    healthhistory_view = 'healthhistory_view',
    healthhistory_create = 'healthhistory_create',
    healthhistory_delete = 'healthhistory_delete',
    healthhistory_modify = 'healthhistory_modify',
    healthhistory_request_create = 'healthhistory_request_create',
    labs_view = 'labs_view',
    labs_create = 'labs_create',
    labs_delete = 'labs_delete',
    labs_modify = 'labs_modify',
    settings_access = 'settings_access',
    settings_Organization_view = 'settings_Organization_view',
    settings_Organization_create = 'settings_Organization_create',
    settings_Organization_modify = 'settings_Organization_modify',
    settings_Organization_delete = 'settings_Organization_delete',
    settings_template_view = 'settings_template_view',
    settings_template_create = 'settings_template_create',
    settings_template_modify = 'settings_template_modify',
    settings_template_delete = 'settings_template_delete',
    settings_attribute_healthhistory_view = 'settings_attribute_healthhistory_view',
    settings_attribute_lab_view = 'settings_attribute_lab_view',
    settings_attribute_client_view = 'settings_attribute_client_view',
    settings_attribute_section_manage = 'settings_attribute_section_manage',
    settings_attribute_create = 'settings_attribute_create',
    settings_attribute_delete = 'settings_attribute_delete',
    settings_attribute_modify = 'settings_attribute_modify',
    settings_riskfactor_view = 'settings_riskfactor_view',
    settings_riskfactor_create = 'settings_riskfactor_create',
    settings_riskfactor_modify = 'settings_riskfactor_modify',
    settings_riskfactor_delete = 'settings_riskfactor_delete',
    user_management_access = 'user_management_access',
    token_info_access = 'token_info_access',
    metrics_access = 'metrics_access',
    system_audit_access = 'system_audit_access',
    dashboard_access = 'dashboard_access',
    orders_create = 'orders_create',
    orders_modify = 'orders_modify',
    orders_view = 'orders_view',
    orders_delete = 'orders_delete',
    dashboard_clinician = 'dashboard_clinician',
    dashboard_employee = 'dashboard_employee',
    dashboard_coach = 'dashboard_employee',
}

export enum ClientEvents
{
    refreshRiskFactors = 'refreshRiskFactors'
}

export enum SourceType
{
    HealthHistory = 'HealthHistory',
    LabResult = 'LabResult',
    PatientVisit = 'PatientVisit',
    RiskFactors = 'RiskFactors',
    Other = 'Other'
}

export enum Gender
{
    Male = 'Male',
    Female = 'Female'
}

export enum ContentControlType
{
    RichText = 'RichText',
    PlainText = 'PlainText'
}

export enum HealthHistoryRequestStatus
{
    Expired = 'Expired',
    InProgress = 'InProgress',
    Completed = 'Completed',
    NotFound = 'NotFound'
}

export enum EditorType
{
    Rule,
    StringBuilder
}