import React, { useState } from 'react'
import { Button, Modal, Form } from 'semantic-ui-react'
import { useErrorDisplay } from './ErrorDisplay'
import TemplatesApi from '../apiClients/TemplatesApi';



const AddTemplateDialog = ({ onAddSuccess }) => {
    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState<boolean>(false);
    const [templateName, setTemplateName] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const { ErrorDisplay, setError } = useErrorDisplay();

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleAddClick = async () => {
        try {
            setBusy(true);
            // upload file and get back template slim
            var result = await new TemplatesApi().AddTemplate(templateName, selectedFile);
            if (result.HasErrors) {
                setError(true, result.Errors.join('\n'), 'Unable to add template.');
            }
            else {
                handleClose();
                onAddSuccess(result.Result);
            }
        }
        catch (error) {
            setError(true, error, 'Failed to add the template');
        }
        finally {
            setBusy(false);
        }
    };

    const handleClose = () => {
        setSelectedFile(null);
        setOpen(false);
    };

    return (
        <>
            <ErrorDisplay />
            <Modal open={open} onOpen={() => setOpen(true)} onClose={handleClose} trigger={<Button content='New Template' labelPosition='left' icon='add' color='blue' compact basic loading={busy} />}>
                <Modal.Header>Add new template</Modal.Header>

                <Modal.Content image scrolling>
                    <Form loading={busy}>
                        <Form.Input placeholder='Template Name' label='Template Name' value={templateName} onChange={(e, data) => setTemplateName(data.value)} />
                        <Form.Input label='Template File' type='file' onChange={handleFileChange} />
                    </Form>
                </Modal.Content>

                <Modal.Actions>

                    <Button
                        content="Create and Upload"
                        onClick={handleAddClick}
                        disabled={!selectedFile || !templateName || busy}
                        positive
                    />
                    <Button color='black' basic onClick={handleClose} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default AddTemplateDialog;