import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react'
import { Table, Icon, Header, Segment, Grid, Button } from 'semantic-ui-react'
import DocumentApi from '../apiClients/DocumentApi';
import AddManualRevisionDialog from './AddManualRevisionDialog';
import { Roles } from '../Enums';
import GeneratePatientDocumentPopup from './GeneratePatientDocumentPopup'
import { OnlyForRole } from '../Helpers';

const PatientDocumentDisplay = ({ patientId }) => {
    const [documentList, setDocumentList] = useState([]);
    const [loading, setLoading] = useState(true);
    const initialized = useRef<boolean>(false);
    const dApi = new DocumentApi();

    const loadDocuments = async () => {
        setLoading(true);
        try {
            const response = await dApi.GetPatientDocumentList(patientId);
            setDocumentList(response);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(initialized.current)
            return;
        initialized.current = true;
        loadDocuments();
    }, []);

    const handleManualRevisionAdded = newDoc => {
        const newDocsList = [newDoc, ...documentList];
        setDocumentList(newDocsList);
    }

    return (
        <Segment color='yellow' loading={loading} style={{maxHeight: '550px', display: 'grid' }}>
            <div style={{ overflowY: 'hidden', overflowX: 'hidden', height: '100%' }}>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as='h3'>
                                <Icon name='folder outline' />
                                <Header.Content>
                                    Documents
                                    <Header.Subheader>Client Document Management</Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            <Button.Group compact basic size='tiny'>
                                {OnlyForRole(Roles.documents_generate, <GeneratePatientDocumentPopup patientId={patientId} onAdd={loadDocuments.bind(this)} />)}
                                {OnlyForRole(Roles.documents_upload, <AddManualRevisionDialog patientId={patientId} onAddSuccess={handleManualRevisionAdded} />)}
                            </Button.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <br />

                <div style={{ overflowY: 'auto', overflowX: 'auto', height: '87%'}}>
                    <Table celled striped sortable compact size='small' className='TableFixedHeader' verticalAlign='top'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Timestamp</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {documentList.map(item => {
                                const rowProps = item.OriginalDocumentId ? { positive: true } : {}
                                return (
                                    <Table.Row key={item.Id} {...rowProps}>
                                        <Table.Cell style={{ width: '20px' }}>
                                            {OnlyForRole(Roles.documents_download, (
                                                <Button.Group basic compact size='tiny'>
                                                    <Button size='tiny' compact basic icon={{ name: 'download', color: 'green' }} onClick={() => dApi.GetDocument(item.Id)} />
                                                </Button.Group>
                                            ))}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.Name}
                                            {item.OriginalDocumentId ? (<Icon name='hand paper outline' />) : <></>}
                                        </Table.Cell>
                                        <Table.Cell>{moment(item.CreatedTimestamp).format('MM/DD/YYYY hh:mm A')}</Table.Cell>
                                    </Table.Row>)
                                }
                            )}
                        </Table.Body>
                    </Table>
                </div>  
            </div>
        </Segment>
    );
};

export default PatientDocumentDisplay;