
import React from "react";
export interface ScrollViewProps
{
    height?: string | number,
    minHeight?: string | number,
    content: React.ReactNode,
    style?: object
}
const ScrollView = ({ content, height = '100%', minHeight = null, style = {} }: ScrollViewProps) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: height, minHeight: minHeight, width: '100%', overflow: 'hidden', ...style}}>
            <div style={{overflowY: 'auto', overflowX: 'auto', flexGrow: 1, flexShrink: 1, flexBasis: '1px' }}>
                {content}
            </div>
        </div>
    );
}

export default ScrollView;