import ApiBase from './base/ApiBase'

class ReportApi extends ApiBase
{
    private  controllerName: string = 'Report';
    constructor() {
        super()
      }

    async GetHealthHistoryAttributeQuestions(criteria: MetricsCriteria) : Promise<PatientDocumentReport[]>
    {
        return this.PostAsync(`${this.controllerName}/DocumentsGeneratedByClient`, criteria);
    }

    async GetSystemAudit(criteria: AuditCriteria) : Promise<SystemAuditItem[]>
    {
        return this.PostAsync(`${this.controllerName}/SystemAudit`, criteria);
    }
}


export interface MetricsCriteria
{
    StartDate: string;
    EndDate: string;
    IncludeAllPatients: boolean;
}

export interface AuditCriteria
{
    StartTime: string;
    EndTime: string;
}


export interface PatientDocumentReport
{
    FullName: string;
    Organization: string;
    Location: string;
    NumberReports: number;
}

export interface SystemAuditItem
{
    Id: string;
    TargetType: string;
    TargetId: string;
    Action: string;
    ActionDetail: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Timestamp: string;
}

export default ReportApi;