import moment from 'moment';
import React from 'react'
import GlobalVariables from './GlobalVariables'

declare global {
    interface String {
        splice(idx: number, rem: number, str: string): string;
    }
}

String.prototype.splice = function(idx, rem, str) {
    if(this.length < (idx + 1))
        return this;
    return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};

export const FormatSSN = (value) => { 
    return NumericDigitsOnly(value)?.slice(0, 9).splice(3, 0, '-').splice(6,0,'-');
}
export const IsSSN = (value) =>
{
    let val = FormatSSN(value);
    return (val?.length === 11 && val === value);
}
export const HasRole = (role: string) =>
{
    return (GlobalVariables.roles.findIndex(x => x == role) >= 0);
}

export const HasOneOfRoles = (roles: string[]) =>
{
    return roles.some(x => HasRole(x));
}

export const OnlyForRole = (role: string, jsx) =>
{
    return HasRole(role) ? jsx : null;
}

export const OnlyForRoles = (roles: string[], jsx) =>
{
    return  HasOneOfRoles(roles) ? jsx : <></>;
}

export const GuidEmpty = '00000000-0000-0000-0000-000000000000';
export const IsValidReactObj = (data) => { return typeof data === 'string' || (Array.isArray(data)) ? React.isValidElement(data[0]) : React.isValidElement(data); }
export const RealNumberCharactersOnly = (data) => {
    let hasMinus = data[0] === "-";
    var i = 0;
    let ret = data?.replace(/[^\d.-]/g, '').replace(/-+/g, '').replace(/[\.\%]/g, function(match) { 
        return match === "." ? (i++ === 0 ? '.' : '') : ''; 
    });
    if(hasMinus)
        ret = `-${ret}`;
    return ret;
}

export const PositiveFloatNumberCharactersOnly = (data) => { return data?.replace(/[^\d.]/g, ''); }
export const NumericDigitsOnly = (data: string) => { return data?.replace(/[^\d]/g, ''); }
export const FormatPhone = (value: string) => { 
    let numOnly = NumericDigitsOnly(value);
    if(numOnly.length < 10)
        return numOnly;
    
    if(numOnly.length == 10)
    {
        return numOnly?.splice(0, 0, '(').splice(4, 0, ') ').splice(9,0,'-');
    }
    
    return value;
}

export const IsPhone = (value) => 
{
    let val = FormatPhone(value);
    return (val?.length === 12 && val === value);
}

export const IsEmpty = (val) => {
    return (IsNullOrUndefined(val) || val.length <= 0 || val === GuidEmpty) ? true : false;
};

export const IsNotEmpty = (val) => {
    return !IsEmpty(val);
};

export const IsNullOrUndefined = (val) => {
    return (val === undefined || val === null) ? true : false;
};

export const AreEqual = (obj1: object, obj2: object) : boolean =>
{
    if(IsNullOrUndefined(obj1) || IsNullOrUndefined(obj2))
        return false;
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const DownloadAsCSV = (filenameNoExtension: string, colNames: string[], data: object[], preprocessor: (name: string, value: any) => any = (name, value) => value) => {
    if(data.length === 0)
    {
        return;
    }
    if(IsEmpty(colNames) || colNames.length === 0)
        colNames = Object.getOwnPropertyNames(data[0]);

    let output = colNames.map(item => `"${item}"`).join(',');
    let addQuotesToStrings = (inObj) => {
        if(typeof inObj === 'string')
        {
            return `"${inObj}"`;
        }
        return inObj;
    }
    data.forEach(row => {
        output += "\n";
        output += colNames.map(x => addQuotesToStrings(preprocessor(x, row[x]))).join(',');
    });

    var hiddenElement = document.createElement('a');  
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(output);  
    hiddenElement.target = '_blank';  
    
    //provide the name for the CSV file to be downloaded  
    hiddenElement.download = `${filenameNoExtension}.csv`;  
    hiddenElement.click();  
};

export const FormatDateTime : (inDate: string) => string = (inDate) => moment(inDate).format('MM/DD/YYYY hh:mm A');
export const FormatDate : (inDate: string) => string = (inDate) => moment(inDate).format('MM/DD/YYYY');
export const FormatTime : (inDate: string) => string = (inDate) => moment(inDate).format('hh:mm A');

export function GetBodyContents(htmlString: string) {
    const pattern = /<body[^>]*>((.|[\n\r])*)<\/body>/im;
    const matches = pattern.exec(htmlString);
    return matches[1];
}

const Helpers = {};
export default Helpers;