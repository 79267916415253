import React, {useState, Fragment} from 'react'
import { Button, Modal, Header, Input, Label, Form, Checkbox, Card, Message, Container, Divider, Popup, Grid} from 'semantic-ui-react'
import PatientApi, { PatientAttribute } from '../apiClients/PatientApi';
import { PatientAttributeScaffold } from '../apiClients/AttributeDefinitionApi'
import { useErrorDisplay } from './ErrorDisplay'
import { GuidEmpty, IsEmpty, RealNumberCharactersOnly } from '../Helpers'
import { SourceType, AttributeDataType, LabDisplayMode } from '../Enums';
//import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import moment, { Moment } from 'moment';
export interface AddLabProps
{
    patientId: string;
    onAddLabs: (patientId: string) => Promise<void>;
    mode: LabDisplayMode;
    disabled?: boolean;
    readOnly?: boolean;
    labId?: string;
    icon?: React.ReactNode | string;
    iconColor?: string;
    hoverText?: string;
}
const AddLab = ({ patientId, onAddLabs, mode, disabled = false, readOnly = false, labId = null, icon = null, iconColor = null, hoverText = null }: AddLabProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [labs, setLabs] = useState<PatientAttributeScaffold[]>([]);
    const [labDateMoment, setLabDateMoment] = useState<Moment>();
    const [shortDescription, setShortDescription] = useState<string>(null);
    const [requestedAttributes, setRequestedAttributes] = useState<string[]>([]);
    const [busy, setBusy] = useState<boolean>(false);
    const [showOnlyRequested, setShowOnlyRequested] = useState<boolean>(true);
    const [showShortDescriptionOverride, setShowShortDescriptionOverride] = useState<boolean>(false);
    const { ErrorDisplay, setError } = useErrorDisplay();

    const pApi = new PatientApi();
    const isEdit = labId != null;
    const handleAdd = async () => {
        let updateItems: PatientAttribute[] = [];
        let addItems: PatientAttribute[] = [];
        try
        {
            setBusy(true);
            if(labId == null)
            {
                labs.forEach(x => {
                    if(x.Data !== '')
                    addItems.push({ AttributeValueOrDefinitionId: x.AttributeDefinitionId, DataValue: `${x.Data}` });
                });
                await pApi.AddLab(patientId, { RequestedAttributes: requestedAttributes, ShortDescription: shortDescription });
                await onAddLabs(patientId);
            }
            else
            {
                if(mode === LabDisplayMode.DataEntry)
                {
                    labs.forEach(x => {
                        if(x.Data !== '' || x.Id !== GuidEmpty)
                        {
                            if(x.Id === GuidEmpty)
                            {
                                addItems.push({ AttributeValueOrDefinitionId: x.AttributeDefinitionId, DataValue: `${x.Data}` });
                            }
                            else
                            {
                                updateItems.push({ AttributeValueOrDefinitionId: x.Id, DataValue: `${x.Data}` });
                            }
                        }
                    });
                    if(updateItems.length > 0)
                    {
                        await pApi.UpdateAttributeValues(patientId, labId, updateItems);
                    }
                    if(addItems.length > 0)
                    {
                        await pApi.AddAttributeValues(patientId, labId, addItems)
                    }
                    await pApi.UpdateLabInfo(patientId, labId, shortDescription, requestedAttributes, labDateMoment.format('YYYY-MM-DDTHH:mm:ss'));
                }
                else
                {
                    await pApi.UpdateLabInfo(patientId, labId, shortDescription, requestedAttributes);
                }
                
                onAddLabs(patientId);
            } 
            setOpen(false);
            setLabs([]);
            setRequestedAttributes([]);           
        }
        catch(ex)
        {
            setError(true, ex, "Unable to add/modify labs");
        }
        finally
        {
            setBusy(false);
        }
    };

    const handleChange = (id: string, dataType: AttributeDataType, value: string) => 
    {
        switch(dataType)
        {
            case AttributeDataType.Numeric:
                handleNumericChange(id, value);
                break;
            default:
                handleStringChange(id, value);
                break;

        }
    }

    const handleStringChange = (id: string, value: string) =>  {
        const newLabs = [ ...labs]
        let index = newLabs.findIndex(x => x.AttributeDefinitionId == id);
        if(newLabs[index].Data != value)
        {
            newLabs[index].Data = value;
            setLabs(newLabs);
        }
    }

    const handleNumericChange = (id:string, value: string) =>  {
        let val = RealNumberCharactersOnly(value);
        const newLabs = [ ...labs]
        let index = newLabs.findIndex(x => x.AttributeDefinitionId == id);
        if(newLabs[index].Data != val)
        {
            newLabs[index].Data = val;
            setLabs(newLabs);
        }
    }

    const handleBoolChange = (id: string, isChecked: boolean) =>  {
        const newLabs = [ ...labs]
        let index = newLabs.findIndex(x => x.AttributeDefinitionId == id);
        if(newLabs[index].Data != `${isChecked}`)
        {
            newLabs[index].Data = `${isChecked}`;
            setLabs(newLabs);
        }
    }

    const canSave : () => boolean = () => {
        let ret = true;
        if(mode === LabDisplayMode.DataEntry)
        {
            ret = ret && labDateMoment !== null;    
        }     
        ret = ret && !IsEmpty(shortDescription); 
        return ret;
    }

    const toggleAttribute = (id) => {
        
        if(requestedAttributes.includes(id))
        {
            var newAttributes = [...requestedAttributes];
            var index = newAttributes.indexOf(id);
            newAttributes.splice(index, 1);
            setRequestedAttributes(newAttributes)
        }
        else
        {
            setRequestedAttributes([...requestedAttributes, id]);
        }
    }

    let uniqueSectionNames = labs?.map(x => x.SectionName).filter((value, index, self) => self.indexOf(value) === index) ?? [];

    let Section = (name) =>
    {
        var items = labs.filter(x => x.SectionName === name);
        if(mode === LabDisplayMode.DataEntry && showOnlyRequested)
        {
            items = items.filter(x => !IsEmpty(x.Data) || requestedAttributes.includes(x.AttributeDefinitionId));
        }
        return items.length === 0 ? null : (
            <Fragment key={name}>
                <Header as="h2">{name}</Header>
                {isEdit && mode === LabDisplayMode.DataEntry ? (
                    <Card.Group>
                    {items.map(item => (
                        <Card key={item.AttributeDefinitionId}
                            raised={!IsEmpty(item.Data)} 
                            color={item.IsCalculation ? 'orange' : (IsEmpty(item.Data) ? 'yellow' : 'green')} 
                            style={{ padding: '5px', width: '200px'}}>
                            <Label as='a' 
                                        attached='top' 
                                        //basic={IsEmpty(item.Data)} 
                                        color={IsEmpty(item.Data) ? (requestedAttributes.includes(item.AttributeDefinitionId) ? 'yellow' : null) : 'green'}>
                                            {`${item.DisplayText} ${item.Info === '' ? '' : `(${item.Info})`} `}
                                    </Label>
                            <Card.Content>
                                {item.IsCalculation ? <p style={{ margin: '5px'}}>{`${item.Data} ${item.UOM ?? ''}`}<span style={{ color: 'orange', marginLeft: '5px' }}>(Calculated)</span></p> : 
                                    item.DataType === AttributeDataType.Boolean ? (
                                        <Checkbox checked={ item.Data?.toLowerCase() === 'true'} onChange={(e, data) => handleBoolChange(item.AttributeDefinitionId, data.checked)} disabled={disabled} readOnly={readOnly} />
                                    ) : (
                                    <Input fluid
                                        name='UOM'
                                        disabled={disabled} 
                                        readOnly={readOnly}
                                        labelPosition={ !IsEmpty(item.UOM) ? 'right' : null }
                                        value={item.Data ?? ''}
                                        onChange={(e, data) => handleChange(item.AttributeDefinitionId, item.DataType, data.value)}
                                        >
                                        <input />
                                        { !IsEmpty(item.UOM) ? <Label content={item.UOM ?? ''} basic /> : null }
                                    </Input>
                                    )
                                }
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>
                ) : (
                    <div>
                        {items.map(item => (
                            <Button basic toggle key={item.AttributeDefinitionId} style={{marginBottom: '3px'}} active={requestedAttributes.includes(item.AttributeDefinitionId)} onClick={() => toggleAttribute(item.AttributeDefinitionId)} content={item.DisplayText} />
                        ))}
                    </div>
                )}
            </Fragment>
        )
    }

    
    const triggerOpen = async () => {
        try
        {
            setBusy(true);
            setShowOnlyRequested(true);
            if(labId == null) {
                let lr = await pApi.GetPatientAttributeScaffold(patientId, SourceType.LabResult);
                lr = lr.filter(x => !x.IsCalculation);
                lr.forEach(l => {                    
                    l['PatientId'] = patientId;
                    l['Data'] = '';
                });
                setLabs(lr);
                setShortDescription(undefined);
                setLabDateMoment(undefined);
                setRequestedAttributes([]);
            }  
            else
            {
                var detail = await pApi.GetLabDetail(patientId, labId);
                detail.Values.forEach(l => {
                    l.Data = l.ValueString ?? ''
                    
                });
                let takenDate = detail.LabTakenDate;
                setLabs(detail.Values?.filter(x => !x.IsCalculation) ?? []);
                setShortDescription(detail.ShortDescription);
                setShowShortDescriptionOverride(IsEmpty(shortDescription));
                setLabDateMoment(IsEmpty(takenDate) ? null : moment(takenDate));
                setRequestedAttributes(detail.RequestedAttributes ?? []);
            }   
            
            setOpen(true);
        }
        catch(error)
        {
            setError(true, error, "Could not get the list of attributes");
        }
        finally
        {
            setBusy(false);
        }
    };

    const loadFullWorkup = async () => {
        let attrs = await pApi.GetFullWorkupAttributeIds(patientId);
        setRequestedAttributes(attrs);
    }

    const getTotal = () => {
        let cost = 0;
        labs.forEach(x => {
            if(requestedAttributes.includes(x.AttributeDefinitionId))
            {
                cost += x.AssociatedCosts;
            }
        });
        return cost;
    }
    const totalCost = getTotal();
    
    return (
        <>
            <ErrorDisplay />
            <Modal
                open={open}
                onOpen={triggerOpen}
                trigger={<Popup content={hoverText ?? `${isEdit ? (disabled || readOnly ? 'View' : 'Edit') : 'Add'} Testing Results`} trigger={<Button basic compact loading={busy} icon={icon ?? { name: (disabled || readOnly ? 'eye' : (isEdit ? 'edit outline' : 'add square')), color: iconColor ?? 'blue' }} onClick={triggerOpen}  />} />}
            >
                <Modal.Header>
                    <Grid columns={2}>
                        <Grid.Column>{`${isEdit ? 'Edit' : 'Add'} ${mode === LabDisplayMode.DataEntry ? 'Testing Results' : 'Requested Testing'}`}</Grid.Column>
                        <Grid.Column textAlign='right'>
                            {mode === LabDisplayMode.DataEntry ? (
                                <Checkbox label='Show Only Requested' checked={showOnlyRequested} onChange={() => setShowOnlyRequested(!showOnlyRequested)} /> 
                            ) : (
                                <Button basic content='Select Full Workup' onClick={loadFullWorkup} />
                            )}
                            
                        </Grid.Column>
                    </Grid>
                </Modal.Header>                       
                <Modal.Content image scrolling>
                    <Container fluid>
                        {disabled || readOnly ? (
                                <Message negative>
                                    <Message.Header>For viewing only. </Message.Header>
                                    <p>Modifications have been turned off, a newer Lab has been entered</p>
                                </Message>
                            ) : null }
                        <Form loading={busy}>
                            { mode === LabDisplayMode.DataEntry ? (
                                <Form.Field width='4'> 
                                    <label>Labs Drawn On:</label>
                                    <DateTimeInput name='labDate' placeholder='Labs Drawn...' dateTimeFormat='MM/DD/YYYY hh:mm A' timeFormat='AMPM' value={labDateMoment?.format('MM/DD/YYYY hh:mm A') ?? ''} error={IsEmpty(labDateMoment)} onChange={(e, { value }) => setLabDateMoment(moment(value))} />
                                </Form.Field>
                            ) : null}
                            {mode === LabDisplayMode.Request || showShortDescriptionOverride ?  (
                                <Form.Input label='Short Description' placeholder='Full Workup, retest, etc...' value={shortDescription} onChange={(e, data) => setShortDescription(data.value)} error={IsEmpty(shortDescription)} />
                            ): null}
                            <Divider />
                            {uniqueSectionNames.map(sectionName => Section(sectionName))}
                        </Form>
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    {mode === LabDisplayMode.Request && totalCost > 0 ?  <Label style={{float: 'left'}} basic color={ totalCost <= 500 ? null : (totalCost <= 1500 ? 'orange' : 'red')} icon='exclamation' content={`Cost $${totalCost.toFixed(2)}`} /> : null}
                    <Button
                        content="Save"
                        onClick={handleAdd}
                        positive
                        disabled={busy || disabled || readOnly || !canSave()}
                    />
                    <Button basic onClick={() => setOpen(false)} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default AddLab;
