import React, { useState } from 'react'
import { Button, ButtonProps, Form, Loader, Modal, Popup } from 'semantic-ui-react'
import { IsEmpty } from '../Helpers';


export interface NoteButtonProps extends Omit<ButtonProps, 'onClick'>
{
    onClick?: (note: string) => (void | Promise<void>);
    header?: string | React.ReactNode;
    additionalContent?: string | React.ReactNode;
    placeholder?: string;
    hoverContent?: string | React.ReactNode;
    requireNote?: boolean;
}

const NoteButton = ({ header = 'Please enter a Note', placeholder = 'Enter note here....', additionalContent, hoverContent, onClick, requireNote = true, ...props } : NoteButtonProps) => {
    const [busy, setBusy] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [note, setNote] = useState<string>();

    const handleClick = async () => {
        setNote(undefined);
        setOpen(true);
    };

    const handleSave = async () => {
        try {
            setBusy(true);
            await onClick(note);
            setOpen(false);
        } catch (error) {
            
        } finally {
            setBusy(false);
        }
    };

    return (
        <>
            {IsEmpty(hoverContent) ? <Button {...props} onClick={handleClick} /> : <Popup content={hoverContent} trigger={<Button {...props} onClick={handleClick} />} />}
            <Modal open={open} size='small'>
                <Modal.Header>{header}</Modal.Header>
                <Modal.Content>
                    <Loader active={busy} />
                    {additionalContent}
                    <Form>
                        <Form.TextArea style={{width: '100%'}} placeholder={placeholder} error={requireNote && (IsEmpty(note) || note.length < 4)} value={note ?? ''} onChange={(e, data) => setNote(data.value?.toString())}/>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' basic onClick={handleSave} disabled={busy || (requireNote && (IsEmpty(note) || note.length < 4))}>Save</Button>
                    <Button color='black' basic onClick={() => setOpen(false)} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default NoteButton;