import ApiBase from './base/ApiBase'

class OrganizationApi extends ApiBase
{
    private  controllerName: string = 'Organization';
    constructor() {
        super()
      }

    //Attribute Section Handlers
    async CreateLocation(organizationId: string, addUpdateLocationContext: AddUpdateLocationContext) : Promise<string>
    {
        return this.PostAsync(`${this.controllerName}/${organizationId}/Location`, addUpdateLocationContext)
    }

    async CreateOrganization(addUpdateOrganizationContext: AddUpdateOrganizationContext) : Promise<string>
    {
        return this.PostAsync(`${this.controllerName}`, addUpdateOrganizationContext)
    }

    async GetLocationDetail(locationId: string) : Promise<LocationDetail>
    {
        return this.GetAsync(`${this.controllerName}/Location/${locationId}`);
    }

    async GetLocationList(organizationId: string, includeInactive: boolean = false) : Promise<NameIdSlim[]>
    {
        return this.GetAsync(`${this.controllerName}/${organizationId}/Location?includeInactive=${includeInactive}`);
    }

    async GetOrganizationDetail(organizationId: string) : Promise<OrganizationDetail>
    {
        return this.GetAsync(`${this.controllerName}/${organizationId}`);
    }

    async GetOrganizationList(includeInactive: boolean = false) : Promise<NameIdSlim[]>
    {
        return this.GetAsync(`${this.controllerName}?includeInactive=${includeInactive}`);
    }

    async GetOrganizationUsers(organizationId: string) : Promise<NameIdSlim[]>
    {
        return this.GetAsync(`${this.controllerName}/${organizationId}/Users`);
    }

    async GetPossibleOrganizationUsers() : Promise<NameIdSlim[]>
    {
        return this.GetAsync(`${this.controllerName}/PossibleOrganizationUsers`);
    }

    async UpdateLocation(locationId: string, addUpdateLocationContext: AddUpdateLocationContext) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/Location/${locationId}`, addUpdateLocationContext);
    }

    async UpdateOrganization(organizationId: string, addUpdateOrganizationContext: AddUpdateOrganizationContext) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/${organizationId}`, addUpdateOrganizationContext);
    }

    async AddUserToOrganization(organizationId: string, userId: string) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/${organizationId}/User/${userId}`);
    }

    async RemoveUserFromOrganization(organizationId: string, userId: string) : Promise<void>
    {
        return this.DeleteAsync(`${this.controllerName}/${organizationId}/User/${userId}`);
    }
}

export interface AddUpdateLocationContext
{
    Addr1: string;
    Addr2: string;
    City: string;
    Country: string;
    IsActive: boolean;
    Name: string;
    PostalCode: string;
    State: string;
}

export interface AddUpdateOrganizationContext
{
    IsActive: boolean;
    Name: string;
}

export interface OrganizationDetail extends AddUpdateOrganizationContext
{
    CreatedBy: string;
    CreatedTimestamp: string;
    Id: string;
    LastModifiedUser: string;
    LastUpdatedTimestamp: string;
}

export interface LocationDetail extends AddUpdateLocationContext
{
    CreatedBy: string;
    CreatedTimestamp: string;
    Id: string;
    LastModifiedUser: string;
    LastUpdatedTimestamp: string;
}

export interface NameIdSlim
{
    Id: string;
    Name: string;
    IsActive: boolean;
    IsDeleted: boolean;
}
export default OrganizationApi;