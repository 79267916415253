import React, { useState } from 'react'
import { Header, Tab } from 'semantic-ui-react'
import TemplatesDisplay from '../components/TemplatesDisplay'
import AttributeManagementDisplay from '../components/AttributeManagementDisplay'
import { Roles, SourceType } from '../Enums'
import RiskFactorRuleBulkEditDisplay from '../components/RiskFactorRuleBulkEditDisplay'
import { HasRole } from '../Helpers'
import OrganizationManagement from '../components/OrganizationManagement'

const Settings = () => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const tabPanes = [];
    
    if(HasRole(Roles.settings_template_view))
    {
      tabPanes.push({
        menuItem: 'Document Templates', 
        render: () => (
          <Tab.Pane key='doc'>
            <TemplatesDisplay />
          </Tab.Pane>
        )
      });
    }
    if(HasRole(Roles.settings_attribute_healthhistory_view))
    {
      tabPanes.push({
          menuItem: 'Health History Attributes', 
          render: () => (
            <Tab.Pane key='hh'>
              <AttributeManagementDisplay sourceType={SourceType.HealthHistory} />
            </Tab.Pane>
          )
        });
      }
    if(HasRole(Roles.settings_attribute_lab_view))
    {
      tabPanes.push({
          menuItem: 'Testing Attributes', 
          render: () => (
            <Tab.Pane key='lab'>
              <AttributeManagementDisplay sourceType={SourceType.LabResult} />
            </Tab.Pane>
          )
        });
    }
    if(HasRole(Roles.settings_attribute_client_view))
    {
      tabPanes.push({
          menuItem: 'Client Attributes', 
          render: () => (
            <Tab.Pane key='client'>
              <AttributeManagementDisplay sourceType={SourceType.PatientVisit} />
            </Tab.Pane>
          )
        });
    }
    if(HasRole(Roles.settings_riskfactor_view))
    {
      tabPanes.push({
          menuItem: 'Risk Factor Weights', 
          render: () => (
            <Tab.Pane key='riskFactorWeights'>
              <RiskFactorRuleBulkEditDisplay />
            </Tab.Pane>
          )
        });
    }
    if(HasRole(Roles.settings_Organization_view))
    {
      tabPanes.push({
          menuItem: 'Organizations', 
          render: () => (
            <Tab.Pane key='organizations'>
              <OrganizationManagement />
            </Tab.Pane>
          )
        });
    }

    const handleTabChange = (e, data) => {
      setSelectedTabIndex(data.activeIndex);
    };

    return (
      <>
        <Header as='h2'>Settings</Header>
        <Tab menu={{ attached: true, tabular: true }} renderActiveOnly={true} panes={tabPanes} activeIndex={selectedTabIndex} onTabChange={handleTabChange} />
      </>
    )
}

export default Settings