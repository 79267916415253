import React, { useState } from 'react'
import { Button, ButtonProps, Popup } from 'semantic-ui-react'
import { DownloadAsCSV } from '../Helpers';
import { useErrorDisplay } from './ErrorDisplay';


export interface ExportButtonProps extends Omit<ButtonProps, 'onClick'>
{
    data: object[];
    filename: string;
    preprocessor?: (name: string, value: any) => any;
    colNames?: string[];
    hoverContent?: string | React.ReactNode;
}

const ExportButton = ({ data, filename, colNames = [], hoverContent = 'Export Data', preprocessor, ...props } : ExportButtonProps) => {
    const [busy, setBusy] = useState<boolean>(false);
    const { ErrorDisplay, setError } = useErrorDisplay();
    
    const handleClick = async () => {
        try {
            setBusy(true);
            DownloadAsCSV(filename, colNames, data, preprocessor);
        } catch(error) {
            setError(true, error, "Failed to export");
        } finally {
            setBusy(false);
        }
    };

    return (
        <>
            <ErrorDisplay />
            <Popup content={hoverContent} trigger={<Button loading={busy} icon={{ name: 'download '}} basic compact {...props} onClick={handleClick} />} />
        </>
    );
}

export default ExportButton;