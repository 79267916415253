import React, {useState} from 'react'
import { Button, Container, Modal, Input, Form, Popup } from 'semantic-ui-react'
import { useErrorDisplay } from './ErrorDisplay'
import AttributeDefinitionApi, { AttributeDefinition } from '../apiClients/AttributeDefinitionApi'
import { AttributeDataType, SourceType } from '../Enums'
import { GuidEmpty, IsEmpty, RealNumberCharactersOnly } from '../Helpers'
import GlobalVariables from '../GlobalVariables'
import RuleEditorPopup from './RuleEditorPopup'

interface AddEditAttributeDefinitionPopupProps
{
    sourceType: SourceType;
    attributeSectionId: string;
    attributeDefinitionId?: string;
    onAddEditAsync: (attr: AttributeDefinition) => Promise<void>;
}

const AddEditAttributeDefinitionPopup = ({ sourceType, attributeSectionId = null, attributeDefinitionId = null, onAddEditAsync = null } : AddEditAttributeDefinitionPopupProps) => {
    const dataScaffold : AttributeDefinition = {
        Id: GuidEmpty,
        AttributeSectionId: '',
        SourceType: sourceType,
        DisplayOrder: 0,
        Name: '',
        DisplayText: '',
        Info: '',
        Question: '',
        Formula: '',
        DataType: AttributeDataType.Boolean,
        UOM: '',
        IsCalculation: false,
        IsActive: true,
        modified: false,
        IsIncludedFullWorkup: false,
        AssociatedCosts: 0.00
    };

    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState(false);
    const { ErrorDisplay, setError } = useErrorDisplay();
    const [targetData, setTargetData] = useState({...dataScaffold});

    const api = new AttributeDefinitionApi();

    //Setup callback if it is passed in, default if not
    const onAddEditAsyncCallback = IsEmpty(onAddEditAsync) ? async (attr) => { } : onAddEditAsync;

    const isEdit = !IsEmpty(attributeDefinitionId);

    
    const loadData = async () => {
        try
        {
            setBusy(true);
            if(isEdit)
            {
                var td = await api.GetAttributeDefinition(attributeDefinitionId);
                setTargetData(td);
            }
            else
            {
                setTargetData({
                    ...dataScaffold, 
                    AttributeSectionId: attributeSectionId, 
                    SourceType: sourceType, 
                    Id: GuidEmpty,
                    DataType: (sourceType === SourceType.HealthHistory) ? AttributeDataType.Boolean : AttributeDataType.Numeric
                });
                // setWeights([]);
            }
            
        }
        catch(error)
        {
            setError(true, error, "Failed to load the attribute data")
        }
        finally
        {
            setBusy(false);
        }
    }

    const handleChange = (e, data) => {
        setTargetData({...targetData, [data.name]: data.value, modified: true });
    }

    const handleNumericChange = (e, data) => {
        let val = RealNumberCharactersOnly(data.value);       
        
        setTargetData({...targetData, [data.name]: val, modified: true });
    }
    const handleNameChange = (e, data) => {
        let val = data.value?.trim().replace(/\s+/g, '-');
        setTargetData({...targetData, [data.name]: val, modified: true });
    }
    const handleCheckboxChange = (e, data) => {
        let newItem = {...targetData, [data.name]: data.checked, modified: true };
        if(data.name === 'IsCalculation' && data.checked)
            newItem.IsIncludedFullWorkup = false;
        setTargetData(newItem);
    }
        
    const handleSave = async () => {
        try
        {
            setBusy(true);
            let id = '';
            targetData.UOM = targetData.DataType === AttributeDataType.Numeric ? targetData.UOM : null;
            if(IsEmpty(targetData.AssociatedCosts))
                targetData.AssociatedCosts = 0;
            if(isEdit)
            {
                id = targetData.Id;
                await api.UpdateAttributeDefinition(targetData.Id, targetData);
            }
            else
            {
                id = await api.AddAttributeDefinition(targetData);
            }
            setTargetData({...dataScaffold});
            await onAddEditAsyncCallback({...targetData, Id: id });
            setOpen(false);            
        }
        catch(error)
        {
            setError(true, error, "Failed to save the attribute data")
        }
        finally
        {
            setBusy(false);
        }
    }

    const canSave = () =>
    {
        let ret = !busy;       
        ret = ret && targetData.modified;
        
        ret = ret && targetData.AttributeSectionId === attributeSectionId;
        ret = ret && targetData.SourceType === sourceType;

        ret = ret && (targetData.Name?.length ?? 0) >= 1;
        ret = ret && (targetData.DisplayText?.length ?? 0) >= 1;

        //If it is a calculation we need a formula value
        ret = ret && (!targetData.IsCalculation || (targetData.Formula?.length ?? 0) >= 2);
        if(sourceType == SourceType.HealthHistory)
        {
            ret = ret && (targetData.Question?.length ?? 0) >= 1;            
        }        

        return ret;
    }
    
    const triggerOpen = async () => {
        loadData();
        console.log(sourceType);
        setOpen(true)
    };
    return (
        <>
            <ErrorDisplay />
            <Modal
                open={open}
                onOpen={triggerOpen}
                trigger={<Popup content={`${isEdit ? 'Edit' : 'Add'} Attribute Definition`} trigger={<Button basic compact loading={busy} icon={{ name: isEdit ? 'edit outline' : 'add', color: isEdit ? 'green' : 'blue'}} onClick={triggerOpen} />} />}
            >
                <Modal.Header>{isEdit ? 'Edit Attribute' : 'Create New Attribute' }</Modal.Header>
                <Modal.Content image scrolling>
                    <Container fluid>
                        <Form loading={busy}>
                            <Form.Checkbox label='Active' name='IsActive' checked={targetData.IsActive ?? false} onChange={handleCheckboxChange} />
                            <Form.Group widths='equal'>
                                <Form.Input label='Variable Name' name='Name' value={targetData.Name} onChange={handleNameChange} />
                                {targetData.SourceType !== SourceType.HealthHistory ? <Form.Select label="Value Data Type" options={GlobalVariables.AttributeDataTypes} name='DataType' value={targetData.DataType} onChange={handleChange} /> : null }
                                {targetData.DataType === AttributeDataType.Numeric ? (
                                    <Form.Input label='Unit of Measure' name='UOM' value={targetData.UOM ?? ''} onChange={handleChange} />                                  
                                ) : null }
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input label='Display Text' name='DisplayText' value={targetData.DisplayText} onChange={handleChange} />
                                {targetData.SourceType === SourceType.LabResult ? <Form.Input label='Info' name='Info' value={targetData.Info} onChange={handleChange} /> : null }
                                {targetData.SourceType === SourceType.LabResult ? <Form.Input label='Associated Costs' name='AssociatedCosts' value={targetData.AssociatedCosts} onChange={handleNumericChange} /> : null }                            
                            </Form.Group>
                            
                            {targetData.SourceType === SourceType.HealthHistory ? <Form.TextArea label='Question' name='Question' value={targetData.Question} onChange={handleChange} /> : null }
                            {targetData.SourceType !== SourceType.HealthHistory ? (
                                <>
                                    <Form.Group widths='equal'>
                                        {targetData.SourceType === SourceType.LabResult ? <Form.Checkbox label='Include in Full Workup?' name='IsIncludedFullWorkup' checked={targetData.IsIncludedFullWorkup} onChange={handleCheckboxChange} /> : null }
                                        <Form.Checkbox label='Is Value Calculated?' name='IsCalculation' checked={targetData.IsCalculation} onChange={handleCheckboxChange} />
                                        { targetData.IsCalculation ? (
                                            <Form.Field >
                                                <label>Value Formula</label>
                                                <Input value={targetData.Formula ?? ''} action name='Formula' readOnly onChange={handleChange}>
                                                    <input />
                                                    <RuleEditorPopup shouldBeBoolean={false} title={<>Editing rule for: <span style={{color: 'red'}}>{targetData.Name ?? ''}</span></>} onAddEditAsync={async (value) => handleChange(null, { name: 'Formula', value: value })} value={targetData.Formula ?? ''} trigger={<Button icon={{ name: 'ellipsis horizontal', color: 'green' }} />} />
                                                </Input>
                                            </Form.Field>
                                        ) : null }
                                    </Form.Group>
                                </>
                            ) : null}
                        </Form>
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Save Changes"
                        onClick={handleSave}
                        positive
                        disabled={!canSave()}
                    />
                    <Button basic onClick={() => setOpen(false)} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default AddEditAttributeDefinitionPopup;
