import React, {useState, useEffect, useRef } from 'react';
import { Table, Icon, Header, Segment, Accordion, Button, Popup, Checkbox } from 'semantic-ui-react';
import AttributeDefinitionApi, { AttributeDefinition, AttributeDefinitionDTO, AttributeSection } from '../apiClients/AttributeDefinitionApi';
import { Roles, SourceType } from '../Enums';
import { useErrorDisplay } from './ErrorDisplay';
import AddEditAttributeDefinitionPopup from './AddEditAttributeDefinitionPopup';
import AddEditAttributeSectionPopup from './AddEditAttributeSectionPopup';
import ChangeDisplayOrderPopup from './ChangeDisplayOrderPopup';
import ExecuteOnConfirm from './ExecuteOnConfirm';
import { useWindowDimensions } from './WindowDimensions';
import GlobalVariables from '../GlobalVariables';
import Search from './Search';
import { IsEmpty, OnlyForRole, HasRole } from '../Helpers';

export interface AttributeManagementDisplayProps
{
    sourceType: SourceType
}

interface AttributeManagementDisplayDataSource
{
    sections: AttributeSection[];
    definitions: AttributeDefinitionDTO[];
}

const AttributeManagementDisplay = ({sourceType} : AttributeManagementDisplayProps) => {
    const { height } = useWindowDimensions();
    const { ErrorDisplay, setError } = useErrorDisplay();
    const [ busy, setBusy ] = useState<boolean>(true);   
    const [ activeIndex, setActiveIndex ] = useState<number>(0); 
    const initialized = useRef<boolean>(false);
    const [ datasource, setDatasource] = useState<AttributeManagementDisplayDataSource>({ sections: [], definitions: [] });
    const [ showUsage, setShowUsage] = useState<boolean>(false);
    const [ expandAll, setExpandAll] = useState<boolean>(false);
    const [ searchText, setSearchText] = useState<string>();

    const api = new AttributeDefinitionApi();

    const handleAccordionClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index
    
        setActiveIndex(newIndex)
      }

    let title = '';
    switch(sourceType)
    {
        case SourceType.HealthHistory:
            title = 'Health History Attributes';
            break;
        case SourceType.LabResult:
            title = 'Testing Attributes';
            break;
        case SourceType.PatientVisit:
            title = 'Client Attributes';
            break;
        default:
            title = 'Other Attributes';
            break;
    }    

    const loadData = async (enableUsage: boolean) => {
        try
        {
            setBusy(true);
            let secs = await api.GetAttributeSections(sourceType);
            let defs = await api.GetAttributeDefinitions(sourceType, enableUsage);
            setDatasource({ sections: secs.sort((a,b) => a.DisplayOrder - b.DisplayOrder), definitions: defs.sort((a,b) => a.DisplayOrder - b.DisplayOrder) });
        }
        catch(error)
        {
            setError(true, error, "Unable to load data for attributes");
        }
        finally
        {
            setBusy(false);
        }
    };

    const changeShowUsage = async () => {
        var temp = !showUsage;
        setShowUsage(temp);
        if(temp)
        {//only re load data if we are going to show it
            await loadData(temp);
        }
    }
    const handleDeleteAttribute = async (item: AttributeDefinition) => {
        setBusy(true);
        await api.DeleteAttributeDefinition(item.Id);
        await loadData(showUsage);
        setBusy(false);
    }

    const handleDeleteSection = async (section: AttributeSection) => {
        setBusy(true);
        await api.DeleteAttributeSection(section.Id);
        await loadData(showUsage);
        setBusy(false);
    }

    const changeSectionDisplayOrder = async (id: string, newDisplayOrder: number) => {
        await api.UpdateAttributeSectionDisplayPosition(id, newDisplayOrder);
        await loadData(showUsage);                                            
        setActiveIndex(newDisplayOrder);
    }

    const changeAttributeDisplayOrder = async (id: string, newDisplayOrder: number) => {
        await api.UpdateAttributeDefinitionDisplayPosition(id, newDisplayOrder);
        await loadData(showUsage);
    }

    const updatedAttr = async (attr: AttributeDefinition) => {
        let updatedDefList = datasource.definitions.filter(x => x.Id === attr.Id);
        if(updatedDefList.length === 0)
        {
            await loadData(showUsage);
            return;
        }
        let defs = datasource.definitions.filter(x => x.Id !== attr.Id);
        setDatasource({ sections: [...datasource.sections], definitions: [...defs, {...updatedDefList[0], ...attr }].sort((a,b) => a.DisplayOrder - b.DisplayOrder) });
    }

    useEffect(() => {
        if(!initialized.current)
        {
            initialized.current = true;
            loadData(showUsage);
        }
      },[]);

    const sectionHasAttributes : (section: AttributeSection) => boolean = (section: AttributeSection) => {
        let attrs = datasource.definitions.filter(x => x.AttributeSectionId == section.Id);
        return (attrs.length > 0);
    };

    const filteredDefinitions = IsEmpty(searchText) ? datasource.definitions : datasource.definitions.filter(x => x.Name.toLowerCase().includes(searchText.toLowerCase()) || (x.DisplayText?.toLowerCase().includes(searchText.toLowerCase()) ?? false) || (x.Question?.toLowerCase().includes(searchText.toLowerCase()) ?? false)) ?? [];
    const filteredSectionHasAttributes : (section: AttributeSection) => boolean = (section: AttributeSection) => {
        let attrs = filteredDefinitions.filter(x => x.AttributeSectionId == section.Id);
        return (attrs.length > 0);
    };

    const getDeleteMessage = async (attributeDefinitionId, message) => {
        let usage = await api.GetAttributeDefinitionUsage(attributeDefinitionId);
        return usage.length == 0 ? (
                <p>{message}</p>
            ) : (
                <>
                    <p>{message}</p>
                    <span style={{color: 'red'}}>
                        <b>It is currently being used and this action will break the following:</b>
                        <ul>
                            {usage.map((item, i) => (
                                <li>{item}</li>
                            ))}
                        </ul>
                    </span>
                </>
            );
    }

    const ViewForSection = ({ section }) =>
    {
        let attrs = filteredDefinitions.filter(x => x.AttributeSectionId == section.Id).sort((a,b) => a.DisplayOrder - b.DisplayOrder); 
        if(attrs.length == 0)
            return null;
        return (
            <Table celled> 
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{ width: '20px'}}></Table.HeaderCell>
                        <Table.HeaderCell>Order</Table.HeaderCell>
                        <Table.HeaderCell>Variable Name</Table.HeaderCell>
                        { showUsage ? <Table.HeaderCell>Usage</Table.HeaderCell> : null }
                        <Table.HeaderCell>Display Text</Table.HeaderCell>
                        { sourceType === SourceType.LabResult ? <Table.HeaderCell>Info</Table.HeaderCell> : <></> }
                        { sourceType === SourceType.HealthHistory ? <Table.HeaderCell>Question</Table.HeaderCell> : <></> }
                        { sourceType !== SourceType.HealthHistory ? <Table.HeaderCell>DataType</Table.HeaderCell> : <></> }
                        { sourceType !== SourceType.HealthHistory ? <Table.HeaderCell>UOM</Table.HeaderCell> : <></> }
                        { sourceType === SourceType.LabResult ? <Table.HeaderCell>Full Workup</Table.HeaderCell> : <></> }
                        { sourceType !== SourceType.HealthHistory ? <Table.HeaderCell>Calculated</Table.HeaderCell> : <></> }
                        { sourceType !== SourceType.HealthHistory ? <Table.HeaderCell>Value Formula</Table.HeaderCell> : <></> }
                        { sourceType === SourceType.LabResult ? <Table.HeaderCell>Cost</Table.HeaderCell> : <></> }
                        <Table.HeaderCell style={{ width: '20px'}}></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>                       
                <Table.Body>
                    
                    {attrs.map(item => (
                        <Table.Row key={item.Id} style={ item.IsActive ? {} : { color: 'lightgray'}}>
                            <Table.Cell>
                                <Button.Group basic compact size='tiny'>
                                    {OnlyForRole(Roles.settings_attribute_modify, <AddEditAttributeDefinitionPopup sourceType={section.SourceType} attributeDefinitionId={item.Id} attributeSectionId={section.Id} onAddEditAsync={updatedAttr} />)}
                                    {OnlyForRole(Roles.settings_attribute_modify, <ChangeDisplayOrderPopup currentDisplayOrder={item.DisplayOrder} changeDisplayOrderAsync={(newDisplayOrder) => changeAttributeDisplayOrder(item.Id, newDisplayOrder)} />)}
                                </Button.Group>
                            </Table.Cell>
                            <Table.Cell>{item.DisplayOrder}</Table.Cell>
                            <Table.Cell>{item.Name}</Table.Cell>
                             {showUsage ? (
                                <Table.Cell>
                                    {item.RFUsageCount + item.ADUsageCount + item.CCUsageCount + item.DisplayCount == 0 ? (
                                        <>
                                            <Popup
                                                content='This variable is currently not used in any rule or content control'
                                                header='Unused'
                                                trigger={<Icon name='exclamation triangle' color='yellow' size='small' />}
                                            />
                                            &nbsp;
                                        </>
                                    ) : null}
                                    {item.RFUsageCount > 0 ? (
                                        <>
                                            <Popup
                                                content='This variable value used to determine Risk Factor Weights'
                                                header='Used in Risk Factor'
                                                trigger={<Icon name='weight' color='purple' size='small' />}
                                            />
                                            &nbsp;
                                        </>
                                    ) : null}
                                    {item.ADUsageCount > 0 ? (
                                        <>
                                            <Popup
                                                content='This variable value used in calculations for other variables'
                                                header='Used in Calculation'
                                                trigger={<Icon name='calculator' color='teal' size='small' />}
                                            />
                                            &nbsp;
                                        </>
                                    ) : null}
                                    {item.CCUsageCount > 0 ? (
                                        <>
                                            <Popup
                                                content='This variable value is used in rules that determine the visibility of a section of a document'
                                                header='Used to Show/Hide sections'
                                                trigger={<Icon name='hide' color='orange' size='small' />}
                                            />
                                            &nbsp;
                                        </>
                                    ) : null}
                                    {item.DisplayCount > 0 ? (
                                        <>
                                            <Popup
                                                content='This variable value is being displayed to clients in at least one template'
                                                header='Data Visible to Client'
                                                trigger={<Icon name='eye' color='blue' size='small' />}
                                            />
                                            &nbsp;
                                        </>
                                    ) : null}
                                </Table.Cell>
                             ) : null}
                            <Table.Cell>{item.DisplayText}</Table.Cell>
                            { sourceType === SourceType.LabResult ? <Table.Cell>{item.Info}</Table.Cell> : <></> }
                            { sourceType === SourceType.HealthHistory ? <Table.Cell>{item.Question}</Table.Cell> : <></> }
                            { sourceType !== SourceType.HealthHistory ? <Table.Cell>{item.DataType}</Table.Cell> : <></> }
                            { sourceType !== SourceType.HealthHistory ? <Table.Cell>{item.UOM}</Table.Cell> : <></> }
                            { sourceType === SourceType.LabResult ? <Table.Cell>{item.IsIncludedFullWorkup.toString()}</Table.Cell> : <></> }
                            { sourceType !== SourceType.HealthHistory ? <Table.Cell>{item.IsCalculation.toString()}</Table.Cell> : <></> }
                            { sourceType !== SourceType.HealthHistory ? (
                                <Table.Cell>
                                    { item.FormulaErrors.length === 0 ? null : (
                                        <Popup trigger={<Icon name='exclamation' inverted color='red' size='small' circular style={{marginTop: '5px'}} />} content={(
                                            <ul>
                                            {item.FormulaErrors.map((err, i) => (
                                                <li style={{color: 'red'}}>{err}</li>
                                            ))}
                                            </ul>
                                        )} />
                                    )}
                                    {item.Formula}
                                </Table.Cell>
                             ) : <></> }
                            { sourceType === SourceType.LabResult ? <Table.Cell>{item.AssociatedCosts}</Table.Cell> : <></> }
                            <Table.Cell>
                                <Button.Group basic compact size='tiny'>
                                    {OnlyForRole(Roles.settings_attribute_delete, <ExecuteOnConfirm displayText={() => getDeleteMessage(item.Id, `Are you sure you want to delete ${item.Name}`)} hoverText='Delete Attribute'  executeAsync={() => handleDeleteAttribute(item)} />)}
                                </Button.Group>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>  
        )
    }

    
    return (
        <>
            <ErrorDisplay />            
            <Segment color='yellow' loading={busy}>  
                <Segment attached='top'>                                  
                    <div style={{ display: 'inline-block', width: '70%'}}>
                        <Header as='h3'>
                            <Icon name='settings' />
                            <Header.Content>
                                {title}
                                <Header.Subheader>Add, Modify, Attribute Definitions and Sections</Header.Subheader>
                            </Header.Content>
                        </Header> 
                    </div>
                    <div style={{ display: 'inline-block', textAlign: 'right', verticalAlign: 'top', width: '30%'}}>
                        <Checkbox label='Expand All' checked={expandAll} onClick={() => setExpandAll(!expandAll)} style={{marginRight: '10px'}} />
                        <Search onChange={setSearchText} style={{ height: '30px', marginRight: '10px' }} />                    
                        <Button.Group basic compact size='tiny'>
                            <Popup content='Show usage column' trigger={<Button basic compact toggle active={showUsage} icon={{ name: 'eye', color: 'purple' }} onClick={changeShowUsage} />} />
                            <AddEditAttributeSectionPopup sourceType={sourceType} onAddEditAsync={() => loadData(showUsage)} />
                        </Button.Group>
                    </div>
                </Segment>
                <div style={{ maxHeight: (height - GlobalVariables.HeaderHeight - 200), overflowX: 'hidden', overflowY: 'auto'}}>
                    <Accordion fluid styled>                 
                        {datasource.sections.map((section, index) => {
                            return IsEmpty(searchText) || filteredSectionHasAttributes(section) ? (
                            <span key={section.Id}>
                                <Accordion.Title
                                    active={expandAll || activeIndex === index || !IsEmpty(searchText)}
                                    index={index}
                                    onClick={handleAccordionClick}>
                                    <Icon name='dropdown' />
                                    <div style={{ display: 'inline-block', width: '70%'}}>
                                        <Header color='blue' as='h4'>{section.DisplayOrder} - {section.Name}</Header>
                                    </div>
                                    { expandAll || activeIndex === index || !IsEmpty(searchText) ? (
                                        <Button.Group basic compact size='tiny' position='right' floated='right' onClick={(e) => e.stopPropagation()}>
                                            {OnlyForRole(Roles.settings_attribute_section_manage, <AddEditAttributeSectionPopup sourceType={section.SourceType} attributeSectionId={section.Id} onAddEditAsync={(id) => loadData(showUsage)} />)}
                                            {OnlyForRole(Roles.settings_attribute_create, <AddEditAttributeDefinitionPopup sourceType={section.SourceType} attributeSectionId={section.Id} onAddEditAsync={(id) => loadData(showUsage)} />)}
                                            {OnlyForRole(Roles.settings_attribute_section_manage, <ChangeDisplayOrderPopup currentDisplayOrder={section.DisplayOrder} changeDisplayOrderAsync={(newDisplayOrder) => changeSectionDisplayOrder(section.Id, newDisplayOrder)} />)}
                                            { HasRole(Roles.settings_attribute_section_manage) && IsEmpty(searchText) && !sectionHasAttributes(section) ? <ExecuteOnConfirm displayText='Are you sure you wish to delete this section?' hoverText='Delete Section' executeAsync={() => handleDeleteSection(section)} /> : null }
                                        </Button.Group>
                                    ) : null}
                                </Accordion.Title>
                                <Accordion.Content active={expandAll || activeIndex === index || !IsEmpty(searchText)}>
                                    {ViewForSection({ section })}
                                </Accordion.Content>
                            </span>
                            ) : null;
                        })}
                    </Accordion>
                </div>         
            </Segment>
        </>
    );
};

export default AttributeManagementDisplay;
