import { ContentControlType } from '../Enums';
import { OperationResult } from '../models/dtos';
import ApiBase from './base/ApiBase'

class TemplatesApi extends ApiBase {
    private  controllerName: string = 'Templates';
    constructor() {
        super()
    }

    async GetTemplates(includeInActive: boolean) : Promise<TemplateSlim[]> {
        return this.GetAsync(`${this.controllerName}?includeInActive=${includeInActive}`)
    }

    async DownloadTemplate(templateId: string, revisionId: string = null) : Promise<void> {
        // get the download key
        const query = revisionId ? `?revisionId=${revisionId}` : '';
        var downloadKey = await this.GetAsync(`${this.controllerName}/${templateId}/key${query}`);

        //redirect the browser to an unauthenticated endpoint using the onetime use authorization id from above.
        window.location.href = `${localStorage.API_BASE_PATH}/${this.controllerName}/download/${downloadKey}`;
    }

    async GetTemplate(templateId: string) : Promise<DocumentTemplate> {
        return this.GetAsync(`${this.controllerName}/${templateId}`);
    }

    async DeleteTemplate(templateId: string) : Promise<void> {
        return this.DeleteAsync(`${this.controllerName}/${templateId}`);
    }

    async UpdateContentControls(templateId: string, contentControls: ContentControl[]) : Promise<void> {
        return this.PostAsync(`${this.controllerName}/${templateId}/ContentControls`, contentControls);
    };

    async AddTemplate(name: string, file: string) : Promise<OperationResult<TemplateSlim>> {
        const formData = new FormData();
        formData.append('file', file);
        return this.UploadAsync(`${this.controllerName}?templateName=${name}`, formData);
    }

    async AddTemplateRevision(templateId: string, file: string) : Promise<OperationResult<TemplateSlim>> {
        const formData = new FormData();
        formData.append('file', file);
        return this.UploadAsync(`${this.controllerName}/${templateId}`, formData);
        
    }
}


export interface DocumentTemplate
{
    TemplateId: string;
    RevisionId: string;
    Name: string;
    ContentControls: ContentControl[];
}

export interface ContentControl
{
    Id: string;
    ReplacementValue: string;
    Type: ContentControlType;
    VisibilityRule: string;
    VisibilityRuleErrors: string[];
}

export interface TemplateSlim
{
    TemplateId: string;
    RevisionId: string;
    Name: string;
    LastUpdated: string;
    ModifiedBy: string;
}

export default TemplatesApi;