import React, {useState, useEffect, useRef } from 'react'

import { Table, Input, Icon, Header, Segment, Divider, Button, Container, Form, Popup } from 'semantic-ui-react'
import RiskFactorsApi from '../apiClients/RiskFactorsApi';
import { useErrorDisplay } from './ErrorDisplay'
import ExecuteOnConfirm from './ExecuteOnConfirm'
import VirtualTableRow from "./VirtualTableRow";
import RiskFactorRuleEditorPopup from './RiskFactorRuleEditorPopup'
import RuleEditorPopup from './RuleEditorPopup'
import { useWindowDimensions } from './WindowDimensions'
import GlobalVariables from '../GlobalVariables';
import { Roles } from '../Enums';
import Search from './Search';
import { PositiveFloatNumberCharactersOnly, IsEmpty, OnlyForRole, HasRole } from '../Helpers';

const RiskFactorRuleBulkEditDisplay = () => {
    const { ErrorDisplay, setError } = useErrorDisplay();
    const [ busy, setBusy ] = useState(true);
    const [ sourceModified, setSourceModified ] = useState(false);
    const initialized = useRef<boolean>(false);
    const [ datasource, setDatasource] = useState([]);
    const [ searchText, setSearchText] = useState('');
    const [ searchFilters, setSearchFilters] = useState({ t1: false, t2: false, t15: false, t3: false, t4: false, t5: false });
    const { height } = useWindowDimensions();
    const rfApi = new RiskFactorsApi();

    const isFiltered = searchFilters.t1 || searchFilters.t2 || searchFilters.t15 || searchFilters.t3 || searchFilters.t4 || searchFilters.t5;
    
    const loadData = async () => {
        try
        {
            setBusy(true);
            let bulk = await rfApi.GetRiskFactorRuleBulkEditList();
            setDatasource(bulk);
            setSourceModified(false);
        }
        catch(error)
        {
            setError(true, error, "Unable to load data");
        }
        finally
        {
            setBusy(false);
        }
    };
    let title = 'Risk Factor Rule Editor';
   

    const handleChanged = async (item, property, value) => {
        let newData = [...datasource];
        let index = newData.findIndex(x => x.Id === item.Id);
        newData.splice(index, 1, {...item, [property]: value, modified: true });
        setDatasource(newData);
        setSourceModified(true);
    }

    const handleNumberChanged = async (item, property, value) => { 
        let newData = [...datasource];
        let index = newData.findIndex(x => x.Id === item.Id);
        newData.splice(index, 1, {...item, [property]: PositiveFloatNumberCharactersOnly(value), modified: true });
        setDatasource(newData);  
        setSourceModified(true);
    }

    useEffect(() => {
        if(!initialized.current)
        {
            initialized.current = true;
            loadData();
        }
      });

    const handleSave = async () => {
        if(!sourceModified)
            return;
        try
        {
            setBusy(true);
            let modified = [...datasource];
            for(let i = 0; i < modified.length; i++)
            {
                let m = modified[i];
                if(m.modified !== true)
                    continue;
                
                await rfApi.UpdateRiskFactorRule(m.Id, {
                    DisplayToClient: m.DisplayToClient,
                    BooleanRule: m.BooleanRule,
                    Description: m.Description, 
                    IsLab: m.IsLab,
                    Type1Weight: parseFloat(m.Type1Weight === '' ? '0' : m.Type1Weight),
                    Type2Weight: parseFloat(m.Type2Weight === '' ? '0' : m.Type2Weight),
                    Type15Weight: parseFloat(m.Type15Weight === '' ? '0' : m.Type15Weight),
                    Type3Weight: parseFloat(m.Type3Weight === '' ? '0' : m.Type3Weight),
                    Type4Weight: parseFloat(m.Type4Weight === '' ? '0' : m.Type4Weight),
                    Type5Weight: parseFloat(m.Type5Weight === '' ? '0' : m.Type5Weight)});
                    m.modified = false;
            }
            setDatasource(modified);
            setSourceModified(false);
            await loadData();
        }
        catch(error)
        {
            setError(true, error, "Failed to save rules and weights");
        }
        finally
        {
            setBusy(false);
        }
    };

    const handleDelete = async (item) => {
        try
        {
            setBusy(true);

            //remove from remote
            await rfApi.DeleteRiskFactorRule(item.Id);

            //remove from local dataset
            let newData = [...datasource];
            let index = newData.findIndex(x => x.Id === item.Id);
            newData.splice(index, 1);
            setDatasource(newData);
        }
        catch(error)
        {
            setError(true, error, 'Failed to remove rule');
        }
        finally
        {
            setBusy(false);
        }
    }

    const hasWeight = (x) => {
        return x.Type1Weight > 0
            || x.Type2Weight > 0
            || x.Type15Weight > 0
            || x.Type3Weight > 0
            || x.Type4Weight > 0
            || x.Type5Weight > 0
    }
    let filteredData =  datasource.filter(x => IsEmpty(searchText) 
                                || x.Description?.toLowerCase().includes(searchText.toLowerCase()) 
                                || (x.BooleanRule?.toLowerCase() ?? '').includes(searchText.toLowerCase())) ?? [];
                            
    filteredData =  filteredData.filter(x => 
                            searchFilters.t1 ? x.Type1Weight > 0 : true
                            && searchFilters.t2 ? x.Type2Weight > 0 : true
                            && searchFilters.t15 ? x.Type15Weight > 0 : true
                            && searchFilters.t3 ? x.Type3Weight > 0 : true
                            && searchFilters.t4 ? x.Type4Weight > 0 : true
                            && searchFilters.t5 ? x.Type5Weight > 0 : true) ?? [];
    return (
        <>
            <ErrorDisplay />
            <Segment color='yellow'>  
                <div style={{ display: 'inline-block', width: '300px' }} >
                    <Header as='h3' >
                        <Icon name='settings' />
                        <Header.Content >
                            {title}
                            <Header.Subheader>Bulk editor for Risk Factor Rules</Header.Subheader>
                        </Header.Content>                    
                    </Header>
                </div>
                <div style={{ display: 'inline-block', width: `calc(100% - 300px)`, textAlign: 'right', verticalAlign: 'top', marginTop: '0px'}} >
                    <b>Filter by Type:  </b>
                    <Button.Group basic  style={{marginLeft: '10px', marginRight: '10px' }} compact>
                        <Button toggle active={searchFilters.t1} onClick={() => setSearchFilters({...searchFilters, t1: !searchFilters.t1 })} content='Inflammatory' />
                        <Button toggle active={searchFilters.t2} onClick={() => setSearchFilters({...searchFilters, t2: !searchFilters.t2 })} content='Atrophic' />
                        <Button toggle active={searchFilters.t15} onClick={() => setSearchFilters({...searchFilters, t15: !searchFilters.t15 })} content='Glycotoxic' />
                        <Button toggle active={searchFilters.t3} onClick={() => setSearchFilters({...searchFilters, t3: !searchFilters.t3 })} content='Toxic' />
                        <Button toggle active={searchFilters.t4} onClick={() => setSearchFilters({...searchFilters, t4: !searchFilters.t4 })} content='Vascular' />
                        <Button toggle active={searchFilters.t5} onClick={() => setSearchFilters({...searchFilters, t5: !searchFilters.t5 })} content='Traumatic' />
                    </Button.Group>
                    <Search loading={busy} onChange={setSearchText} />
                    
                    <Button.Group style={{marginLeft: '10px'}}>
                        {OnlyForRole(Roles.settings_riskfactor_create, <RiskFactorRuleEditorPopup onAddEditAsync={(id) => loadData()}/>)}
                    </Button.Group>
                </div>
                <Container fluid>
                    <Form loading={busy}>
                        <div style={{ maxHeight: `${height - GlobalVariables.HeaderHeight - 240}px`, overflowX: 'visible', overflowY: 'auto' }}>
                        <Table className='TableFixedHeader'>                            
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Description</Table.HeaderCell>
                                    <Table.HeaderCell>Display</Table.HeaderCell>
                                    <Table.HeaderCell>Lab?</Table.HeaderCell>
                                    <Table.HeaderCell>Rule</Table.HeaderCell>
                                    { (!isFiltered || searchFilters.t1) ? <Table.HeaderCell>Inflammatory</Table.HeaderCell> : null }
                                    { (!isFiltered || searchFilters.t2) ? <Table.HeaderCell>Atrophic</Table.HeaderCell> : null }
                                    { (!isFiltered || searchFilters.t15) ? <Table.HeaderCell>Glycotoxic</Table.HeaderCell> : null }
                                    { (!isFiltered || searchFilters.t3) ? <Table.HeaderCell>Toxic</Table.HeaderCell> : null }
                                    { (!isFiltered || searchFilters.t4) ? <Table.HeaderCell>Vascular</Table.HeaderCell> : null }
                                    { (!isFiltered || searchFilters.t5) ? <Table.HeaderCell>Traumatic</Table.HeaderCell> : null }
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {filteredData.map((w, index) => (
                                    // <Table.Row key={w.Id}>
                                    //     <Table.Cell><Form.Input name='Description' value={w.Description ?? ''} onChange={(e, data) => handleChanged(w, data.name, data.value)} /></Table.Cell>
                                    //     <Table.Cell>
                                    //         <Form.Field control={() => (
                                    //             <div className='ui action input'>
                                    //                 <Input value={w.BooleanRule ?? ''} readOnly attached='right' />
                                    //                 <RuleEditorPopup shouldBeBoolean={true} onAddEditAsync={(value) => handleChanged(w, 'BooleanRule', value)} value={w.BooleanRule ?? ''} btnProps={{ attached: 'left', compact: true }} />
                                    //             </div>
                                    //         )} />
                                    //     </Table.Cell>
                                    //     { (!isFiltered || searchFilters.t1) ? <Table.Cell><Form.Input name='Type1Weight' width='15' value={w.Type1Weight} onChange={(e, data) => handleChanged(w, data.name, data.value)} /></Table.Cell> : null }
                                    //     { (!isFiltered || searchFilters.t2) ? <Table.Cell><Form.Input name='Type2Weight' width='15' value={w.Type2Weight} onChange={(e, data) => handleNumberChanged(w, data.name, data.value)} /></Table.Cell> : null }
                                    //     { (!isFiltered || searchFilters.t15) ? <Table.Cell><Form.Input name='Type15Weight' width='15' value={w.Type15Weight} onChange={(e, data) => handleNumberChanged(w, data.name, data.value)} /></Table.Cell> : null }
                                    //     { (!isFiltered || searchFilters.t3) ? <Table.Cell><Form.Input name='Type3Weight' width='15' value={w.Type3Weight} onChange={(e, data) => handleNumberChanged(w, data.name, data.value)} /></Table.Cell> : null }
                                    //     { (!isFiltered || searchFilters.t4) ? <Table.Cell><Form.Input name='Type4Weight' width='15' value={w.Type4Weight} onChange={(e, data) => handleNumberChanged(w, data.name, data.value)} /></Table.Cell> : null }
                                    //     { (!isFiltered || searchFilters.t5) ? <Table.Cell><Form.Input name='Type5Weight' width='15' value={w.Type5Weight} onChange={(e, data) => handleNumberChanged(w, data.name, data.value)} /></Table.Cell> : null }
                                    //     <Table.Cell style={{ width: '20px'}}>
                                    //         <Button.Group>
                                    //             <ExecuteOnConfirm displayText='You wish to delete this Risk Factor Rule?' executeAsync={() => handleDelete(w)} />
                                    //         </Button.Group>
                                    //     </Table.Cell>
                                    // </Table.Row>
                                    <VirtualTableRow key={w.Id} height={60} rowStyle={w.modified ? { backgroundColor: "#E7F1FC" } : (hasWeight(w) ? {} : { backgroundColor: "#fbffbf" })} children={(
                                        <>
                                            <Table.Cell><Form.Input name='Description' value={w.Description ?? ''} onChange={(e, data) => handleChanged(w, data.name, data.value)} /></Table.Cell>
                                            <Table.Cell><Form.Checkbox name='DisplayToClient' checked={w.DisplayToClient} onChange={(e, data) => handleChanged(w, data.name, data.checked)} /></Table.Cell>
                                            <Table.Cell><Form.Checkbox name='IsLab' checked={w.IsLab} onChange={(e, data) => handleChanged(w, data.name, data.checked)} /></Table.Cell>
                                            <Table.Cell>
                                                <Form.Field>
                                                    <Input value={w.BooleanRule ?? ''} action readOnly name='BooleanRule' onChange={(e, data) => handleChanged(w, data.name, data.value)}>
                                                        { w.BooleanRuleErrors.length == 0 ? null : (
                                                            <Popup trigger={<Icon name='exclamation' inverted color='red' size='small' circular style={{marginTop: '5px'}} />} content={(
                                                                <ul>
                                                                {w.BooleanRuleErrors.map((err, i) => (
                                                                    <li style={{color: 'red'}}>{err}</li>
                                                                ))}
                                                                </ul>
                                                            )} />
                                                        )}
                                                        <input />
                                                        <RuleEditorPopup shouldBeBoolean={true} title={<>Editing rule for: <span style={{color: 'red'}}>{w.Description ?? ''}</span></>} onAddEditAsync={(value) => handleChanged(w, 'BooleanRule', value)} value={w.BooleanRule ?? ''} trigger={<Button icon={{ name: 'ellipsis horizontal', color: 'green' }} enabled={HasRole(Roles.settings_riskfactor_modify)}  />} />
                                                    </Input>
                                                </Form.Field>
                                            </Table.Cell>
                                            { (!isFiltered || searchFilters.t1) ? <Table.Cell><Form.Input name='Type1Weight' width='15' value={w.Type1Weight} onChange={(e, data) => handleChanged(w, data.name, data.value)} readOnly={!HasRole(Roles.settings_riskfactor_modify)} /></Table.Cell> : null }
                                            { (!isFiltered || searchFilters.t2) ? <Table.Cell><Form.Input name='Type2Weight' width='15' value={w.Type2Weight} onChange={(e, data) => handleNumberChanged(w, data.name, data.value)} readOnly={!HasRole(Roles.settings_riskfactor_modify)} /></Table.Cell> : null }
                                            { (!isFiltered || searchFilters.t15) ? <Table.Cell><Form.Input name='Type15Weight' width='15' value={w.Type15Weight} onChange={(e, data) => handleNumberChanged(w, data.name, data.value)} readOnly={!HasRole(Roles.settings_riskfactor_modify)} /></Table.Cell> : null }
                                            { (!isFiltered || searchFilters.t3) ? <Table.Cell><Form.Input name='Type3Weight' width='15' value={w.Type3Weight} onChange={(e, data) => handleNumberChanged(w, data.name, data.value)} readOnly={!HasRole(Roles.settings_riskfactor_modify)} /></Table.Cell> : null }
                                            { (!isFiltered || searchFilters.t4) ? <Table.Cell><Form.Input name='Type4Weight' width='15' value={w.Type4Weight} onChange={(e, data) => handleNumberChanged(w, data.name, data.value)} readOnly={!HasRole(Roles.settings_riskfactor_modify)} /></Table.Cell> : null }
                                            { (!isFiltered || searchFilters.t5) ? <Table.Cell><Form.Input name='Type5Weight' width='15' value={w.Type5Weight} onChange={(e, data) => handleNumberChanged(w, data.name, data.value)} readOnly={!HasRole(Roles.settings_riskfactor_modify)} /></Table.Cell> : null }
                                            <Table.Cell style={{ width: '20px'}}>
                                                <Button.Group compact basic size='tiny'>
                                                    {OnlyForRole(Roles.settings_riskfactor_delete, <ExecuteOnConfirm displayText='You wish to delete this Risk Factor Rule?' hoverText='Delete Rule' executeAsync={() => handleDelete(w)} />)}
                                                </Button.Group>
                                            </Table.Cell>
                                        </>
                                    )} />
                                ))}
                            </Table.Body>
                        </Table>
                        </div>
                    </Form>
                </Container>
                
                <Divider />                 
                <div style={{ textAlign: 'right'}}>
                    <Button content='Save' positive onClick={handleSave} disabled={busy || !sourceModified || !HasRole(Roles.settings_riskfactor_modify)} />
                    <Button content='Revert' negative basic onClick={loadData} disabled={busy || !sourceModified || !HasRole(Roles.settings_riskfactor_modify)} />
                </div>
            </Segment>
        </>
    );
};

export default RiskFactorRuleBulkEditDisplay;