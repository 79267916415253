
import React, {useRef, useState} from 'react'
import { Button, Modal, Icon, Header } from 'semantic-ui-react'
import { GetBodyContents, IsValidReactObj } from '../Helpers'
import HtmlReactParser from 'html-react-parser';

let setError: React.MutableRefObject<(show: boolean, message: string | React.ReactNode, subtitle: string) => void>;

export interface ErrorDisplayState
{
    message: string | React.ReactNode;
    subtitle: string;
    show: boolean;
}

const ErrorDisplay = () => {
    const [error, setErrorFunc] = useState<ErrorDisplayState>({ message: '', subtitle: null, show: false });
    setError = useRef<(show: boolean, message: string, subtitle: string) => void>();
    setError.current = (show, message, subtitle) => {
        if(typeof message == 'string' && message.startsWith("<"))
            message = HtmlReactParser(`<div>${GetBodyContents(message)}</div>`);
        else if(typeof message == 'string' && message.startsWith("{"))
        {
            var obj = JSON.parse(message);
            message = <div>
                <p>{obj.Message}</p>
                <p>RequestId: {obj.RequestId}</p>
            </div>
        }
        if(show)
        {
            console.error(`ErrorMsg ${message.toString()}`);
        }
        setErrorFunc({ show: show, message: message, subtitle: subtitle });
    };

    return (
        <Modal size='small' open={error.show}>
            <Modal.Header>
                <Header color='red'>
                    <Icon name='warning sign' />
                    <Header.Content>
                        Error
                        <Header.Subheader>{ (error.subtitle === null || error.subtitle === undefined || error.subtitle == '') ? 'Please Read Error' : error.subtitle }</Header.Subheader>
                    </Header.Content>
                </Header>
            </Modal.Header>
            <Modal.Content>
                {IsValidReactObj(error?.message) ? error?.message : <p>{(error?.message?.toString() ?? "Unknown Error")}</p>}
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={() => setErrorFunc({ ...error, show: false })} content="Ok" />
            </Modal.Actions>
        </Modal>
    );
};

export const useErrorDisplay = () => {
    return { ErrorDisplay, setError: (show: boolean, message: string | React.ReactNode, subtitle: string) => {setError.current(show, message, subtitle)} };
};