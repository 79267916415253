import React, { useState } from 'react'
import { Button, Modal, Icon, Table, Input, Form, Segment, Popup, Grid, Checkbox, Header } from 'semantic-ui-react'
import { useErrorDisplay } from './ErrorDisplay'
import TemplatesApi, { ContentControl } from '../apiClients/TemplatesApi';
import RuleEditorPopup from './RuleEditorPopup';
import { ContentControlType, EditorType } from '../Enums';
import { useWindowDimensions } from './WindowDimensions';
import Search from './Search';
import { IsEmpty } from '../Helpers';

interface ContentControlModified extends ContentControl
{
    HasChanges: boolean;
    Original: ContentControl;
}

const EditContentControlsDialog = ({ template }) => {
    const { height } = useWindowDimensions();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [contentControls, setContentControls] = useState<ContentControlModified[]>([]);
    const { ErrorDisplay, setError } = useErrorDisplay();
    const [ searchText, setSearchText] = useState<string>();
    const [showOnlyErrors, setShowOnlyErrors] = useState(false);

    const handleOpen = async () => {
        setIsLoading(true);
        setSearchText(null);
        setShowOnlyErrors(false);
        setOpen(true);
        

        try {
            // get content controls from the web service
            const result = await new TemplatesApi().GetTemplate(template.TemplateId);
            setContentControls(result.ContentControls as ContentControlModified[]);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        setContentControls([]);
        setOpen(false);
    }

    const handleSave = async () => {
        setIsLoading(true);
        try
        {
            const changedItems = contentControls
                .filter(x => x.HasChanges)
                .map(({Original, HasChanges, ...otherProps}) => otherProps);
            await new TemplatesApi().UpdateContentControls(template.TemplateId, changedItems);
            
            handleClose();
        }
        catch(error)
        {
            setError(true, error, 'failed to save changes');
        }
        finally
        {
            setIsLoading(false);
        }
    }

    const handleItemEdit = newItem => {
        const idx = contentControls.findIndex(x => x.Id === newItem.Id && x.Type === newItem.Type);
        if(idx == -1) return;

        // keep original version of the item in the Original property
        if(!newItem.Original) 
            newItem.Original = contentControls[idx];

        newItem.HasChanges = 
            newItem.Original.VisibilityRule !== newItem.VisibilityRule || 
            newItem.Original.ReplacementValue !== newItem.ReplacementValue;

        // var newControls = contentControls.map((x, i) => i == idx ? newItem : x);
        contentControls.splice(idx, 1, newItem);
        setContentControls([...contentControls]);
    };

    const hasChanges = contentControls.some(x => x.HasChanges)
    const changedCount = contentControls.filter(x => x.HasChanges).length;
    let displayControls = IsEmpty(searchText) ? [...contentControls] : contentControls.filter(x => x.Id.toLowerCase().includes(searchText.toLowerCase().trim()) || x.VisibilityRule.toLowerCase().includes(searchText.toLowerCase().trim()) || x.ReplacementValue.toLowerCase().includes(searchText.toLowerCase().trim()));
    displayControls = showOnlyErrors ? displayControls.filter(x => x.Type == ContentControlType.PlainText ? IsEmpty(x.ReplacementValue) : IsEmpty(x.VisibilityRule) || x.VisibilityRuleErrors.length > 0) : displayControls;
    return (
        <>
            <ErrorDisplay />
            <Modal closeOnDimmerClick={false} closeOnEscape={false}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                trigger={<Popup content='Edit Content control rules and content' trigger={<Button basic compact icon={{ name: 'edit outline', color: 'green'}} onClick={handleOpen} />} />}
            >
                <Segment attached='bottom'>
                    <Grid columns={2}>
                        <Grid.Column width={12}><Header as='H2'>Modify content controls for '{template.Name}'</Header></Grid.Column>
                        <Grid.Column width={4}  textAlign='right'>
                            <Search loading={isLoading} onChange={setSearchText} />
                            <Checkbox label='Only show errors' checked={showOnlyErrors} style={{marginTop: '3px'}} onChange={() => setShowOnlyErrors(!showOnlyErrors)} />
                        </Grid.Column> 
                    </Grid>
                </Segment>
                
                    
                
                <Modal.Content scrolling as={Segment} loading={isLoading} style={{height: `${height * .6}px`, padding: '0px'}}>
                    <Table celled striped sortable size='small' className='TableFixedHeader'>
                        <Table.Header>                            
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell />
                                <Table.HeaderCell>Visibility Rule</Table.HeaderCell>
                                <Table.HeaderCell>Replacement Value</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {displayControls.map(item => <EditContentControlItem key={`${item.Id}-${item.Type}`} item={item} onEdit={handleItemEdit} />)}
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <label>Count: {displayControls.length}{hasChanges ? `, Modified: ${changedCount}` : null}</label>
                                        
                    <Button
                        content="Save"
                        onClick={handleSave}
                        disabled={!hasChanges || isLoading}
                        positive
                    />
                    <Button color='black' basic onClick={handleClose} disabled={isLoading}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

const EditContentControlItem = ({ key, item, onEdit }) => {
    
    const handleChange = (e, data) => {
        const newItem = { ...item, [data.name]: data.value };
        onEdit(newItem);
    };
    const isReplacement = item.Type === 'PlainText';
    const getStyle = (item: ContentControlModified) => {
        let ret = {};
        if(item.HasChanges)
        {
            ret['color'] = '#5858c5';
            ret['fontWeight'] = 'bold';
        }
        if(item.Type == ContentControlType.PlainText && IsEmpty(item.ReplacementValue))
        {
            ret['backgroundColor'] = '#fbffbf';
        }
        return ret;
    };
    return (
        <Table.Row key={key} style={getStyle(item)}>
            <Table.Cell>{item.Id}</Table.Cell>
            <Table.Cell>{isReplacement ? <Icon name='exchange' /> : <Icon name='paragraph' />}</Table.Cell>
            <Table.Cell>
                <Form.Field>
                    <Input fluid value={item.VisibilityRule} action readOnly name='BooleanRule' onChange={handleChange} error={IsEmpty(item.VisibilityRule) && item.Type === ContentControlType.RichText}>
                        { item.VisibilityRuleErrors.length == 0 ? null : (
                            <Popup trigger={<Icon name='exclamation' inverted color='red' size='small' circular style={{marginTop: '5px'}} />} content={(
                                <ul>
                                {item.VisibilityRuleErrors.map((err, i) => (
                                    <li style={{color: 'red'}}>{err}</li>
                                ))}
                                </ul>
                            )} />
                        )}
                        <input />
                        <RuleEditorPopup shouldBeBoolean={true}  title={<>Editing rule for: <span style={{color: 'red'}}>{item.Id}</span></>} onAddEditAsync={async (value) => handleChange(null, { name: 'VisibilityRule', value: value })} value={item.VisibilityRule ?? ''} trigger={<Button icon={{ name: 'ellipsis horizontal', color: 'green' }} />} />
                    </Input>
                </Form.Field>
                
            </Table.Cell>
            <Table.Cell>
                {item.Type === ContentControlType.PlainText ? (
                    <Form.Field>
                        <Input fluid value={item.ReplacementValue} action readOnly name='ReplacementValue' onChange={handleChange} error={IsEmpty(item.ReplacementValue) && item.Type === ContentControlType.PlainText}>
                            <input />
                            <RuleEditorPopup shouldBeBoolean={false} title={<>Editing replacement value for: <span style={{color: 'red'}}>{item.Id}</span></>} onAddEditAsync={async (value) => handleChange(null, { name: 'ReplacementValue', value: value })} value={item.ReplacementValue ?? ''} trigger={<Button icon={{ name: 'ellipsis horizontal', color: 'green' }} />} editorType={EditorType.StringBuilder} />
                        </Input>
                    </Form.Field>
                ) : null }
            </Table.Cell>
        </Table.Row>
    );
};

export default EditContentControlsDialog;
