import React, {useState} from 'react'
import { Button, Modal, Container, Form, Popup } from 'semantic-ui-react'
import OrganizationApi, { AddUpdateLocationContext } from '../apiClients/OrganizationApi';
import { States } from '../Data/states';
import { IsEmpty } from '../Helpers';
import { useErrorDisplay } from './ErrorDisplay'

export interface AddLocationPopupProps
{
    organizationId: string;
    onChange?: (id: string) => Promise<void>;
    trigger?: React.ReactNode;
}
const AddLocationPopup = ({ organizationId, trigger, onChange = async (id) => { }}: AddLocationPopupProps) => {
    const scaffold: AddUpdateLocationContext = {
        Name: '',
        Addr1: '',
        Addr2: '',
        City: '',
        State: '',
        Country: '',
        PostalCode: '',
        IsActive: true
    }
    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState(true);
    const { ErrorDisplay, setError } = useErrorDisplay();

    const [targetData, setTargetData] = useState<AddUpdateLocationContext>();

    
    const api  = new OrganizationApi();
            
    const handleSave = async () => {
        try
        {
            if(!canSave())
                return;
            setBusy(true);
            let id = await api.CreateLocation(organizationId, targetData)
            setOpen(false);
            await onChange(id);
        }
        catch(error)
        {
            setError(true, error, "Failed to save the data")
        }
        finally
        {
            setBusy(false);
        }
    }

    const canSave = () => {
        let ret = !busy;
        ret = ret && !IsEmpty(targetData.Name);
        return ret;
    }

    const handleChange = (e, data) => {
        setTargetData({...targetData, [data.name]: (data.value === undefined ? data.checked : data.value) });
    }

    const handleTrigger = async () => {
        setBusy(false);
        setTargetData({...scaffold});
        setOpen(true);
    }
    
    return (
        <>
            <ErrorDisplay />
            <Modal
                open={open}
                onOpen={handleTrigger}
                trigger={trigger ?? <Popup content='Add new Location' trigger={<Button basic compact icon={{ name: 'add', color: 'blue' }} onClick={handleTrigger} size='mini' />} /> }
            >
                <Modal.Header>Create a new Organization</Modal.Header>
                <Modal.Content>
                    {IsEmpty(targetData) ? null : (
                        <Container fluid>
                            <Form loading={busy}>
                                <Form.Group>
                                    <Form.Input width={12} label='Location Name' name='Name' placeholder='Type new location here' value={targetData.Name ?? ''} onChange={handleChange} error={IsEmpty(targetData.Name)} />
                                    <Form.Checkbox width={4} label='Active?' name='IsActive' checked={targetData.IsActive} onChange={handleChange} />
                                </Form.Group>                                

                                <Form.Input label='Street Address' name='Addr1' placeholder='ie... 123 Main St' value={targetData.Addr1 ?? ''} onChange={handleChange} />                            
                                <Form.Input label='Street Address 2' name='Addr2' placeholder='' value={targetData.Addr2 ?? ''} onChange={handleChange} />
                                <Form.Group widths='equal'>
                                    <Form.Input label='City' name='City' placeholder='' value={targetData.City ?? ''} onChange={handleChange} />
                                    <Form.Select label='State' name='State' value={targetData.State} options={States.map((s, i) => { return { key: s.abbreviation, text: s.name, value: s.abbreviation }})} onChange={handleChange} />
                                    {/* <Form.Input label='State' name='State' placeholder='' value={targetData.State ?? ''} onChange={handleChange} /> */}
                                    <Form.Input label='Zip' name='PostalCode' placeholder='' value={targetData.PostalCode ?? ''} onChange={handleChange} />
                                </Form.Group>
                                <Form.Input label='Country' name='Country' placeholder='' value={targetData.Country ?? ''} onChange={handleChange} />
                            </Form>
                        </Container>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Save"
                        onClick={handleSave}
                        positive
                        disabled={!canSave()}
                    />
                    <Button color='black' basic onClick={() => setOpen(false)} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default AddLocationPopup;
