import { IsEmpty } from '../../Helpers';

class ApiBase {
    private processResponse: (response: Response) => Promise<any>;
    constructor() {
        this.processResponse = async (response: Response) => {

            if (!response.ok) {
                let body = await response.text();
                console.log(body);
                try
                {
                    let errorResponse = await JSON.parse(body);
                    let message = 'Unknown Error occurred';
                    if (errorResponse.RequestId !== undefined) {
                        message = `${errorResponse.Message}, RequestId: ${errorResponse.RequestId}`;
                    }
                    else if (errorResponse.errors !== undefined) {

                        message = `${Object.keys(errorResponse.errors)[0]}: ${errorResponse.errors[Object.keys(errorResponse.errors)[0]][0]}`;
                    }
                    throw message;
                }
                catch
                {
                    throw body;
                }
            }
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.indexOf("application/json") !== -1) {
                return await response.json();
            } else {
                return;
            }
        };
    }

    private getHeaders()
    {
        let ret = [
            ['Content-Type', 'application/json'],            
        ];
        if(!IsEmpty(localStorage.token))
        {
            ret.push(['Authorization', `bearer ${localStorage.token}`]);
        }
        return ret;
    }

    protected async GetAsync(relativePathAndQuery: string) {
        try {
            console.log(`GET: ${localStorage.API_BASE_PATH}/${relativePathAndQuery}`);
            let response = await fetch(`${localStorage.API_BASE_PATH}/${relativePathAndQuery}`, {
                method: 'get',
                mode: 'cors',
                headers: this.getHeaders()
            });

            return await this.processResponse(response);
        }
        catch (error) {
            throw `${error}`;
        }
    }

    protected async PostAsync(relativePathAndQuery: string, bodyObj = null, contentType: string = 'application/json') {
        try {
            let response = await fetch(`${localStorage.API_BASE_PATH}/${relativePathAndQuery}`, {
                method: 'post',
                headers: this.getHeaders(),
                body: contentType == 'application/json' && bodyObj ? JSON.stringify(bodyObj) : bodyObj
            });
            return await this.processResponse(response);
        }
        catch (error) {
            throw `${error}`;
        }
    }

    protected async UploadAsync(relativePathAndQuery: string, formData: FormData) {
        try {
            let response = await fetch(`${localStorage.API_BASE_PATH}/${relativePathAndQuery}`, {
                method: 'post',
                headers: {
                    'Authorization': `bearer ${localStorage.token}`
                },
                body: formData
            });
            return await this.processResponse(response);
        }
        catch (error) {
            throw `${error}`;
        }
    }

    protected async PutAsync(relativePathAndQuery: string, bodyObj = null, contentType = 'application/json') {
        try {
            let response = await fetch(`${localStorage.API_BASE_PATH}/${relativePathAndQuery}`, {
                method: 'put',
                mode: 'cors',
                headers: this.getHeaders(),
                body: contentType == 'application/json' && bodyObj ? JSON.stringify(bodyObj) : bodyObj
            });

            return await this.processResponse(response);
        }
        catch (error) {
            throw `${error}`;
        }
    }

    protected async DeleteAsync(relativePathAndQuery: string) {
        try {
            let response = await fetch(`${localStorage.API_BASE_PATH}/${relativePathAndQuery}`, {
                method: 'delete',
                mode: 'cors',
                headers: this.getHeaders()
            });
            return await this.processResponse(response);
        }
        catch (error) {
            throw `${error}`;
        }
    }
}

export default ApiBase;