import moment, { Moment } from 'moment';
import React, { useState } from 'react'
import { DateTimeInput } from 'semantic-ui-calendar-react';
import { Button, Dimmer, Grid, Header, Loader, Popup, Segment, Table } from 'semantic-ui-react'
import ReportApi, { SystemAuditItem } from '../apiClients/ReportApi';
import { useErrorDisplay } from '../components/ErrorDisplay';
import { useWindowDimensions } from '../components/WindowDimensions';
import GlobalVariables from '../GlobalVariables';
import { DownloadAsCSV, IsEmpty } from '../Helpers';

const SystemAudit = () => {
  const [startMoment, setStartMoment] = useState<Moment>(moment().subtract(10, 'days'));
  const [endMoment, setEndMoment] = useState<Moment>(moment());
  const [busy, setBusy] = useState<boolean>(false);
  const [data, setData] = useState<SystemAuditItem[]>([]);
  const { ErrorDisplay, setError } = useErrorDisplay();
  const { height } = useWindowDimensions();
  
  const api = new ReportApi();
  const search = async () => {
    try
    {
      setBusy(true);
      var response = await api.GetSystemAudit({ StartTime: startMoment.format('YYYY-MM-DDTHH:mm:ss'), EndTime: endMoment.format('YYYY-MM-DDTHH:mm:ss')});
      setData(response);
    }
    catch(error)
    {
      setError(true, error, "Failed to get report data");
    }
    finally
    {
      setBusy(false);
    }
  };

  const processCsvOutput = (name, value) => {
    if(name === 'Timestamp')
        return moment(value).format('MM/DD/YYYY hh:mm A');
    return value;
}
  
  return (
    <>
      <ErrorDisplay />
      <Dimmer.Dimmable as={Segment} blurring={busy} basic>
        <Segment color='grey' attached='top'>
          
          <Grid columns={2}>
            <Grid.Column>
              <Header>
                System Audit
                <Header.Subheader>
                  Select a time range
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column textAlign='right'>
              <Button.Group size='mini' compact basic>
                  <Popup content='Download as CSV' trigger={<Button icon='download' basic compact disabled={IsEmpty(data)} onClick={() => DownloadAsCSV(`SystemAudit`,['Timestamp', 'TargetType', 'TargetId', 'Action', 'ActionDetail', 'FirstName', 'LastName', 'Email'], data, processCsvOutput)}/>} />
              </Button.Group>
            </Grid.Column>
          </Grid>
          <Grid columns={3} style={{ margin: '10px'}}>
            <DateTimeInput placeholder='Start Time...' dateTimeFormat='MM/DD/YYYY hh:mm A' timeFormat='AMPM' value={startMoment?.format('MM/DD/YYYY hh:mm A') ?? ''} error={startMoment === null} onChange={(e, { value }) => setStartMoment(moment(value))} />
            <DateTimeInput placeholder='End Time...' dateTimeFormat='MM/DD/YYYY hh:mm A' timeFormat='AMPM' value={endMoment?.format('MM/DD/YYYY hh:mm A') ?? ''} error={endMoment === null} onChange={(e, { value }) => setEndMoment(moment(value))} />
            <Button content='Run' onClick={search} />
          </Grid>
          
        </Segment>
        <div style={{ height: `${height - GlobalVariables.HeaderHeight - 150}px`, overflowX: 'visible', overflowY: 'auto' }}>
        {IsEmpty(data) ? null : (
           
          <Table celled attached='bottom' className='TableFixedHeader' >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Timestamp</Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>TargetType</Table.HeaderCell>
                  <Table.HeaderCell>TargetId</Table.HeaderCell>
                  <Table.HeaderCell>Action</Table.HeaderCell>
                  <Table.HeaderCell>ActionDetail</Table.HeaderCell>
                  {/* <Table.HeaderCell>Email</Table.HeaderCell> */}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.map((item, i) => (
                  <Table.Row key={item.Id}>
                      <Table.Cell singleLine>{moment(item.Timestamp).format('MM/DD/YYYY hh:mm A')}</Table.Cell>
                      <Table.Cell singleLine>{item.FirstName} {item.LastName}</Table.Cell>
                      <Table.Cell singleLine>{item.TargetType}</Table.Cell>
                      <Table.Cell singleLine>{item.TargetId}</Table.Cell>
                      <Table.Cell singleLine>{item.Action}</Table.Cell>
                      <Table.Cell style={{ lineBreak: 'anywhere' }}>{item.ActionDetail}</Table.Cell>
                      {/* <Table.Cell singleLine>{item.Email}</Table.Cell> */}
                  </Table.Row>
                ))}
              </Table.Body>
          </Table>
          
        )}
        </div>
        <Dimmer active={busy}>
          <Loader active={busy} />
        </Dimmer>
      </Dimmer.Dimmable>
    </>
  );
}

export default SystemAudit