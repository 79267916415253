import ApiBase from './base/ApiBase'

class RiskFactorsApi extends ApiBase
{
    private  controllerName: string = 'RiskFactors';
    constructor() {
        super()
      }

    //Attribute Section Handlers
    async AddRiskFactorRule(riskFactorRulePayload: RiskFactorRulePayload) : Promise<string>
    {
        return this.PostAsync(`${this.controllerName}/Rules`, riskFactorRulePayload)
    }

    async DeleteRiskFactorRule(riskFactorRuleId: string) : Promise<void>
    {
        return this.DeleteAsync(`${this.controllerName}/Rules/${riskFactorRuleId}`)
    }

    async GetRiskFactorRule(riskFactorRuleId: string) : Promise<RiskFactorRule>
    {
        return this.GetAsync(`${this.controllerName}/Rules/${riskFactorRuleId}`);
    }

    async UpdateRiskFactorRule(riskFactorRuleId: string, riskFactorRulePayload: RiskFactorRulePayload) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/Rules/${riskFactorRuleId}`, riskFactorRulePayload);
    }

    async GetRiskFactorRuleBulkEditList() : Promise<RiskFactorRuleDTO[]>
    {
        return this.GetAsync(`${this.controllerName}/Rules/BulkEdit`);
    }
}

export interface RiskFactorRulePayload
{
    BooleanRule: string;
    Description: string;
    IsLab: boolean;
    DisplayToClient: boolean;
    Type15Weight: number;
    Type1Weight: number;
    Type2Weight: number;
    Type3Weight: number;
    Type4Weight: number;
    Type5Weight: number;
}

export interface RiskFactorRule extends RiskFactorRulePayload
{
    Id: string;
}

export interface RiskFactorRuleDTO extends RiskFactorRule
{
    BooleanRuleErrors: string[];
}

export default RiskFactorsApi;