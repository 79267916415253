import React, {useState, useEffect, useRef } from 'react'
import { Button, Grid, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import OrganizationApi, { NameIdSlim } from '../apiClients/OrganizationApi';
import { Roles } from '../Enums';
import GlobalVariables from '../GlobalVariables';
import { OnlyForRole } from '../Helpers';
import AddOrganizationPopup from './AddOrganizationPopup';
import { useErrorDisplay } from './ErrorDisplay';
import OrganizationView from './OrganizationView';
import ScrollView from './ScrollView';
import { useWindowDimensions } from './WindowDimensions';


const OrganizationManagement = () => {
    const { height } = useWindowDimensions();
    const { ErrorDisplay, setError } = useErrorDisplay();
    const initialized = useRef<boolean>(false);
    const [ busy, setBusy ] = useState<boolean>(false);
    const [organizations, setOrganizations] = useState<NameIdSlim[]>([]);
    const [selectedOrgId, setSelectedOrgId] = useState<string>();
    const [showInactive, setShowInactive] = useState<boolean>(false);

    const api = new OrganizationApi();

    useEffect(() => {
        if(initialized.current)
            return;
        initialized.current = true;
        load(showInactive);
        //initialize here
    });


    const load = async (displayInactive: boolean) => {
        try
        {
            setBusy(true);
            var orgList = await api.GetOrganizationList(displayInactive);
            setOrganizations(orgList);
        }
        catch(error)
        {
            setError(true, error, "Could not load Organization Data");
        }
        finally
        {
            setBusy(false);
        }
    }

    const handleAddOrganization = async (id) => { 
        await load(showInactive); 
        setSelectedOrgId(id); 
    }

    const toggleInactive = async () => {
        var show = !showInactive;
        setShowInactive(show);
        await load(show);
    }

    const viewHeight = height - GlobalVariables.HeaderHeight - 240;

    return (
        <>
            <ErrorDisplay />
            <Segment loading={busy} basic>
                <Grid divided>
                    <Grid.Column width={4}>
                        
                        <ScrollView height={viewHeight} content={(
                            <Table className='TableFixedHeader'>
                                <Table.Header>
                                    <Table.HeaderCell>
                                        <Grid>
                                            <Grid.Column width={12}>
                                                <Header as='h3' >
                                                    <Icon name='building outline' />
                                                    <Header.Content >
                                                        Organizations
                                                        <Header.Subheader>Manage organizations and their locations</Header.Subheader>
                                                    </Header.Content>                    
                                                </Header>
                                            </Grid.Column>
                                            <Grid.Column width={4} textAlign='right'>
                                                <Button.Group size='mini' compact basic>
                                                    <Popup content='Show/Hide Inactive Organizations' trigger={<Button toggle compact icon={{ name: 'eye', color: 'orange'}} active={showInactive} onClick={() => toggleInactive()} />} />
                                                    {OnlyForRole(Roles.settings_Organization_create, <AddOrganizationPopup onChange={handleAddOrganization}/>)}
                                                </Button.Group>
                                            </Grid.Column>
                                        </Grid>
                                    </Table.HeaderCell>
                                </Table.Header>
                                <Table.Body>
                                    {organizations.map((item, i) => (
                                        <Table.Row key={item.Id} active={item.Id === selectedOrgId} onClick={() => setSelectedOrgId(item.Id)}>
                                            <Table.Cell><span style={item.IsActive ? null : { color: 'red' }}>{item.Name}</span></Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )} />                        
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <ScrollView height={viewHeight} content ={<OrganizationView organizationId={selectedOrgId} onChange={() => load(showInactive)} />} />
                    </Grid.Column>
                </Grid>
            </Segment>
        </>
    );
}

export default OrganizationManagement;