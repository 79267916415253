import React, {useState} from 'react'
import { Button, IconProps, Modal, Popup, SemanticShorthandItem,  } from 'semantic-ui-react'
import { useErrorDisplay } from './ErrorDisplay'
interface ExecuteOnConfirm
{
    trigger?: React.ReactNode
    icon?: SemanticShorthandItem<IconProps>;
    hoverText?: string;
    displayText: string | React.ReactElement | (() => Promise<string>) | (() => Promise<React.ReactElement>);
    executeAsync: () => Promise<void>;
}

const ExecuteOnConfirm = ({ trigger = null, icon = { name: 'delete', color: 'red' }, hoverText = 'Delete', displayText, executeAsync } : ExecuteOnConfirm) => {
    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState(false);
    const [message, setMessage] = useState<React.ReactElement>();
    const { ErrorDisplay, setError } = useErrorDisplay();
    


    const handleConfirm = async () => {
        try
        {
            setBusy(true);
            await executeAsync();
        }
        catch(error)
        {
            setError(true, error, 'Failed to execute Action');
        }
        finally
        {
            setBusy(false);
            setOpen(false);
        }        
    }
    const triggerOpen = async () => {
        try
        {
            setBusy(true);
            if(typeof displayText === 'function')
            {
                let text = await displayText();
                setMessage(<>{text}</>);
            }
            else
            {            
                setMessage(<p>{displayText}</p>);
            }
            setOpen(true);
        }
        catch(error)
        {
            setError(true, error, "Failed to get the display Text");
        }
        finally
        {
            setBusy(false);
        }
    }
    return (
        <>
            <ErrorDisplay />
            <Modal size='small'
                open={open}                
                onOpen={triggerOpen}
                trigger={trigger ?? <Popup content={hoverText} trigger={<Button basic compact icon={icon} onClick={triggerOpen} loading={busy} />} />}
            >
                <Modal.Header>Are you sure?</Modal.Header>
                <Modal.Content scrolling>
                    <p>{message ?? ''}</p>
                </Modal.Content>
                <Modal.Actions>                    
                    <Button
                        content="Yes"
                        onClick={handleConfirm}
                        positive
                        basic
                        disabled={busy}
                    />
                    <Button color='red' onClick={() => setOpen(false)} disabled={busy}>No</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default ExecuteOnConfirm;
