import React, { useState, useEffect } from 'react'
import { Route, NavLink } from 'react-router-dom'
import { Button, Divider, Dropdown, Grid, Header, Icon, Image, Menu, Popup } from 'semantic-ui-react'

import logo from './resources/logo.png'
import 'semantic-ui-css/semantic.min.css'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import './App.css'
import './css/TableFixedHeader.css'

import Main from './pages/Main'
import Patients from './pages/Patients'
import UserInfo from './pages/UserInfo'
import Settings from './pages/Settings'
import GlobalVariables from './GlobalVariables'
import { Roles } from './Enums'
import HealthHistoryQuestionnaire from './pages/HealthHistoryQuestionnaire'
import ExecuteOnConfirm from './components/ExecuteOnConfirm'
import Metrics from './pages/Metrics'
import SystemAudit from './pages/SystemAudit'
import { OnlyForRole } from './Helpers'
import Dashboard from './pages/Dashboard'

const fixed = true;

const App = ({keycloak}) => {
  const [menuItem, setMenuItem] = useState("");
  ///Setup keycloak events to capture the token for use in the api classes
  keycloak.onAuthSuccess = () => {
    GlobalVariables.token = keycloak?.token
    let { roles } = keycloak['tokenParsed'] ?? {};
    GlobalVariables.roles = roles;//[...roles, ...keycloak['tokenParsed'].resource_access['AMFAS.Web'].roles ];
    console.log("Auth success, saving token");
  };
  keycloak.onAuthRefreshSuccess = () => {
    GlobalVariables.token = keycloak?.token
    let { roles } = keycloak['tokenParsed'] ?? {};
    GlobalVariables.roles = roles;//[...roles, ...keycloak['tokenParsed'].resource_access['AMFAS.Web'].roles ];
    console.log("Auth refresh success, saving token");
  };

  useEffect( () => {
      setMenuItem(window.location.pathname);
  }, []);
  // console.log('Keycloak is initialized:', initialized)
  let { given_name, family_name, email } = keycloak['idTokenParsed'] ?? {};
  GlobalVariables.UserName = email;
  const gotoUserAdmin = () => {
    window.location.href = localStorage.USER_MANAGEMENT_URL;
  }
  
  function MainContent() {
    
    if(keycloak.authenticated)
    {
        return (
          <>
            {/* <Route exact path="/" component={Main} /> */}
            
            {OnlyForRole(Roles.dashboard_access, <Route exact path="/" component={Dashboard} />)}
            {OnlyForRole(Roles.client_access, <Route exact path="/Clients" component={Patients}  />)}
            <Route path="/HealthHistoryQuestionnaire" component={HealthHistoryQuestionnaire}  />
            {OnlyForRole(Roles.token_info_access, <Route exact path="/AuthInfo" component={() => UserInfo({keycloak})} />) }
            {OnlyForRole(Roles.settings_access, <Route exact path="/Settings" component={Settings} />)}
            {OnlyForRole(Roles.metrics_access, <Route exact path="/Metrics" component={Metrics} />)}
            {OnlyForRole(Roles.system_audit_access, <Route exact path="/Audit" component={SystemAudit} />)}
          </>
        )
    }
    //Not Authenticated
    return (
      <>
        <Route exact path="/" component={Main} />
        <Route path="/HealthHistoryQuestionnaire" component={HealthHistoryQuestionnaire}  />
      </>
    )
  }

  function NavItems()
  {
    if(keycloak.authenticated)
    {
      return (
        <>
          {/* <Menu.Item active={menuItem === '/'}><NavLink to="/" onClick={() => setMenuItem('/')}>Main</NavLink></Menu.Item> */}
          {OnlyForRole(Roles.dashboard_access, <Menu.Item active={menuItem === '/'}><NavLink to="/" onClick={() => setMenuItem('/')}>Dashboard</NavLink></Menu.Item>)}
          {OnlyForRole(Roles.client_access, <Menu.Item active={menuItem === '/Clients'}><NavLink to="/Clients" onClick={() => setMenuItem('/Clients')}>Clients</NavLink></Menu.Item>)}          
          {OnlyForRole(Roles.metrics_access, <Menu.Item active={menuItem === '/Metrics'}><NavLink to="/Metrics" onClick={() => setMenuItem('/Metrics')}>Metrics</NavLink></Menu.Item>)}
          {OnlyForRole(Roles.system_audit_access, <Menu.Item active={menuItem === '/Audit'}><NavLink to="/Audit" onClick={() => setMenuItem('/Audit')}>System Audit</NavLink></Menu.Item>)}
          <div style={{ width: '100%'}}/>
          
            <Dropdown floating labeled button basic text={`${given_name} ${family_name}`} icon='user' className='icon' style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>
              <Dropdown.Menu>
                <ExecuteOnConfirm displayText='Logout user, Are you sure?' executeAsync={() => keycloak['logout']()} trigger={<Dropdown.Item>Logout</Dropdown.Item>} />
                
                {OnlyForRole(Roles.my_account_access, <Dropdown.Item onClick={() => keycloak['accountManagement']()}>Manage My Account</Dropdown.Item>)}
                {OnlyForRole(Roles.user_management_access, <Dropdown.Item onClick={gotoUserAdmin}>User Management</Dropdown.Item>)}
                {OnlyForRole(Roles.token_info_access, <Dropdown.Item onClick={() => setMenuItem('/AuthInfo')}><NavLink to="/AuthInfo">Auth Info</NavLink></Dropdown.Item>)}
              </Dropdown.Menu>
            </Dropdown>
          {OnlyForRole(Roles.settings_access, <Popup content='Settings' trigger={<Menu.Item active={menuItem === '/Settings'} ><NavLink as='div' to="/Settings" onClick={() => setMenuItem('/Settings')}><Icon fitted name='setting' size='large' style={{ marginLeft: '0.5em' }}/></NavLink></Menu.Item>} />)}
        </>
      )
    }
    //Not Authenticated
    return (
      <>
          <Menu.Item><NavLink to="/">Main</NavLink></Menu.Item>
          <Menu.Item position='right'>
              <Button onClick={() => keycloak['login']()}>Login</Button>
              <Button inverted={!fixed} primary={fixed} style={{ marginLeft: '0.5em' }}>Sign Up</Button>
          </Menu.Item>
      </>
    )
  }
  return (
      <> 
        <Grid columns={2}>
          <Grid.Column width={2}>
              <Image centered src={logo} style={{ margin: '20px'}} />
          </Grid.Column>
          <Grid.Column width={14}>
              {/* <Header textAlign='center' as='h1' style={{ marginTop: '50px'}}>A Mind for All Seasons</Header>         */}
          </Grid.Column>
        </Grid>
      {menuItem.includes('HealthHistoryQuestionnaire') ? <Divider /> : (
        <>
          <Menu
              fixed={null}
              inverted={false}
              pointing={true}
              secondary={!fixed}
              size='small'
            >
              <NavItems />
          </Menu> 
        </> 
        )}
        <MainContent />
      </>
    )
}


export default App
