import { useState, useEffect, useRef, RefObject } from 'react';

function getWindowDimensions(divRef?: RefObject<HTMLDivElement>) {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width: (divRef?.current?.offsetWidth ?? width),
    height: (divRef?.current?.offsetHeight ?? height)
  };
}


export function useWindowDimensions(divRef?: RefObject<HTMLDivElement>) {
  const windowDimensions = useRef(getWindowDimensions());
  const [retDimensions, setRetDimensions] = useState(getWindowDimensions());

  const handleResize = () => {
    var dimensions = getWindowDimensions();
    if(dimensions.height !== windowDimensions.current.height || dimensions.width !== windowDimensions.current.width)
    {
        windowDimensions.current = dimensions;
        setRetDimensions(dimensions);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return retDimensions;
}