import ApiBase from './base/ApiBase'
import { SourceType, Gender } from '../Enums';
import { RiskFactorRule } from './RiskFactorsApi'
import { PatientAttributeScaffold } from './AttributeDefinitionApi'

class PatientApi extends ApiBase
{
    private controllerName: string = 'Patient';
    constructor() {
        super()
      }

    async AddPatient(patient: PatientData, attributes: PatientAttribute[]) : Promise<string>
    {
        return this.PostAsync(this.controllerName, { PatientData: patient, PatientVisitAttributes: attributes });
    }

    async GetPatient(patientId: string) : Promise<PatientData>
    {
        return this.GetAsync(`${this.controllerName}/${patientId}`);
    }

    async GetFullWorkupAttributeIds(patientId: string) : Promise<string[]>
    {
        return this.GetAsync(`${this.controllerName}/${patientId}/FullWorkupAttributeIds`);
    }

    async GetPatientCurrentState(patientId: string) : Promise<PatientState>
    {
        return this.GetAsync(`${this.controllerName}/${patientId}/CurrentState`);
    }

    async GetPatientAttributeScaffold(patientId: string, sourceType: SourceType) : Promise<PatientAttributeScaffold[]>
    {
        return this.GetAsync(`${this.controllerName}/${patientId}/Scaffold?sourceType=${sourceType}`);
    }

    async GetPatientCurrentAttributeValues(patientId: string, sourceType: SourceType) : Promise<PatientAttributeValue[]>
    {
        return this.GetAsync(`${this.controllerName}/${patientId}/AttributeValues/Current?sourceType=${sourceType}`);
    }

    async UpdatePatient(patientId: string, patient: PatientData) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/${patientId}`, patient);
    }

    
    async SearchPatients(payload: PatientSearchPayload) : Promise<PatientData[]>
    {
        return this.PostAsync(`${this.controllerName}/Search`, payload)
    }

    async GetHealthHistoryList(patientId: string) : Promise<HealthHistoryListItem[]>
    {
        return this.GetAsync(`${this.controllerName}/${patientId}/HealthHistory`)
    }

    async AddHealthHistory(patientId: string, healthHistoryContext: HealthHistoryContext) : Promise<string>
    {
        return this.PostAsync(`${this.controllerName}/${patientId}/HealthHistory`, healthHistoryContext)
    }

    async DeleteHealthHistory(patientId: string, healthHistoryId: string) : Promise<void>
    {
        return this.DeleteAsync(`${this.controllerName}/${patientId}/HealthHistory/${healthHistoryId}`)
    }

    async GetHealthHistoryDetail(patientId: string, healthHistoryId: string) : Promise<HealthHistoryDetail>
    {
        return this.GetAsync(`${this.controllerName}/${patientId}/HealthHistory/${healthHistoryId}/Attributes`)
    }

    async AddAttributeValues(patientId: string, sourceId: string, attributeValues: PatientAttribute[]) : Promise<void>
    {
        return this.PostAsync(`${this.controllerName}/${patientId}/AttributeSource/${sourceId}`, attributeValues);
    }

    async UpdateAttributeValues(patientId: string, sourceId: string, attributeValues: PatientAttribute[]) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/${patientId}/UpdateAttributeValues?sourceId=${sourceId}`, attributeValues);
    }

    async GetLabList(patientId: string) : Promise<LabItem[]>
    {
        return this.GetAsync(`${this.controllerName}/${patientId}/Labs`)
    }
    async AddLab(patientId: string, payload: AddUpdateLabPayload) : Promise<string>
    {
        return this.PostAsync(`${this.controllerName}/${patientId}/Labs`, payload)
    }

    async UpdateLabInfo(patientId: string, labId: string, shortDescription: string, requestedAttributes: string[], labTakenDate?: string) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/${patientId}/Labs/${labId}`, { LabsTakenDate: labTakenDate, ShortDescription: shortDescription, RequestedAttributes: requestedAttributes })
    }

    async DeleteLab(patientId: string, labId: string) : Promise<void>
    {
        return this.DeleteAsync(`${this.controllerName}/${patientId}/Labs/${labId}`)
    }

    async GetLabAttributes(patientId: string, labId: string) : Promise<PatientAttributeValue[]>
    {
        return this.GetAsync(`${this.controllerName}/${patientId}/Labs/${labId}/Attributes`)
    }

    async GetLabDetail(patientId: string, labId: string) : Promise<LabDetailResponse>
    {
        return this.GetAsync(`${this.controllerName}/${patientId}/Labs/${labId}`)
    }
}



export interface LabDetailResponse
{
    LabTakenDate?: string;
    ShortDescription: string;
    RequestedAttributes: string[];
    Values: PatientAttributeValue[];
}

export interface AddUpdateLabPayload
{
    ShortDescription: string;
    LabsTakenDate?: string;
    RequestedAttributes: string[];
}

export interface HealthHistoryDetail
{
    Attributes: PatientAttributeValue[];
    Notes: string;
}

export interface PatientState
{
    Attributes: PatientAttributeValue[];
    SummaryWeights: SummaryWeightItem[];
    Weights: RiskFactorRule[];
}

export interface SummaryWeightItem
{
    Name: string;
    Weight: number;
}

export interface HealthHistoryContext
{
    Attributes: PatientAttribute[];
    Notes: string;
    CompletedBy: string;
}

export interface HealthHistoryListItem
{
    Id: string;
    Notes: string;
    InProgress: boolean;
    IsRequestGenerated: boolean;
    CreatedTimestamp: string;
    LastUpdatedTimestamp: string;
    CreatedUser: string;
    LastModifiedUser: string;
    EnteredBy: string;
}

export interface LabItem
{
    Id: string;
    ShortDescription: string;
    LabTakenDate: string;
    CreatedBy: string;
    CreatedTimestamp: string;
    LastModifiedUser: string;
    LastUpdatedTimestamp: string;
    IsDeleted: boolean;
    PatientId: string;
    RequestedAttributes: string[];
    AreRequestedValuesEntered: boolean;
}

export interface PatientSearchPayload
{
    DOB: string;
    LastName: string;
    Email: string;
    Phone: string;
}


export interface PatientData
{
    DOB: string;
    Gender: Gender;
    Id: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Email: string;
    Phone: string;
    PCP_Fax: string;
    PCP_Name: string;
    PCP_Phone: string;
    OrganizationId: string;
    LocationId: string;
}

export interface PatientAttribute
{
    AttributeValueOrDefinitionId: string;
    DataValue: string;
}

export interface PatientAttributeValue extends PatientAttributeScaffold
{
    Id: string;
    PatientId: string;
    Timestamp: string;
    ValueString: string;
    Data: any;
    PreviousAttributeValues: PrevAttributeValue[];
}

export interface PrevAttributeValue
{
    AttributeValueId: string;
    CreatedTimestamp: string;
    SourceId?: string;
    SourceType: SourceType;
    StringValue: string;
}

export default PatientApi;