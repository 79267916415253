import React, {useState, createRef} from 'react'
import { Button, Modal, Input, Message, Popup } from 'semantic-ui-react'
import { useErrorDisplay } from './ErrorDisplay'
import HealthHistoryRequestApi from '../apiClients/HealthHistoryRequestApi'

interface HealthHistoryRequestLinkProps
{
    patientId: string;
}
const HealthHistoryRequestLink = ({ patientId } : HealthHistoryRequestLinkProps) => {
    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState(false);
    const [ showCopied, setShowCopied ] = useState(false);
    const { ErrorDisplay, setError } = useErrorDisplay();
    const [ link, setLink ] = useState<string>('');
    const [ inputRef ] = useState(createRef<Input>());
    
    const api = new HealthHistoryRequestApi();
    const handleCopy = () => {
        //navigator.clipboard.writeText(link);
        inputRef.current.select();
        var success = false;
        function listener(e) {
            e.clipboardData.setData("text/plain", link);
            e.preventDefault();
            success = true;
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
        
        document.getSelection().removeAllRanges();
        
        setShowCopied(success);
    }

    const triggerOpen = async () => {
        try
        {
            setBusy(true);
            var hhrId = await api.CreateHealthHistoryRequestId(patientId);
            setLink(`${window.location.protocol}//${window.location.host}/HealthHistoryQuestionnaire?id=${hhrId}`);
            setOpen(true)
        }
        catch(error)
        {
            setError(true, error, "Failed to get a request link");
        }
        finally
        {
            setBusy(false);
        }
    };
    return (
        <>
            <ErrorDisplay />
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                onOpen={triggerOpen}
                trigger={<Popup content='Create Health History Request' trigger={<Button compact loading={busy} basic icon={{ name: 'linkify', color: 'orange'}} onClick={triggerOpen} />} />}
            >
                <Modal.Header>
                    History Request Link
                    
                </Modal.Header>
                <Modal.Content>
                    <Input fluid value={link} ref={inputRef} action={{
                                                color: 'teal',
                                                icon: 'copy',
                                                onClick: handleCopy
                                            }} />
                    
                </Modal.Content>
                <Modal.Actions>
                { showCopied ? <Message compact positive size='mini' content='Copied to clipboard!!' />  : null }
                    <Button color='black' basic onClick={() => setOpen(false)}>Close</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default HealthHistoryRequestLink;
