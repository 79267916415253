import React, {useState, useEffect, useRef } from 'react'
import { Divider, Statistic, Icon, Header, Segment, Grid, Table, Button } from 'semantic-ui-react'
import PatientApi from '../apiClients/PatientApi';
//import { PieChart } from 'react-minimal-pie-chart';
import { ClientEvents, Roles } from '../Enums'
import { OnlyForRole } from '../Helpers';
import PertinentPositivesPopup from './PertinentPositivesPopup';

const PatientRiskFactorDisplay = ({patientId}) => {
    const initialized = useRef<boolean>(false);
    const [patientState, setPatientState] = useState(null);
    const [busy, setBusy] = useState(true);
    const api = new PatientApi();

    const loadRiskState = async (currentClientId) => {
        try
        {
            setBusy(true);
            let state = await api.GetPatientCurrentState(currentClientId);
            setPatientState(state);
        }
        finally
        {
            setBusy(false);
        }
    };

    useEffect(() => {
        if(!initialized.current)
        {
            initialized.current = true;
            window.addEventListener(ClientEvents.refreshRiskFactors, e => loadRiskState(patientId));
            loadRiskState(patientId);
        }
      });

    const getColor = (weight) => 
    {
        if(weight < 15)
            return 'grey';
        else
            return 'red'
    }

    // const getIndexColor = (index) => 
    // {
    //     if(index === 0)
    //         return 'red';
    //     else if(index === 1)
    //         return 'orange';
    //     else if(index === 2)
    //         return 'blue';
    //     else
    //         return 'green'
    // }

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    // const defaultLabelStyle = {
    //     fontSize: '7px',
    //     fontFamily: 'sans-serif',
    // };
    // const shiftSize = 7;
    // let chartData = patientState?.SummaryWeights?.map((item, index) => ({ title: item.Name, value: item.Weight, color: getIndexColor(index) }));
      
    return (
        <Segment color='red' loading={busy} style={{maxHeight: '550px', display: 'grid'}}>  
            <div style={{ overflowY: 'hidden', overflowX: 'hidden', height: '100%' }}>
                <Grid columns={2}>
                    <Grid.Column width='12'>
                        <Header as='h3'>
                            <Icon name='heartbeat' />
                            <Header.Content>
                                Risk Factors
                                <Header.Subheader>Details calculated from Health history and Lab work</Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width='4' textAlign='right'>
                        {OnlyForRole(Roles.riskfactors_pertinent_positive_view, <Button.Group compact basic size='tiny'><PertinentPositivesPopup patientId={patientId} /></Button.Group>)}
                    </Grid.Column>
                </Grid>          
                
                
                <Divider /> 
                {patientState == null ? <h2>No Data</h2> : (
                    <>
                        
                        <Segment basic style={{zoom: .8}}>
                            <Statistic.Group size='mini'>
                                {patientState?.SummaryWeights === null ? <></> : patientState.SummaryWeights.map(item => (
                                    <Statistic key={item.Name} color={getColor(item.Weight)} size='tiny'>                        
                                        <Statistic.Label>{item.Name}</Statistic.Label>
                                        <Statistic.Value>{item.Weight}%</Statistic.Value>
                                    </Statistic>
                                ))}
                            </Statistic.Group>
                        </Segment>
                        {/* {chartData === null ? <></> : (
                            <Segment>
                                
                                <PieChart 
                                    data={chartData} 
                                    radius={PieChart.defaultProps.radius - shiftSize}
                                    segmentsShift={(index) => (index === 0 ? shiftSize : 0.5)}
                                    label={({ dataEntry }) => dataEntry.value }
                                    animate 
                                    totalValue={100}
                                    labelStyle={{
                                    ...defaultLabelStyle,
                                    }} 
                                    radius={32}
                                    labelPosition={122} 
                                    style={{ height: '300px' }}/>
                            </Segment>
                        )} */}
                        
                        <div style={{ overflowY: 'auto', overflowX: 'auto', height: '70%', verticalAlign: 'top'}}>
                            <Table celled striped compact size='small' className='TableFixedHeader'>
                                <Table.Header>
                                    <Table.Row >
                                        <Table.HeaderCell singleLine>Name</Table.HeaderCell>
                                        <Table.HeaderCell singleLine>Inflammatory</Table.HeaderCell>
                                        <Table.HeaderCell singleLine>Atrophic</Table.HeaderCell>
                                        <Table.HeaderCell singleLine>Glycotoxic</Table.HeaderCell>
                                        <Table.HeaderCell singleLine>Toxic</Table.HeaderCell>
                                        <Table.HeaderCell singleLine>Vascular</Table.HeaderCell>
                                        <Table.HeaderCell singleLine>Traumatic</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                
                                <Table.Body>                                
                                    {patientState?.Weights === null ? <></> : patientState.Weights.map(item => (
                                        <Table.Row key={item.Description} >
                                            <Table.Cell textAlign='left'><b>{capitalize(item.Description)}</b></Table.Cell>
                                            <Table.Cell textAlign='center'>{item.Type1Weight}</Table.Cell>
                                            <Table.Cell textAlign='center'>{item.Type2Weight}</Table.Cell>
                                            <Table.Cell textAlign='center'>{item.Type15Weight}</Table.Cell>
                                            <Table.Cell textAlign='center'>{item.Type3Weight}</Table.Cell>
                                            <Table.Cell textAlign='center'>{item.Type4Weight}</Table.Cell>
                                            <Table.Cell textAlign='center'>{item.Type5Weight}</Table.Cell>
                                        </Table.Row>
                                    ))}                        
                                </Table.Body>
                                
                            </Table>
                        </div> 
                    </>
                )}
            </div>
        </Segment>
    );
};

export default PatientRiskFactorDisplay;