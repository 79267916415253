import React, {useState, useEffect, useRef } from 'react'
import { Message, Icon, Header, Segment, Grid, Button, Form, Input, Label, Card, Popup } from 'semantic-ui-react'
import PatientApi, { PatientAttributeValue } from '../apiClients/PatientApi';
import { useErrorDisplay } from './ErrorDisplay'
import { SourceType, ClientEvents, Roles, AttributeDataType } from '../Enums'
import { RealNumberCharactersOnly, GuidEmpty, IsEmpty, HasRole } from '../Helpers';

const PatientCharacteristicsDisplay = ({patientId}) => {
    const initialized = useRef<boolean>(false);
    const [patientData, setPatientData] = useState<PatientAttributeValue[]>([]);
    const [calculatedData, setCalculatedData] = useState<PatientAttributeValue[]>([]);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [busy, setBusy] = useState<boolean>(true);
    const [messageInfo, setMessageInfo] = useState({
        visible: false,
        message: ''
    });
    const { ErrorDisplay, setError } = useErrorDisplay();
    const api = new PatientApi();

    const handleNumericChange = (e, data) => {
        //remove any nonNumeric data except . and -
        var val = RealNumberCharactersOnly(data.value);
        let newData = [];
        let modifiedData = false;
        patientData.forEach(item => {
            if(item.AttributeDefinitionId === data.id)
            {
                var newItem = {...item};
                if(newItem.ValueString !== val)
                {
                    newItem.ValueString = val;
                    modifiedData = true;
                }
                
                newData.push(newItem);
            }
            else
                newData.push(item);
        });
        setPatientData(newData);
        setIsModified(isModified || modifiedData);
    };

    const handleStringChange = (e, data) => {
        var val = data.value;
        let newData = [];
        let modifiedData = false;
        patientData.forEach(item => {
            if(item.AttributeDefinitionId === data.id)
            {
                var newItem = {...item};
                if(newItem.ValueString !== val)
                {
                    newItem.ValueString = val;
                    modifiedData = true;
                }
                
                newData.push(newItem);
            }
            else
                newData.push(item);
        });
        setPatientData(newData);
        setIsModified(isModified || modifiedData);
    };

    const handleBooleanChange = (e, data) => {
        var val = data.checked;
        let newData = [];
        let modifiedData = false;
        patientData.forEach(item => {
            if(item.AttributeDefinitionId === data.id)
            {
                var newItem = {...item};
                if(newItem.ValueString !== val)
                {
                    newItem.Data = val;
                    newItem.ValueString = val ? 'true' : 'false'
                    modifiedData = true;
                }
                
                newData.push(newItem);
            }
            else
                newData.push(item);
        });
        setPatientData(newData);
        setIsModified(isModified || modifiedData);
    };

    const saveChanges = async () => {
        try
        {
            setBusy(true);
            let addVals = [];
            patientData.forEach(item => {  
                if(item.ValueString !== null)          
                    addVals.push({ AttributeValueOrDefinitionId: item.AttributeDefinitionId, DataValue: item.ValueString });
            });
            await api.AddAttributeValues(patientId, GuidEmpty, addVals);
            console.log(addVals);
            await loadFromServer();
            setMessageInfo({ visible: true, message: 'Data was saved successfully' });
            setIsModified(false);
            window.dispatchEvent(new CustomEvent(ClientEvents.refreshRiskFactors));
        }
        catch(ex)
        {
            setError(true, ex, "Unable to save the patient characteristics")
        }
        finally
        {
            setBusy(false);
        }
    };

    const revertChanges = () => {
        loadFromServer().then(() => {
            setMessageInfo({ visible: true, message: 'Data was reverted successfully' });
            setIsModified(false);
        });
    };

    const loadFromServer = async () => {
        
        try
        {
            setBusy(true);
            let pa = await api.GetPatientCurrentAttributeValues(patientId, SourceType.PatientVisit);
            let modifiable = [];
            let calculated = [];
            pa?.forEach(a => {
                if(!a.IsCalculation)
                    modifiable.push(a);
                else if(!IsEmpty(a.ValueString) && a.ValueString !== '0')
                    calculated.push(a);
            });
            setPatientData(modifiable);
            setCalculatedData(calculated);
        }
        catch(ex)
        {
            setError(true, ex, "Unable to get data from the server");
        }
        finally
        {
            setBusy(false);
        }
    }

    useEffect(() => {
        if(!initialized.current)
        {
            initialized.current = true;
            loadFromServer();
        }
      });

    const ItemDisplay = ({ item }: { item: PatientAttributeValue }) =>
    {
        if(item.DataType == AttributeDataType.Boolean)
        {
            return (
                <Form.Checkbox label={item.DisplayText}
                    id={item.AttributeDefinitionId}
                    //style={{marginBottom: '3px', marginTop: '3px' }}
                    name={item.Name}
                    readOnly={!HasRole(Roles.client_characteristics_modify)}
                    checked={!IsEmpty(item.ValueString) && item.ValueString.toLowerCase() === 'true'}
                    onChange={handleBooleanChange}/>
            );
        }
        else if (item.DataType == AttributeDataType.Numeric)
        {
            return (
                <Form.Field key={item.AttributeDefinitionId}>
                    <label>{item.DisplayText}</label>
                    <Input fluid
                        id={item.AttributeDefinitionId}
                        name={item.Name}
                        readOnly={!HasRole(Roles.client_characteristics_modify)}
                        labelPosition={ !IsEmpty(item.UOM) ? 'right' : null }
                        value={item.ValueString ?? ''}
                        onChange={handleNumericChange}>
                        <input />
                        { !IsEmpty(item.UOM) ? <Label content={item.UOM ?? ''} basic /> : null }
                    </Input>
                </Form.Field>
            );
        }
        else if (item.DataType == AttributeDataType.String)
        {
            return (
                <Form.Field key={item.AttributeDefinitionId}>
                    <label>{item.DisplayText}</label>
                    <Input fluid
                        id={item.AttributeDefinitionId}
                        name={item.Name}
                        readOnly={!HasRole(Roles.client_characteristics_modify)}
                        value={item.ValueString ?? ''}
                        onChange={handleStringChange} />
                </Form.Field>
            );
        }
    }

    return (
        <>
            <ErrorDisplay />
            <Segment color='blue' loading={busy} style={{maxHeight: '550px', display: 'grid'}}> 
                <div style={{ overflowY: 'hidden', overflowX: 'hidden', height: '100%' }}>           
                    <Header as='h3'>
                        <Icon name='stethoscope' />
                        <Header.Content>
                            Client Characteristics
                            <Header.Subheader>Data collected on a client</Header.Subheader>
                        </Header.Content>
                    </Header>
                    <div style={{ overflowY: 'auto', overflowX: 'auto', height: "87%"}}> 
                        <Card fluid style={{width: '98%', margin: '4px'}}>
                            <Label attached='top' color='blue'>Entered</Label>
                            <Card.Content>
                                <Form>
                                    <Grid columns={2} stackable doubling>
                                        {patientData.length === 0 ? <></> : patientData.map(item => (
                                            <Grid.Column key={item.AttributeDefinitionId} style={{minWidth: '150px'}}><ItemDisplay item={item} /></Grid.Column>
                                        ))}                                            
                                    </Grid>
                                </Form>
                            </Card.Content>
                        </Card>
                        {isModified && HasRole(Roles.client_characteristics_modify) ? (
                            <Segment textAlign='right' basic>
                                <Popup content='Save Changes' trigger={<Button icon='save outline' color='green' onClick={saveChanges} disabled={busy} />}/>
                                <Popup content='Revert Changes' trigger={<Button icon='window close' color='red' basic onClick={revertChanges} disabled={busy} />} /><br />
                            </Segment>
                        ) : <></>}
                        
                        {messageInfo.visible ? (
                            <Message positive header='Success' content={messageInfo.message} onDismiss={() => setMessageInfo({ visible: false, message: '' })} />
                        ) : <></>}

                        {calculatedData.length === 0 ? <></> : (
                            <Card fluid style={{width: '98%', margin: '4px'}}>
                                <Label attached='top' color='orange'>Calculated</Label>
                                <Card.Content>
                                    <Form>
                                        <Grid columns={2} stackable doubling>
                                            {calculatedData.map(item => (
                                                <Grid.Column key={item.AttributeDefinitionId}>
                                                    <Form.Field key={item.AttributeDefinitionId}>
                                                        <label>{item.DisplayText}</label>
                                                        <Input fluid
                                                            id={item.AttributeDefinitionId}
                                                            name={item.Name}
                                                            readOnly
                                                            labelPosition={ !IsEmpty(item.UOM) ? 'right' : null }
                                                            value={item.ValueString ?? ''}>
                                                            <input />
                                                            { !IsEmpty(item.UOM) ? <Label content={item.UOM ?? ''} basic /> : null }
                                                        </Input>
                                                    </Form.Field>
                                                </Grid.Column>
                                            ))}
                                        </Grid>
                                    </Form>
                                </Card.Content>
                            </Card>
                        )}  
                    </div>              
                </div>
            </Segment>
        </>
    );
};

export default PatientCharacteristicsDisplay;