import { SourceType, AttributeDataType } from './Enums';
class GlobalVariables
{
    static token = null;
    static roles: string[] = [];
    static UserName: string = '';
    static HeaderHeight = 219;
    static get genderOptions() { 
        return [
            { key: 'Male', text: 'Male', value: 'Male' },
            { key: 'Female', text: 'Female', value: 'Female' }
        ];
    };
    static get sourceTypes() { 
        return [
            { key: SourceType.HealthHistory, text: 'Health History', value: SourceType.HealthHistory },
            { key: SourceType.LabResult, text: 'Lab Result', value: SourceType.LabResult },
            { key: SourceType.PatientVisit, text: 'Client', value: SourceType.PatientVisit },
            { key: SourceType.Other, text: SourceType.Other, value: SourceType.Other }
        ];
    };
    static get AttributeDataTypes() { 
        return [
            { key: AttributeDataType.Numeric, text: 'Numeric', value: AttributeDataType.Numeric },
            { key: AttributeDataType.Boolean, text: 'Boolean', value: AttributeDataType.Boolean },
            { key: AttributeDataType.String, text: 'String', value: AttributeDataType.String },
        ];
    };
}

export default GlobalVariables