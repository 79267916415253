import ApiBase from './base/ApiBase'

class DocumentApi extends ApiBase
{
    private controllerName: string = 'Document';

    constructor() {
        super()
      }

    async GetPatientDocumentList(patientId: string) : Promise<PatientDocumentSlim[]>
    {
        return this.GetAsync(`${this.controllerName}/${patientId}/List`);
    }

    async GetDocument(patientDocumentId: string) : Promise<void>
    {
        //Get an authorization id which is one time use using the authenticated endpoint
        var authorizationId = await this.GetAsync(`${this.controllerName}/GetDocumentAuthorizedId/${patientDocumentId}`);

        //redirect the browser to an unauthenticated endpoint using the onetime use authorization id from above.
        window.location.href = `${localStorage.API_BASE_PATH}/${this.controllerName}/${authorizationId}`;
    }

    async GenerateDocument(patientId: string, templateId: string) : Promise<string>
    {
        return this.PostAsync(`${this.controllerName}/${patientId}?templateId=${templateId}`);
    }

    async SaveManualRevision(patientId: string, fileName: string, file: File) : Promise<PatientDocumentSlim> {
        const formData = new FormData();
        formData.append('file', file);
        return this.UploadAsync(`Patients/${patientId}/ManualRevisions?fileName=${fileName}`, formData);
    }
}


export interface PatientDocumentSlim
{
    Id: string;
    Timestamp: string;
    Name: string;
}

export default DocumentApi;