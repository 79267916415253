import ApiBase from './base/ApiBase'
import { SourceType, AttributeDataType } from '../Enums'


class AttributeDefinitionApi extends ApiBase
{
    private controllerName: string = 'AttributeDefinition';

    constructor() {
        super()
      }

    //Attribute Section Handlers
    async GetAttributeSections(sourceType: SourceType) : Promise<AttributeSection[]>
    {
        return this.GetAsync(`${this.controllerName}/Section?sourceType=${sourceType}`)
    }

    async GetAttributeSection(attributeSectionId: string) : Promise<AttributeSection>
    {
        return this.GetAsync(`${this.controllerName}/Section/${attributeSectionId}`)
    }

    async AddAttributeSection(name: string, sourceType: SourceType, displayRule: string) : Promise<string>
    {
        return this.PostAsync(`${this.controllerName}/Section`, { Name: name, SourceType: sourceType, DisplayRule: displayRule});
    }

    async UpdateAttributeSection(attributeSectionId: string, name: string, sourceType: SourceType, displayRule: string) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/Section/${attributeSectionId}`, { Name: name, SourceType: sourceType, DisplayRule: displayRule});
    }

    async UpdateAttributeSectionDisplayPosition(attributeSectionId: string, position: number) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/Section/${attributeSectionId}/DisplayPosition/${position}`);
    }

    async DeleteAttributeSection(attributeSectionId: string) : Promise<void>
    {
        return this.DeleteAsync(`${this.controllerName}/Section/${attributeSectionId}`)
    }

    //Attribute Definition Handlers
    async GetAttributeDefinitions(sourceType: SourceType, showUsage: boolean) : Promise<AttributeDefinitionDTO[]>
    {
        return this.GetAsync(`${this.controllerName}?sourceType=${sourceType}&showUsage=${showUsage}`)
    }

    async GetAttributeDefinition(attributeDefinitionId: string) : Promise<AttributeDefinition>
    {
        return this.GetAsync(`${this.controllerName}/${attributeDefinitionId}`)
    }

    async GetAttributeDefinitionUsage(attributeDefinitionId: string) : Promise<string[]>
    {
        return this.GetAsync(`${this.controllerName}/${attributeDefinitionId}/Usage`)
    }

    async AddAttributeDefinition(attributeDefinition: AttributeDefinition) : Promise<string>
    {
        return this.PostAsync(`${this.controllerName}`, attributeDefinition);
    }

    async UpdateAttributeDefinition(attributeDefinitionId: string, attributeDefinition: AttributeDefinition) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/${attributeDefinitionId}`, attributeDefinition);
    }

    async UpdateAttributeDefinitionDisplayPosition(attributeSectionId: string, position: number) : Promise<void>
    {
        return this.PutAsync(`${this.controllerName}/${attributeSectionId}/DisplayPosition/${position}`);
    }

    async DeleteAttributeDefinition(attributeDefinitionId: string) : Promise<void>
    {
        return this.DeleteAsync(`${this.controllerName}/${attributeDefinitionId}`)
    }

    //Variable and scaffold
    async GetPossibleAttributeVariables() : Promise<AttributeVariableSlim[]>
    {
        return this.GetAsync(`${this.controllerName}/Variables`)
    }

    async GetAttributeScaffoldBySourceType(sourceType: SourceType) : Promise<PatientAttributeScaffold[]>
    {
        return this.GetAsync(`${this.controllerName}/Scaffold/${sourceType}`)
    }

    async EvaluateRuleForErrors(rule: string, shouldReturnBool: boolean) : Promise<string[]>
    {
        return this.PostAsync(`${this.controllerName}/Rule/Evaluate`, { Rule: rule, ShouldReturnBool: shouldReturnBool });
    }
}

export interface RuleCheckContext
{
    Rule: string;
    ShouldReturnBool: boolean
}

export interface AttributeDefinition
{
    Id: string;
    AttributeSectionId: string;
    SourceType: SourceType,
    DisplayOrder: number;
    Name: string;
    DisplayText: string;
    Info: string;
    Question: string;
    Formula: string;
    DataType: AttributeDataType;
    UOM: string;
    IsCalculation: boolean;
    IsActive: boolean;
    modified: boolean;
    IsIncludedFullWorkup: boolean;
    AssociatedCosts: number;
}

export interface AttributeDefinitionDTO extends AttributeDefinition
{
    RFUsageCount: number;
    ADUsageCount: number;
    CCUsageCount: number;
    DisplayCount: number;
    FormulaErrors: string[];
}

export interface PatientAttributeScaffold
{
    Id: string;
    AttributeDefinitionId: string;
    SectionName: string;
    SourceType: SourceType,
    DisplayOrder: number;
    Name: string;
    DisplayText: string;
    Info: string;
    Question: string;
    Formula: string;
    DataType: AttributeDataType;
    UOM: string;
    IsCalculation: boolean;
    modified: boolean;
    Data: any;
    IsIncludedFullWorkup: boolean;
    AssociatedCosts: number;
}

export interface AttributeSection
{
    Id: string;
    SourceType: SourceType,
    DisplayOrder: number;
    DisplayRule: string;
    Name: string;
    modified: boolean;
}

export interface AttributeVariableSlim
{
    AttributeDefinitionId: string;
    DataType: AttributeDataType;
    SourceType: SourceType,
    Name: string;
    UOM: string;
    modified: boolean;
}

export default AttributeDefinitionApi;