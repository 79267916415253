import React, {useState} from 'react'
import { Button, Modal, Container, Form, Popup } from 'semantic-ui-react'
import OrganizationApi from '../apiClients/OrganizationApi';
import { IsEmpty } from '../Helpers';
import { useErrorDisplay } from './ErrorDisplay'

export interface OrganizationEditorProps
{
    onChange?: (id: string) => Promise<void>;
    trigger?: React.ReactNode;
}
const AddOrganizationPopup = ({trigger, onChange = async (id) => { }}: OrganizationEditorProps) => {
    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState(true);
    const { ErrorDisplay, setError } = useErrorDisplay();

    const [orgName, setOrgName] = useState<string>();


    const api  = new OrganizationApi();
            
    const handleSave = async () => {
        try
        {
            if(!canSave())
                return;
            setBusy(true);
            let id = await api.CreateOrganization( {IsActive: true, Name: orgName })
            setOpen(false);
            await onChange(id);
        }
        catch(error)
        {
            setError(true, error, "Failed to save the data")
        }
        finally
        {
            setBusy(false);
        }
    }

    const canSave = () => {
        let ret = !busy;
        ret = ret && !IsEmpty(orgName);
        return ret;
    }

    const handleChange = (e, data) => {
        setOrgName(data.value);
    }

    const handleTrigger = async () => {
        setBusy(false);
        setOrgName('');
        setOpen(true)
    }
    
    return (
        <>
            <ErrorDisplay />
            <Modal
                open={open}
                onOpen={handleTrigger}
                trigger={trigger ?? <Popup content='Add new Organization' trigger={<Button basic compact icon={{ name: 'add', color: 'blue' }} onClick={handleTrigger} size='mini' />} /> }
            >
                <Modal.Header>Create a new Organization</Modal.Header>
                <Modal.Content scrolling>
                    <Container fluid>
                        <Form loading={busy}>
                            <Form.Input label='Organization Name' name='OrgName' placeholder='Type new org name here' value={orgName ?? ''} onChange={handleChange} />
                        </Form>
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Save"
                        onClick={handleSave}
                        positive
                        disabled={!canSave()}
                    />
                    <Button color='black' basic onClick={() => setOpen(false)} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default AddOrganizationPopup;
