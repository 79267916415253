import React, { useState, useRef } from 'react'
import { Icon, IconProps, Input } from 'semantic-ui-react'
import { IsEmpty } from '../Helpers';

export interface SearchProps
{
    onChange: (newValue: string) => void;
    style?: any;
    iconProps?: IconProps;
    clearIconProps?: IconProps;
    size?: 'mini' | 'small' | 'large' | 'big' | 'huge' | 'massive';
    placeholder?: string;
    loading?: boolean;
    delay?: number;
}

const Search = ({ onChange, size = 'mini', style = {}, iconProps = {}, clearIconProps = {}, placeholder = 'Search...', loading = false, delay = 400 }: SearchProps) => {
    const timer = useRef<NodeJS.Timer>(null);
    const [searchText, setSearchText] = useState<string>();

    const searchTextChanged = (value: string) => {
        setSearchText(value);
        clearTimeout(timer.current);
        timer.current = setTimeout(() => onChange(value), delay);
    };

    return (
        <Input size={size} loading={loading} value={searchText} style={style} onChange={(e, data) => searchTextChanged(data.value)} placeholder={placeholder} icon={IsEmpty(searchText) ? <Icon name='search' fitted color='blue' {...iconProps} /> : <Icon name='cancel' fitted link={true} color='red' {...clearIconProps} onClick={() => searchTextChanged('')} />} />   
    );
}


export default Search;