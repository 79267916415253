import React, { useState } from 'react'
import { Button, ButtonProps, Loader, Modal, Popup } from 'semantic-ui-react'
import { IsEmpty } from '../Helpers';


export interface ConfirmButtonProps extends Omit<ButtonProps, 'onClick'>
{
    onClick?: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => (void | Promise<void>);
    headerContent?: string | React.ReactNode;
    hoverContent?: string | React.ReactNode;
    message: string | React.ReactNode;
}

const ConfirmButton = ({ headerContent = 'Are you sure?', hoverContent = 'Delete', message, onClick, ...props } : ConfirmButtonProps) => {
    const [busy, setBusy] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = async () => {
        setOpen(true);
    };

    const handleConfirm = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
        try {
            setBusy(true);
            await onClick(e, data);
            setOpen(false);
        } catch (error) {
            
        } finally {
            setBusy(false);
        }
    };

    return (
        <>
            {IsEmpty(hoverContent) ? <Button {...props} onClick={handleClick} /> : <Popup content={hoverContent} trigger={<Button {...props} onClick={handleClick} />} />}
            <Modal open={open} size='small'>
                <Modal.Header>{headerContent}</Modal.Header>
                <Modal.Content>
                    <Loader active={busy} />
                    {typeof(message) === 'string' ? <p>{message}</p> : message}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Yes"
                        onClick={handleConfirm}
                        positive
                        basic
                        disabled={busy}
                    />
                    <Button color='red' onClick={() => setOpen(false)} disabled={busy}>No</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default ConfirmButton;