import React, {useState} from 'react'
import { Button, Modal, Form, Container, Checkbox, Popup, Message } from 'semantic-ui-react'
import PatientApi from '../apiClients/PatientApi';
import { useErrorDisplay } from './ErrorDisplay'
import { SourceType } from '../Enums';

const AddHealthHistory = ({ patientId, disabled = false, readOnly = false, onAddHealthHistory, icon = null, iconColor = null, healthHistoryId = null, previousHealthHistoryId = null }) => {
    const [open, setOpen] = useState(false);
    const [notes, setNotes] = useState(null);
    const [history, setHistory] = useState([]);
    const [busy, setBusy] = useState(false);
    const { ErrorDisplay, setError } = useErrorDisplay();
    const pApi = new PatientApi();

    const isEdit = healthHistoryId != null;
    
    const handleChange = (e, data) => {
        const newHistory = [ ...history]
        newHistory[newHistory.findIndex(x => x.AttributeDefinitionId == data.name)].Data = data.checked;
        setHistory(newHistory);
    };

    const handleAdd = async () => {
        let outItems = [];
        
        try
        {
            setBusy(true);
            if(healthHistoryId == null)
            {
                history.forEach(x => outItems.push({ AttributeValueOrDefinitionId: x.AttributeDefinitionId, DataValue: `${x.Data}` }));
                await pApi.AddHealthHistory(patientId, { Notes: notes, Attributes: outItems, CompletedBy: null });
                await onAddHealthHistory(patientId);
            }
            else
            {
                history.forEach(x => outItems.push({ AttributeValueOrDefinitionId: x.Id, DataValue: `${x.Data}` }));
                await pApi.UpdateAttributeValues(patientId, healthHistoryId, outItems);
                await onAddHealthHistory(patientId);
            }
            
            setOpen(false);
            setHistory([]);
        }
        catch(ex)
        {
            setError(true, ex, "Unable to add/modify health history");
        }
        finally
        {
            setBusy(false);
        }
    };

    const handleCopyPrevious = async () => {
        try
        {
            if(previousHealthHistoryId == null)
                return;
            setBusy(true);
            let hh =  await pApi.GetHealthHistoryDetail(patientId, previousHealthHistoryId); 
            hh.Attributes.forEach(h => {
                h['Data'] = h['ValueString'] === 'True'
            });
            setHistory(hh.Attributes);
        }    
        catch(error)
        {
            setError(true, error, "Failed to get the last Health History");
        }
        finally
        {
            setBusy(false);
        }
    }
    
    function ItemDisplay()
    {
        let cat = '';
        return (history == null || history.length <= 0) ? <></> : history.map(item => {
            if(item.SectionName != cat)
            {
                cat = item.SectionName;
                
                return (
                    <Form.Field as='div' key={item.AttributeDefinitionId} disabled={disabled}>
                        <h2>{cat}</h2>
                        <Checkbox key={item.AttributeDefinitionId} name={item.AttributeDefinitionId} label={item.Question} checked={item.Data} onChange={handleChange}  disabled={disabled} readOnly={readOnly} />
                    </Form.Field>
                )
            }
            return (
                <Form.Field as='div' key={item.AttributeDefinitionId}  disabled={disabled}>
                    <Checkbox key={item.AttributeDefinitionId} name={item.AttributeDefinitionId} label={item.Question} checked={item.Data} onChange={handleChange}  disabled={disabled} readOnly={readOnly} />
                </Form.Field>
            )
        })
    }

    const triggerOpen = async () => 
    {
        try
        {
            setBusy(true);
            if(healthHistoryId == null)
            {
                let attrs = await pApi.GetPatientAttributeScaffold(patientId, SourceType.HealthHistory); 
                attrs.forEach(h => {
                
                    h['PatientId'] = patientId;
                    h['Data'] = false;
                });
                setHistory(attrs);
                setNotes(null);
            }  
            else
            {
                let hh = await pApi.GetHealthHistoryDetail(patientId, healthHistoryId);
                hh.Attributes.forEach(h => {
                    h['Data'] = h['ValueString']?.toLowerCase() === 'true'
                });
                setHistory(hh.Attributes);
                setNotes(hh.Notes);
            }                             
            
            setOpen(true);
            
        }
        catch(error)
        {
            setError(true, error, "Failed to get the list of attributes");
        }
        finally
        {
            setBusy(false);
        }
    };
    
    return (
        <>
            <ErrorDisplay />
            <Modal
                open={open}
                onOpen={triggerOpen}
                trigger={<Popup content={`${isEdit ? (disabled || readOnly ? 'View' : 'Edit') : 'Add'} Health History`} trigger={<Button compact basic loading={busy} icon={{ name: icon ?? (disabled || readOnly ? 'eye' : (isEdit ? 'edit outline' : 'add square')), color: iconColor ?? 'blue' }} onClick={triggerOpen} />} />}
            >
                <Modal.Header>{isEdit ? 'Edit' : 'Add'} Health History{ previousHealthHistoryId == null ? <></> : <Button floated='right' basic onClick={handleCopyPrevious}>Copy Latest Answers</Button>}</Modal.Header>
                <Modal.Content image scrolling>                
                    <Container fluid>
                        {disabled || readOnly ? (
                            <Message negative>
                                <Message.Header>For viewing only. </Message.Header>
                                <p>Modifications have been turned off, a newer Health History has been entered</p>
                            </Message>
                        ) : null }
                        <Form loading={busy}>
                            {ItemDisplay()}
                            <Form.TextArea name='notes' label='Notes' value={notes ?? ''} onChange={(e, data) => setNotes(data.value)} disabled={disabled || readOnly} />                    
                        </Form>
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Save"
                        onClick={handleAdd}
                        positive
                        disabled={busy || disabled || readOnly}
                    />
                    <Button basic onClick={() => setOpen(false)} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default AddHealthHistory;
