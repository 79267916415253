import React, { useState, useEffect } from 'react'
import { Button, Grid, Modal, Container, Form, Table, Input, Divider, Header, Ref, Popup, Segment, Checkbox, StrictButtonProps } from 'semantic-ui-react'
import { useErrorDisplay } from './ErrorDisplay'
import AttributeDefinitionApi from '../apiClients/AttributeDefinitionApi'

import { EditorType, SourceType } from '../Enums'
import { IsEmpty } from '../Helpers'
export interface RuleEditorPopupProps
{
    value: string;
    shouldBeBoolean?: boolean;
    onAddEditAsync: (item: string) => Promise<void>;
    btnProps?: StrictButtonProps;
    trigger?: React.ReactNode;
    editorType?: EditorType;
    title?: string | React.ReactNode;
    sourceTypes?: SourceType[];
}
const RuleEditorPopup = ({ value, shouldBeBoolean = false, onAddEditAsync, btnProps = {}, trigger = null, editorType = EditorType.Rule, title = null, sourceTypes = [] }: RuleEditorPopupProps) => {
    const [inputRef, setInputRef] = useState(null);
    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState(true);
    const [modified, setModified] = useState(false);
    const { ErrorDisplay, setError } = useErrorDisplay();
    const [possibleVariables, setPossibleVariables] = useState(null);
    const [invalidVariablesError, setInvalidVariablesError] = useState<string>(null);
    const [searchText, setSearchText] = useState('');
    const [targetData, setTargetData] = useState('');
    const [showPrevious, setShowPrevious] = useState<boolean>(false);

    const api = new AttributeDefinitionApi();

    useEffect(() => {
        setInvalidVariablesError(editorType === EditorType.StringBuilder ? null : getInvalidVariablesError())
    }, [targetData, possibleVariables])

    const getInvalidVariablesError = () => {
        if (!possibleVariables || !targetData) 
            return null;

        const regex = editorType === EditorType.Rule ? /\[(.*?)\]/g : /\{(.*?)\}/g
        let matches = [...targetData.matchAll(regex)]

        const isValidVariable = (variable: string) =>
            (possibleVariables?.findIndex(x => x.Name.toLowerCase() === variable.toLowerCase()) > -1) ?? false;

        const invalidVariables = matches.filter(x => !isValidVariable(x[1])).map(x => x[0]);
        return invalidVariables.length > 0 ? `Invalid variable(s): ${invalidVariables.join(', ')}` : null
    }

    //Setup callback if it is passed in, default if not
    const onAddEditAsyncCallback = IsEmpty(onAddEditAsync) ? async () => { } : onAddEditAsync;

    const loadData = async () => {
        try {
            setBusy(true);
            if (possibleVariables === null) {
                let pa = await api.GetPossibleAttributeVariables();
                
                setPossibleVariables(pa)
            }
            setTargetData(value);
        }
        catch (error) {
            setError(true, error, "Failed to load the attribute data")
        }
        finally {
            setBusy(false);
        }
    }

    const handleSave = async () => {
        try {
            setBusy(true);
            
            if(editorType == EditorType.Rule && !IsEmpty(targetData))
            {
                let errors = await api.EvaluateRuleForErrors(targetData, shouldBeBoolean);
                if(errors.length > 0)
                {
                    setError(true, errors.join('\r\n'), 'Rule evaluation Error');
                    return;
                }
            }
            await onAddEditAsyncCallback(targetData);
            setOpen(false);
            setModified(false);
        }
        catch (error) {
            setError(true, error, "Failed to save the data")
        }
        finally {
            setBusy(false);
        }
    }

    const canSave = () =>
        modified &&
        !busy &&
        !invalidVariablesError

    const handleChange = (e, data) => {
        setModified(true);
        setTargetData(data.value);
    }

    const handleVariableSelect = (item) => {
        let start = inputRef.parentElement[0].selectionStart;
        let end = inputRef.parentElement[0].selectionEnd;
        if (start >= targetData.length) {
            if (editorType === EditorType.Rule)
                setTargetData(`${targetData} [${item.Name}]`);
            else if (editorType === EditorType.StringBuilder)
                setTargetData(`${targetData} {${item.Name}}`);
        }
        else {

            if (editorType === EditorType.Rule)
                setTargetData(targetData.splice(start, end - start, `[${item.Name}]`));
            else if (editorType === EditorType.StringBuilder)
                setTargetData(targetData.splice(start, end - start, `{${item.Name}}`));
        }
        setModified(true);
    }

    let filteredVariables = possibleVariables;
    if(!showPrevious)
    {
        filteredVariables = filteredVariables?.filter(x => !x.Name?.includes(":prev")) ?? [];
    }
    if(sourceTypes.length > 0)
    {
        filteredVariables = filteredVariables?.filter(x => sourceTypes.includes(x.SourceType)) ?? [];
    }
    if(!IsEmpty(searchText))
    {
        filteredVariables = filteredVariables?.filter(x => x.Name?.toLowerCase().includes(searchText.toLowerCase()) ?? false) ?? [];
    }
    
    return (
        <>
            <ErrorDisplay />
            <Modal
                open={open}
                onOpen={async () => {
                    setBusy(false);
                    setOpen(true);
                    await loadData();
                }}
                
                trigger={trigger ?? <Button icon={{ name: 'ellipsis horizontal', color: 'green' }} {...btnProps} />}
            >
                <Modal.Header>{title ?? (editorType === EditorType.Rule ? 'Edit Rule' : 'Design Value')}</Modal.Header>
                <Modal.Content scrolling>
                    <Container fluid>
                        <Form loading={busy}>
                            <Ref innerRef={setInputRef}>
                                <Form.TextArea
                                    fluid
                                    placeholder={editorType === EditorType.Rule ? 'Enter rule here...' : 'Enter replacement text here'}
                                    value={targetData}
                                    onChange={handleChange}
                                    error={invalidVariablesError}
                                />
                            </Ref>
                        </Form>
                    </Container>
                    <Divider />
                    <Segment attached='top'>
                        <Grid columns={3} padded={false}>
                            <Grid.Column>
                                <Header>Possible Variables</Header>
                            </Grid.Column>
                            <Grid.Column>
                                <Checkbox label='Show Previous?' checked={showPrevious} onChange={() => setShowPrevious(!showPrevious)} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input loading={busy} focus value={searchText} onChange={(e, data) => setSearchText(data.value)} placeholder='Search...' icon={{ name: 'search', color: 'blue' }} fluid />
                            </Grid.Column>
                        </Grid>
                    </Segment>
                    <div style={{ display: 'block', height: 300, overflowX: 'hidden', overflowY: 'auto' }}>
                    
                        
                        <Table className='TableFixedHeader'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Source</Table.HeaderCell>
                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                    <Table.HeaderCell>UOM</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {filteredVariables?.map((item) => (
                                    <Table.Row key={item.Name}>
                                        <Table.Cell style={{ width: '25px'}}><Button.Group compact basic size='tiny'><Popup content={editorType === EditorType.Rule ? `Insert [${item.Name}]` : `Insert {${item.Name}}`} trigger={<Button compact basic icon={{ name: 'add', color: 'blue' }} onClick={() => handleVariableSelect(item)} />} /></Button.Group></Table.Cell>
                                        <Table.Cell>{editorType === EditorType.Rule ? `[${item.Name}]` : `{${item.Name}}`}</Table.Cell>
                                        <Table.Cell>{item.SourceType}</Table.Cell>
                                        <Table.Cell>{item.DataType}</Table.Cell>
                                        <Table.Cell>{item.UOM}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Save"
                        onClick={handleSave}
                        positive
                        disabled={!canSave()}
                    />
                    <Button color='black' basic onClick={() => setOpen(false)} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default RuleEditorPopup;
