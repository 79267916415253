import React, { useState } from 'react'
import { Button, Modal, Form, Popup } from 'semantic-ui-react'
import { useErrorDisplay } from './ErrorDisplay'
import TemplatesApi from '../apiClients/TemplatesApi';

const AddTemplateRevisionDialog = ({ templateId, onAddSuccess }) => {
    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState();
    const { ErrorDisplay, setError } = useErrorDisplay();

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleAddClick = async () => {
        try {
            setBusy(true);
            // upload file and get back template slim
            var result = await new TemplatesApi().AddTemplateRevision(templateId, selectedFile);
            if (result.HasErrors) {
                setError(true, result.Errors.join('\n'), 'Unable to add template revision.');
            }
            else {
                handleClose();
                onAddSuccess(result.Result);
            }
        }
        catch (error) {
            setError(true, error, 'Failed to add a Template Revision');
        }
        finally {
            setBusy(false);
        }
    };

    const handleClose = () => {
        setSelectedFile(undefined);
        setOpen(false);
    };

    const triggerOpen = () => {
        setSelectedFile(undefined);
        setOpen(true);
    };
    return (
        <>
            <ErrorDisplay />
            <Modal
                open={open}
                onOpen={triggerOpen}
                onClose={handleClose}
                trigger={<Popup content='Upload revised document template' trigger={<Button compact basic icon={{ name: 'upload', color: 'orange' }} onClick={triggerOpen} />} />}
            >
                <Modal.Header>Upload new revision</Modal.Header>

                <Modal.Content image scrolling>
                    <Form loading={busy}>
                        <Form.Input label='Template File' type='file' onChange={handleFileChange} />
                    </Form>
                </Modal.Content>

                <Modal.Actions>
                    <Button
                        content="Upload"
                        onClick={handleAddClick}
                        disabled={!selectedFile || busy}
                        positive
                    />
                    <Button color='black' basic onClick={handleClose} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default AddTemplateRevisionDialog;