import React, {useState} from 'react'
import { Button, Modal, Form, Popup } from 'semantic-ui-react'
import { useErrorDisplay } from './ErrorDisplay'
import TemplatesApi from '../apiClients/TemplatesApi'
import DocumentApi from '../apiClients/DocumentApi'

const GeneratePatientDocumentPopup = ({ patientId, onAdd, icon = null, iconColor = null }) => {
    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const { ErrorDisplay, setError } = useErrorDisplay();

    const dApi = new DocumentApi();
    const tApi = new TemplatesApi();
    const handleCreateDocument = async () => {
        try
        {
            if(selectedTemplateId == null)
                throw "Please choose a template type";
            setBusy(true);
            var patientDocumentId = await dApi.GenerateDocument(patientId, selectedTemplateId);
            if(patientDocumentId === null)
                throw "Document id was not returned";
            dApi.GetDocument(patientDocumentId);
            await onAdd();
            setOpen(false);           
            setSelectedTemplateId(null);
            setTemplates([]);
        }
        catch(ex)
        {
            setError(true, ex, "Problems encountered while generating the document");
        }
        finally
        {
            setBusy(false); 
        }
    };

    const handleTemplateChange = (e, data) =>  {
        setSelectedTemplateId(data.value);
    }
    
    const triggerOpen = async () => {
        try
        {
            setBusy(true);
            setOpen(true);
            let tList =  await tApi.GetTemplates(false);
            if(tList.length === 1)
                setSelectedTemplateId(tList[0].TemplateId);
            if(tList !== null)
            {
                var tDisplay = tList.map(item => ({ key: item.TemplateId, value: item.TemplateId, text: item.Name }));
                setTemplates(tDisplay);
            }
        }
        catch(error)
        {
            setError(true, error, "Could not load templates");
        }
        finally
        {
            setBusy(false);
        }
    };
    
    return (
        <>
            <ErrorDisplay />
            <Modal
                size='tiny'
                open={open}
                onOpen={triggerOpen}
                trigger={<Popup content='Generate New Document' trigger={<Button compact basic loading={busy} icon={{ name: icon ?? 'add square', color: iconColor ?? 'blue' }} onClick={triggerOpen} />} />}
            >
                <Modal.Header>Generate Document for Patient</Modal.Header>
                <Modal.Content>
                    {/* <Dimmer active={busy} >
                        <Loader indeterminate inline='centered'>{busy.message}</Loader>
                    </Dimmer> */}
                    <Form loading={busy}>
                        <Form.Dropdown search selection label="Select a document type" fluid options={templates} value={selectedTemplateId} onChange={handleTemplateChange} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Create Document"
                        onClick={handleCreateDocument}
                        positive
                        disabled={busy}
                    />
                    <Button basic onClick={() => setOpen(false)} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default GeneratePatientDocumentPopup;
