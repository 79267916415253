import React, {useState, useEffect, useRef } from 'react'
import { Table, Icon, Header, Segment, Grid, Button } from 'semantic-ui-react'
import PatientApi, { LabItem } from '../apiClients/PatientApi';
import AddLab from './AddLab'
import ExecuteOnConfirm from './ExecuteOnConfirm'
import { ClientEvents, LabDisplayMode, Roles } from '../Enums'
import { useErrorDisplay } from './ErrorDisplay'
import moment from 'moment';
import { OnlyForRole, HasRole, IsEmpty } from '../Helpers';

const LabsDisplay = ({ patientId }) => {
    const [labs, setLabs] = useState<LabItem[]>([]);
    const [busy, setBusy] = useState(true);
    const { ErrorDisplay, setError } = useErrorDisplay();
    const initialized = useRef<boolean>(false);
    const api = new PatientApi();

    const loadLabs = async (patientId) => {
        try
        {
            setBusy(true);
            let ll = await api.GetLabList(patientId);
            setLabs(ll);
            console.log(`Loaded Labs, PatientId: ${patientId}`);
        }
        finally
        {
            setBusy(false);
        }
    }

    const labsAdded = async (patientId) => {
        await loadLabs(patientId);
        window.dispatchEvent(new CustomEvent(ClientEvents.refreshRiskFactors));
    };
    
    const deleteLab = async (labId) => {
        try
        {
            setBusy(true);
            await api.DeleteLab(patientId, labId);
            window.dispatchEvent(new CustomEvent(ClientEvents.refreshRiskFactors));
            loadLabs(patientId);
        }
        catch(ex)
        {
            setError(true, ex, "Can not remove lab");
        }
        finally
        {
            setBusy(false);
        }
    };

    useEffect(() => {
        if(!initialized.current)
        {
            initialized.current = true;
            loadLabs(patientId);
        }
      });

    return (
        <>
            <ErrorDisplay />
            <Segment color='purple' loading={busy} style={{maxHeight: '550px', display: 'grid'}}>
                <div style={{ overflowY: 'hidden', overflowX: 'hidden', height: '100%' }}>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as='h3'>
                                    <Icon name='lab' />
                                    <Header.Content>
                                        Client Testing
                                        <Header.Subheader>Add/View Client testing information</Header.Subheader>
                                    </Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column textAlign='right'>
                                {OnlyForRole(Roles.labs_create, <Button.Group compact basic size='tiny'><AddLab hoverText='Add Testing Request' mode={LabDisplayMode.Request} patientId={patientId} onAddLabs={labsAdded.bind(this)}/></Button.Group>)}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <br />
                    
                    <div style={{ overflowY: 'auto', overflowX: 'auto', verticalAlign: 'top', height: '87%'}}>                
                        <Table celled striped size='small' compact className='TableFixedHeader' verticalAlign='top'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell>Short Description</Table.HeaderCell>
                                    <Table.HeaderCell>Collected On</Table.HeaderCell>
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {labs.map((item, index) => (                                
                                    <Table.Row key={item.Id} style={{ color: item.AreRequestedValuesEntered ? null : 'red' }}>
                                        <Table.Cell style={{ width: '20px'}}>
                                            <Button.Group compact basic size='tiny'>
                                                <AddLab patientId={patientId} mode={LabDisplayMode.DataEntry} onAddLabs={labsAdded.bind(this)} iconColor='green' labId={item.Id} readOnly={!HasRole(Roles.labs_modify)} />
                                                {HasRole(Roles.labs_create) ? <AddLab hoverText='Edit Testing Request' patientId={patientId} mode={LabDisplayMode.Request} onAddLabs={labsAdded.bind(this)} icon={{ name: 'tasks', color: 'yellow' }} labId={item.Id} /> : null}
                                            </Button.Group>
                                        </Table.Cell>
                                        <Table.Cell>{item.ShortDescription}</Table.Cell>
                                        <Table.Cell>{IsEmpty(item.LabTakenDate) ? 'Requested' : moment(item.LabTakenDate).format('MM/DD/YYYY hh:mm A')}</Table.Cell>
                                        <Table.Cell style={{ width: '20px'}}>
                                            {OnlyForRole(Roles.labs_delete, (
                                                <Button.Group compact basic size='tiny'>                                            
                                                    <ExecuteOnConfirm displayText={`Are you sure you want to delete this Test`} hoverText='Delete' executeAsync={() => deleteLab(item.Id)} />                                            
                                                </Button.Group>
                                            ))}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </Segment>
        </>
    );
};

export default LabsDisplay;