import React, { useState, useEffect, useRef } from 'react'
import { Table, Segment, Grid, Header, Icon, Button, Modal, Dropdown } from 'semantic-ui-react'
import OrderApi, { DashboardOrder } from '../apiClients/OrderApi';
import { Roles } from '../Enums';
import GlobalVariables from '../GlobalVariables';
import { FormatDateTime, HasRole, IsEmpty, OnlyForRole } from '../Helpers';
import ExecuteOnConfirm from './ExecuteOnConfirm';
import OrderRequestManagementView from './OrderRequestManagementView';
import PatientDetail from './PatientDetail';
import Search from './Search';

const DashboardOrderDisplay = () => {
    function getDefaultDashboard() {
        if(HasRole(Roles.dashboard_clinician))
            return Roles.dashboard_clinician.toString();
        else if(HasRole(Roles.dashboard_employee)) {
            Roles.dashboard_employee.toString()
        } else if(HasRole(Roles.dashboard_coach)) {
            Roles.dashboard_coach.toString()
        }
        return '';
    }
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState<string>();
    const [open, setOpen] = useState(false);
    const initialized = useRef<boolean>(false);
    const [orderList, setOrderList] = useState<DashboardOrder[]>([]);
    const [selectedOrder, setSelectedOrder] = useState<DashboardOrder>();
    const [dashboardList, setDashboardList] = useState<string[]>([]);
    const [selectedDashboard, setSelectedDashboard] = useState<string>(getDefaultDashboard());

    const loadData: () => Promise<DashboardOrder[]> = async () => {
        setLoading(true);
        try {
            const response = await new OrderApi().GetOpenOrders();
            let dashboards = [];
            response.forEach(x => x.Dashboards.forEach(d => dashboards.push(d)));
            dashboards = dashboards.filter((value, index, self) => {
                return self.indexOf(value) === index
              });
            setDashboardList(GlobalVariables.roles.filter(x => x.startsWith('dashboard') && x !== 'dashboard_access'));
            setOrderList(response);
            return response;
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(initialized.current)
            return;
        initialized.current = true;
        loadData();
    }, []);

    const handleViewPatient = async (order: DashboardOrder) => {
        try
        {
            setLoading(true);
            setSelectedOrder(order);
            setOpen(true);
        }
        catch(error)
        {

        }
        finally
        {
            setLoading(false);
        }
    }

    const handleDeleteOrder = async (orderId: string) => {
        try
        {
            setLoading(true);
            await new OrderApi().DeleteOrder(orderId);
            await loadData();
            
        }
        catch(error)
        {
            
        }
        finally
        {
            setLoading(false);
        }
    }

    const handleRequestManagementChange = async (orderId: string) => {
        var orders = await loadData();

        //close the window if the change took it off the current dashboard
        if(orders.findIndex(x => x.Id == orderId) < 0)
            setOpen(false);
    }
    const filteredDashboard = IsEmpty(selectedDashboard) || dashboardList.length <= 1 ? [...orderList] : orderList.filter(x => x.Dashboards.includes(selectedDashboard));

    const filteredOrderList = IsEmpty(searchText) ? [...filteredDashboard] : filteredDashboard.filter(x => x.PatientName.toLowerCase().includes(searchText)
                                                                                || x.OrganizationName.toLowerCase().includes(searchText)
                                                                                || x.LocationName.toLowerCase().includes(searchText)
                                                                                || x.OrderType.toLowerCase().includes(searchText)
                                                                                || x.CurrentState.toLowerCase().includes(searchText));


    return (
        <>
            <Segment color='yellow' loading={loading} style={{maxHeight: '550px', display: 'grid' }}>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as='h3'>
                                <Icon name='cart' />
                                <Header.Content>
                                    Client Orders
                                    <Header.Subheader>Contains orders that require your attention!</Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            {dashboardList.length <= 1 ? null : <Dropdown labeled size='mini' selection search clearable options={dashboardList.map(x => {return { text: (x.replace('dashboard_','').charAt(0).toUpperCase() + x.replace('dashboard_','').slice(1)), value: x, key: x }})} value={selectedDashboard} onChange={(e, data) => setSelectedDashboard(data.value.toString())} />}
                            <Search onChange={nv => setSearchText(nv.toLowerCase())} style={{marginLeft: '10px', marginRight: '10px'}} />
                            <Button compact basic size='small' icon={{ name: 'refresh', color: 'blue' }} onClick={loadData} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Table celled striped sortable compact size='small' verticalAlign='top'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell sorted='descending' width='1' singleLine>Timestamp</Table.HeaderCell>
                            <Table.HeaderCell width={1} singleLine>Name</Table.HeaderCell>
                            <Table.HeaderCell>Organization</Table.HeaderCell>
                            <Table.HeaderCell>Location</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>State</Table.HeaderCell>
                            {dashboardList.length <= 1 ? null : <Table.HeaderCell>Dashboards</Table.HeaderCell>}
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {filteredOrderList.map(order => (
                            <Table.Row>
                                <Table.Cell style={{width: '20px'}}>
                                    <Button.Group compact basic size='tiny'>
                                        <Button compact size='tiny' loading={loading} basic icon={{ name: 'eye' }} onClick={() => handleViewPatient(order)} />
                                    </Button.Group>
                                </Table.Cell>
                                <Table.Cell singleLine>{FormatDateTime(order.OrderedTimestamp)}</Table.Cell>
                                <Table.Cell singleLine>{order.PatientName}</Table.Cell>
                                <Table.Cell>{order.OrganizationName}</Table.Cell>
                                <Table.Cell>{order.LocationName}</Table.Cell>
                                <Table.Cell>{order.OrderType}</Table.Cell>
                                <Table.Cell>{order.CurrentState}</Table.Cell>
                                {dashboardList.length <= 1 ? null : <Table.Cell>{order.Dashboards.map(x => (x.replace('dashboard_','').charAt(0).toUpperCase() + x.replace('dashboard_','').slice(1))).join(',')}</Table.Cell>}
                                <Table.Cell style={{width: '20px'}}>{OnlyForRole(Roles.orders_delete, <ExecuteOnConfirm displayText='Are you sure you want to delete this order?' hoverText='Delete Order from Client' executeAsync={() => handleDeleteOrder(order.Id)} />)}</Table.Cell>
                            </Table.Row>
                        ))}
                        
                    </Table.Body>
                </Table>
            </Segment>
            {IsEmpty(selectedOrder) ? null : (
                <Modal open={open} size='fullscreen'>
                    <Modal.Header>Order Details</Modal.Header>
                    <Modal.Content scrolling>
                        <OrderRequestManagementView orderId={selectedOrder.Id} onChange={handleRequestManagementChange} />
                        <PatientDetail patientId={selectedOrder.PatientId} showOrders={false} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' basic onClick={() => setOpen(false)} disabled={loading}>close</Button>
                    </Modal.Actions>
                </Modal>
            )}
        </>
    );
};

export default DashboardOrderDisplay;