import React, { useState } from 'react'
import { Button, Modal, Form, Popup } from 'semantic-ui-react'
import { useErrorDisplay } from './ErrorDisplay'
import DocumentApi from '../apiClients/DocumentApi';
import { IsNotEmpty } from '../Helpers';

const AddManualRevisionDialog = ({ patientId, onAddSuccess: onSaveSuccess }) => {
    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File>();
    const [docName, setDocName] = useState('');
    const { ErrorDisplay, setError } = useErrorDisplay();

    const getFilenameWithoutExt = (filename) => 
        filename ? filename.split('.').slice(0,-1).join('.') : '';

    const handleFileChange = (event) => {
        const prevFile = IsNotEmpty(selectedFile) ? selectedFile.name : null;
        const file = event.target.files[0];
        setSelectedFile(file);

        if(file?.name && (!docName || docName == getFilenameWithoutExt(prevFile))){
            setDocName(file.name.split('.').slice(0,-1).join('.'));
        }
    };

    const handleDocNameChange = (event) => {
        setDocName(event.target.value);
    };

    const handleAddClick = async () => {
        try {
            setBusy(true);
            // upload file and get back template slim
            var newDoc = await new DocumentApi().SaveManualRevision(patientId, docName, selectedFile);
            handleClose();
            onSaveSuccess(newDoc);
        }
        catch (error) {
            setError(true, error, 'Failed to add a Template Revision');
        }
        finally {
            setBusy(false);
        }
    };

    const handleClose = () => {
        setDocName(undefined);
        setSelectedFile(undefined);
        setOpen(false);
    };

    const handleOpen = () => {
        setSelectedFile(undefined);
        setOpen(true);
    }

    return (
        <>
            <ErrorDisplay />
            
            <Modal
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                trigger={<Popup content='Upload Revision' trigger={<Button compact basic icon={{ name: 'upload', color: 'blue'}} onClick={handleOpen} />} />}
            >
                <Modal.Header>Save manual document revision</Modal.Header>

                <Modal.Content image scrolling>
                    <Form loading={busy}>
                        <Form.Input label='Select File' type='file' onChange={handleFileChange} />
                        <Form.Input label='Document Name' type='text' value={docName} onChange={handleDocNameChange} />
                    </Form>
                </Modal.Content>

                <Modal.Actions>
                    <Button
                        content="Save"
                        onClick={handleAddClick}
                        disabled={!selectedFile || !docName || busy}
                        positive
                    />
                    <Button color='black' basic onClick={handleClose} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default AddManualRevisionDialog;