import React, {useState} from 'react'
import { Button, Modal, Form, Container, Popup, Input } from 'semantic-ui-react'
import { useErrorDisplay } from './ErrorDisplay'
import AttributeDefinitionApi from '../apiClients/AttributeDefinitionApi'
import { Roles, SourceType } from '../Enums'
import { HasRole, IsEmpty } from '../Helpers'
import RuleEditorPopup from './RuleEditorPopup'

interface AddEditAttributeSectionPopupProps
{
    sourceType: SourceType,
    attributeSectionId?: string,
    onAddEditAsync: (id: string) => Promise<void>;
}
const AddEditAttributeSectionPopup = ({ sourceType, attributeSectionId = null, onAddEditAsync = null } : AddEditAttributeSectionPopupProps) => {
    const dataScaffold = {
        Id: '',
        Name: '',
        SourceType: sourceType,
        DisplayRule: '',
        modified: false
    };

    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState(false);
    const { ErrorDisplay, setError } = useErrorDisplay();
    const [targetData, setTargetData] = useState({...dataScaffold});

    
    const api = new AttributeDefinitionApi();

    //Setup callback if it is passed in, default if not
    const onAddEditAsyncCallback = onAddEditAsync ?? (async (id) => { });

    const isEdit = !IsEmpty(attributeSectionId);

    

    const loadData = async () => {
        try
        {
            setBusy(true);
            if(isEdit)
            {
                var td = await api.GetAttributeSection(attributeSectionId);
                setTargetData(td);
            }
            else
            {
                setTargetData({...dataScaffold});
            }
        }
        catch(error)
        {
            setError(true, error, "Failed to load the attribute data")
        }
        finally
        {
            setBusy(false);
        }
    }

    const handleChange = (e, data) => {
        setTargetData({...targetData, [data.name]: data.value, modified: true });
    }
        
    const handleSave = async () => {
        try
        {
            let id = '';
            if(isEdit)
            {
                id = targetData.Id;
                await api.UpdateAttributeSection(attributeSectionId, targetData.Name, targetData.SourceType, targetData.DisplayRule);
            }
            else
            {
                id = await api.AddAttributeSection(targetData.Name, sourceType, targetData.DisplayRule);                
            }
            setBusy(true);
            setOpen(false);
            await onAddEditAsyncCallback(id);
        }
        catch(error)
        {
            setError(true, error, "Failed to save the attribute data")
        }
        finally
        {
            setBusy(false);
        }
    }

    const canSave = () =>
    {
        let ret = !busy;
        ret = ret && targetData.modified;
        ret = ret && targetData.Name.length > 5;

        return ret;
    }
    const triggerOpen = async () => {
        loadData();
        setOpen(true)
    };
    const showRule = sourceType === SourceType.HealthHistory || sourceType === SourceType.LabResult;
    return (
        <>
            <ErrorDisplay />
            <Modal
                open={open}
                onOpen={triggerOpen}
                trigger={<Popup content={`${isEdit ? 'Edit' : 'Add' } Attribute Section`} trigger={<Button basic compact loading={busy} icon={{ name: isEdit ? 'edit outline' : 'object ungroup outline', color: isEdit ? 'green' : 'blue'}} onClick={triggerOpen} />} /> }
            >
                <Modal.Header>{isEdit ? 'Edit Section' : 'Create New Section'}</Modal.Header>
                <Modal.Content image scrolling>
                    <Container fluid>
                        <Form loading={busy}>
                            <Form.Group widths='equal'>
                                <Form.Input label="Section Name" name='Name' value={targetData.Name} onChange={handleChange} error={targetData.Name.length < 5} />
                                {showRule ? (
                                    <Form.Field>
                                        <label>Display Rule</label>
                                        <Input value={targetData.DisplayRule ?? ''} action readOnly name='DisplayRule'>
                                            <input />
                                            <RuleEditorPopup title='Editing Section Display Rule' shouldBeBoolean={true} onAddEditAsync={async (value) => handleChange(null, { name: 'DisplayRule', value: value })} value={targetData.DisplayRule ?? ''} sourceTypes={[ SourceType.Other, SourceType.PatientVisit]} trigger={<Button icon={{ name: 'ellipsis horizontal', color: 'green' }} enabled={HasRole(Roles.settings_attribute_section_manage)}  />} />
                                        </Input>
                                    </Form.Field>
                                ) : null}
                            </Form.Group>
                        </Form>
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Save"
                        onClick={handleSave}
                        positive
                        disabled={!canSave()}
                    />
                    <Button color='black' basic onClick={() => setOpen(false)} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default AddEditAttributeSectionPopup;
