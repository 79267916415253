import React, { useState } from 'react'

import { Tab, Menu, Grid, Button } from 'semantic-ui-react'

//Components
import PatientSearchBar from '../components/PatientSearchBar'
import PatientDetail from '../components/PatientDetail'
import { Roles } from '../Enums'
import { OnlyForRole } from '../Helpers'

const Patients = () => {
    const [patients, setPatients] = useState([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const removeTab = (key) =>
    {
        const index = patients.findIndex(p => p.Id === key);
        const newPatients = [...patients];
        newPatients.splice(index, 1);
        setSelectedTabIndex(0);
        setPatients(newPatients);
    };
    const patientSelected = (patient) => {
        const index = patients.findIndex(p => p.Id === patient.Id);
        if(index >= 0)
        {
            setSelectedTabIndex(index);
            return;
        }
        const newPatients = [...patients];
        newPatients.push(patient);
        setPatients(newPatients);
        setSelectedTabIndex(newPatients.length - 1);
    };
    

    const handleTabChange = (e, data) => {
        setSelectedTabIndex(data.activeIndex);
    };

    const tabPanes = patients.map((patient, index) => ({
            key: patient.Id,
            menuItem: (
                <Menu.Item key={patient.Id} style={((index === selectedTabIndex) ? {backgroundColor: 'rgb(253, 254, 255)'} : {})}>
                    {patient.FirstName} {patient.LastName}
                    <Button.Group><Button icon='delete' circular id={patient.Id} style={{ marginLeft: '10px' }} compact size='tiny' basic onClick={(e) => { e.stopPropagation(); removeTab(patient.Id);}}/></Button.Group>
                </Menu.Item>
                ), 
            pane: (<Tab.Pane key={patient.Id} style={{ backgroundColor: 'rgb(253, 254, 255)' }} ><PatientDetail patientId={patient.Id} /></Tab.Pane>)
        })
    );
    
    return (
            <Grid columns={2} style={{ margin: '3px'}}>
                <Grid.Column width={4} style={{minWidth: '300px'}} >
                    {OnlyForRole(Roles.client_search, <PatientSearchBar setSelectedPatient={patientSelected.bind(this)} />)}
                </Grid.Column>
                <Grid.Column width={12}>
                    {patients.length === 0 ? <></> : <Tab menu={{ attached: true, tabular: true }} renderActiveOnly={false} panes={tabPanes} activeIndex={selectedTabIndex} onTabChange={handleTabChange} />}
                </Grid.Column>
            </Grid>
        )
}





export default Patients