import React from 'react';
import {useInView} from "react-intersection-observer";

///Got the concept from here:
///https://medium.com/@alvinnguyen116/virtual-and-infinite-scrolling-in-react-d56a05976cd2
///Adapted that concept to work with table rows.
function VirtualTableRow({children, height, rowStyle, ...props}) {
    const [ref, inView] = useInView();
    const style = {
        ...rowStyle,
        height: `${height}px`,
        overflow: 'hidden'       
    };
    return (
        <tr {...props} style={style} ref={ref}>
            {inView ? children : null}
        </tr>
    );
}

export default VirtualTableRow;