import React, {useState} from 'react'
import { Button, Container, Modal, Form, Popup } from 'semantic-ui-react'
import { IsEmpty, NumericDigitsOnly } from '../Helpers';
import { useErrorDisplay } from './ErrorDisplay'


const ChangeDisplayOrderPopup = ({ trigger = null, currentDisplayOrder, changeDisplayOrderAsync }) => {
    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState(false);
    const { ErrorDisplay, setError } = useErrorDisplay();
    const [displayOrder, setDisplayOrder] = useState(parseInt(currentDisplayOrder));


    const handleChange = (e, data) => {
        if(IsEmpty(data.value))
            return;
        let displayOrder = NumericDigitsOnly(data.value);
        setDisplayOrder(parseInt(displayOrder));
    }
        
    const handleSave = async () => {
        if(!canSave())
        {
            return;
        }
        try
        {
            setBusy(true);
            await changeDisplayOrderAsync(displayOrder);
            setOpen(false);
        }
        catch(error)
        {
            setError(true, error, "Failed to change display order")
        }
        finally
        {
            setBusy(false);
        }
    }

    const canSave = () => !busy && currentDisplayOrder !== displayOrder; 

    const triggerOpen = async () => {
        setDisplayOrder(currentDisplayOrder);
        setOpen(true)
    };

    return (
        <>
            <ErrorDisplay />
            <Modal
                size='mini'
                open={open}
                onOpen={triggerOpen}
                trigger={ trigger ?? <Popup content="Change Attribute Display Order" trigger={<Button compact basic loading={busy} icon={{ name: 'ordered list', color: 'orange'}} onClick={triggerOpen} />} />}
            >
                <Modal.Header>Change Display Order</Modal.Header>
                <Modal.Content image scrolling>
                    <Container fluid>
                        <Form loading={busy}>
                            <Form.Input label="Display Order" value={displayOrder} onChange={handleChange} />
                        </Form>
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Save"
                        onClick={handleSave}
                        positive
                        disabled={!canSave()}
                    />
                    <Button color='black' basic onClick={() => setOpen(false)} disabled={busy}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default ChangeDisplayOrderPopup;
