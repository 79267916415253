import React, {useState, useEffect, useRef } from 'react'
import { Table, Icon, Header, Segment, Grid, Button } from 'semantic-ui-react'
import PatientApi, { HealthHistoryListItem } from '../apiClients/PatientApi';
import AddHealthHistory from './AddHealthHistory'
import ExecuteOnConfirm from './ExecuteOnConfirm'
import { useErrorDisplay } from './ErrorDisplay'
import { ClientEvents, Roles } from '../Enums';
import HealthHistoryRequestLink from './HealthHistoryRequestLink';
import moment from 'moment';
import { OnlyForRole, HasRole } from '../Helpers';
import ConfirmButton from './ConfirmButton';

const HealthHistoryDisplay = ({ patientId }) => {
    const [healthHistory, setHealthHistory] = useState<HealthHistoryListItem[]>([]);
    const [lastHealthHistoryId, setLastHealthHistoryId] = useState(null);
    const initialized = useRef<boolean>(false);
    const [busy, setBusy] = useState(true);
    const { ErrorDisplay, setError } = useErrorDisplay();
    const api = new PatientApi();

    const loadHealthHistory = async (patientId) => {
        try
        {
            setBusy(true);
            let hh = await api.GetHealthHistoryList(patientId);
            console.log(`Loaded Health History, PatientId: ${patientId}`);
            if(hh.length > 0)
            {
                setLastHealthHistoryId(hh[0].Id);
            }
            else
            {
                setLastHealthHistoryId(null);
            }
            setHealthHistory(hh);
        }
        catch(error)
        {            
            
        }
        finally
        {
            setBusy(false);
        }
    }


    const healthHistoryAdded = async (patientId) => {
        await loadHealthHistory(patientId);
        window.dispatchEvent(new CustomEvent(ClientEvents.refreshRiskFactors));
    };

    const deleteHealthHistory = async (healthHistoryId) => {
        try
        {
            await api.DeleteHealthHistory(patientId, healthHistoryId);
            loadHealthHistory(patientId);
            window.dispatchEvent(new CustomEvent(ClientEvents.refreshRiskFactors));
        }
        catch(ex)
        {
            setError(true, ex, "Could not delete");
        }
    };

    useEffect(() => {
        if(!initialized.current)
        {
            initialized.current = true;
            loadHealthHistory(patientId);
        }
      });

    return (
        <>
            <ErrorDisplay />
            <Segment color='purple' loading={busy} style={{maxHeight: '550px', display: 'grid' }}>
                <div style={{ overflowY: 'hidden', overflowX: 'hidden', height: '100%' }}>
                    <Grid columns={2}>
                        <Grid.Column>
                            <Header as='h3'>
                                <Icon name='history' />
                                <Header.Content>
                                    Health History
                                    <Header.Subheader>Add/View Health History</Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            <Button.Group basic compact size='tiny'>
                                {OnlyForRole(Roles.healthhistory_create, <AddHealthHistory patientId={patientId} onAddHealthHistory={healthHistoryAdded.bind(this)} previousHealthHistoryId={lastHealthHistoryId} />)} 
                                {OnlyForRole(Roles.healthhistory_request_create, <HealthHistoryRequestLink patientId={patientId} />)}
                            </Button.Group>
                        </Grid.Column>
                    </Grid>
                    <br />
                    <div style={{ overflowY: 'auto', overflowX: 'auto', height: "87%"}}>            
                        <Table celled striped size='small' compact className='TableFixedHeader' verticalAlign='top'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell ></Table.HeaderCell>
                                    <Table.HeaderCell>Completed On</Table.HeaderCell>
                                    <Table.HeaderCell>Completed By</Table.HeaderCell>
                                    <Table.HeaderCell>Generated By Request</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {healthHistory.map((item, index) => (
                                    <Table.Row key={item.Id} style={item.InProgress ? { color: 'red'} : {}}>
                                        <Table.Cell style={{ width: '20px'}}>
                                            <Button.Group basic compact size='tiny'>
                                                <AddHealthHistory patientId={patientId} onAddHealthHistory={healthHistoryAdded.bind(this)} iconColor='green' healthHistoryId={item.Id} readOnly={index > 0 || !HasRole(Roles.healthhistory_modify) || (item.IsRequestGenerated && item.InProgress)} />
                                            </Button.Group>
                                        </Table.Cell>
                                        <Table.Cell style={{ width: '200px'}}>{moment(item.CreatedTimestamp).format('MM/DD/YYYY hh:mm A')}</Table.Cell>
                                        <Table.Cell>{item.LastModifiedUser}</Table.Cell>
                                        <Table.Cell>{item.IsRequestGenerated.toString()}</Table.Cell>
                                        <Table.Cell style={{ width: '20px'}}>
                                            <Button.Group basic compact size='tiny'> 
                                                {OnlyForRole(Roles.healthhistory_delete,  (                                      
                                                    <ConfirmButton message={`Are you sure you want to delete this health history`} hoverContent='Delete Health History Entry' icon={{ name: 'delete', color: 'red' }} compact size='small' basic onClick={() => deleteHealthHistory(item.Id)} />
                                                ))}
                                            </Button.Group>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </Segment>
        </>
    );
};

export default HealthHistoryDisplay;