import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import Keycloak from 'keycloak-js';
import GlobalVariables from './GlobalVariables';

if(process.env.NODE_ENV === 'development')
{
  localStorage.setItem('API_BASE_PATH', 'http://localhost:5000');
  //localStorage.setItem('API_BASE_PATH', 'https://app.amindforallseasons.com/ArmgaSys.AM4AS.WebService');
  localStorage.setItem('USER_MANAGEMENT_URL', 'https://app.amindforallseasons.com:8443/auth/admin/AMFAS/console/#/realms/AMFAS/users');
  localStorage.setItem('AUTH_PATH', 'https://app.amindforallseasons.com:8443/auth');
} else {
  localStorage.setItem('API_BASE_PATH', 'https://app.amindforallseasons.com/ArmgaSys.AM4AS.WebService');
  localStorage.setItem('USER_MANAGEMENT_URL', 'https://app.amindforallseasons.com:8443/auth/admin/AMFAS/console/#/realms/AMFAS/users');
  localStorage.setItem('AUTH_PATH', 'https://app.amindforallseasons.com:8443/auth');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

//keycloak init options
async function Init() {
let initOptions = {
  url: localStorage.AUTH_PATH, realm: 'AMFAS', clientId: 'AMFAS.Web', onLoad: 'check-sso'
}

const getBasename = path => {
  let ret = path.substr(0, path.lastIndexOf('/'));
  return ret;
};
let keycloak = Keycloak(initOptions);
///Setup keycloak events to capture the token for use in the api classes
keycloak.onAuthSuccess = () => {
  localStorage.setItem('token', keycloak?.token);
  console.log(keycloak?.token);
  //@ts-ignore
  let { roles } = keycloak['tokenParsed'] ?? {};
  GlobalVariables.roles = roles;
  console.log("Auth success, saving token");
};
keycloak.onAuthRefreshSuccess = () => {
  localStorage.setItem('token', keycloak?.token);
  //@ts-ignore
  let { roles } = keycloak['tokenParsed'] ?? {};
  GlobalVariables.roles = roles;
  console.log("Auth refresh success, saving token");
};

//@ts-ignore
keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  //React Render
  console.log(`Authenticated: ${auth}`);
  localStorage.setItem('token', keycloak?.token);
  //@ts-ignore
  let { roles } = keycloak['tokenParsed'] ?? {};
  GlobalVariables.roles = roles;
  ReactDOM.render(
    <BrowserRouter basename={getBasename(window.location.pathname)}>
      <App keycloak={keycloak}/>
    </BrowserRouter>, document.getElementById('root'));
  // localStorage.setItem("react-token", keycloak.token);
  // localStorage.setItem("react-refresh-token", keycloak.refreshToken);
  setInterval(() => {
      keycloak.updateToken(70).then((refreshed) => {
          if (refreshed) {
              localStorage.setItem('token', keycloak.token);
              console.debug('Token refreshed' + refreshed);
          } else {
              console.warn('Token not refreshed, valid for '
                  + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
          }
      }).catch(() => {
          console.error('Failed to refresh token');
      });
  }, 60000)

}).catch(() => {
  console.error("Authenticated Failed");
});
}

Init();