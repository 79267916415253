import React, { Component } from 'react'
import { Button, Form, Item, Divider, Segment, Header, Icon, Grid } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import AddPatientModal from './AddPatientModal'
import PatientApi, { PatientSearchPayload } from '../apiClients/PatientApi'
// import { useErrorDisplay } from './ErrorDisplay'
import { Roles } from '../Enums';
import { IsEmpty, OnlyForRole, NumericDigitsOnly } from '../Helpers';

function isEmpty(val) {
  return (val === undefined || val == null || val.length <= 0) ? true : false;
}

interface PatientSearchBarState extends PatientSearchPayload {
  isLoaded: boolean,
  RunningSearch: boolean,
  items: any[],
}

interface PatientSearchBarProps {
  setSelectedPatient: any
}

class PatientSearchBar extends Component<PatientSearchBarProps, PatientSearchBarState> {
  private scaffold: PatientSearchBarState = {
    isLoaded: false,
    DOB: null,
    LastName: null,
    Email: null,
    Phone: null,
    RunningSearch: false,      
    items: []
  };

  //private setError;
  constructor(props: PatientSearchBarProps) {
    super(props);
    this.loadPatients = this.loadPatients.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.state = {...this.scaffold};
  }

  private clearFields = () => {
    this.setState({...this.scaffold});
  }
  
  private loadPatients = async () => {
      this.setState({ RunningSearch: true, items: [] });
      try
      {
        let api = new PatientApi();
        var items = await api.SearchPatients({ ...this.state });
        
        this.setState({ items: items });
      }
      catch(ex)
      {
        //this.setError(true, ex, "Could not perform the search");
      }
      finally
      {
        this.setState({ isLoaded: true, items: items, RunningSearch: false });
      }
    };

  render() {
    const { isLoaded, items } = this.state;
    //const { ErrorDisplay, setError } = useErrorDisplay();
    //this.setError = setError;
    const PatientDisplay = () => 
        // there is an error
      isLoaded && isEmpty(items) ? <p>No Results</p> :   //search returned no results 
      !isLoaded ? <></> :  // search has not been run
      ( // search returned results 
        <>
          <h3>Results:</h3>          
          <Divider />
          <Item.Group divided>
              {(items === null) ? null : items.map((item, index) => (
                <Item key={item.Id} onClick={() => {
                  this.props.setSelectedPatient(item);
                }}>
                  {item.Gender == 'Male' ? <Item.Image size='tiny' src='https://react.semantic-ui.com/images/avatar/large/justen.jpg' /> : <Item.Image size='tiny' src='https://react.semantic-ui.com/images/avatar/large/jenny.jpg' /> }
                  <Item.Content verticalAlign='middle'>
                    <Item.Header>
                      {IsEmpty(item.MiddleName) ? `${item.FirstName} ${item.LastName}` : `${item.FirstName} ${item.MiddleName} ${item.LastName}`}
                    </Item.Header><br/>
                    <p style={{ marginTop: '5px'}}><b>DOB:</b> {new Date(item.DOB).toLocaleDateString("en-US")}</p>
                    {IsEmpty(item.Phone) ? null : <p style={{ marginTop: '5px'}}><b>Phone:</b> {item.Phone}</p>}
                    {IsEmpty(item.Email) ? null : <p style={{ marginTop: '5px'}}><b>Email:</b> {item.Email}</p>}
                  </Item.Content>
                </Item>
              ))}
            </Item.Group>
        </>
      );

    return (
      <>
        {/* <ErrorDisplay /> */}
        <Segment color='red'>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3'>
                  <Icon name='search' />                  
                  <Header.Content>
                      Client Search
                      <Header.Subheader>Search by criteria</Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <Button.Group basic compact size='tiny'>
                  {OnlyForRole(Roles.client_create, <AddPatientModal setSelectedPatient={this.props.setSelectedPatient}/>)}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider /> 
          <Form loading={this.state.RunningSearch} onSubmit={(e) => this.loadPatients()}>
            <Form.Group widths={2}>
              <Form.Input placeholder="Last Name"  label="Last Name" value={this.state.LastName ?? ''}  onChange={(e, data) => this.setState({ LastName: data.value == '' ? null : data.value })} fluid />          
              <SemanticDatepicker label='Date of Birth' name='DOB' onChange={(e, data) => this.setState({ DOB: data.value == null ? null : JSON.parse(JSON.stringify(data.value)) })} showToday={false} format='MM/DD/YYYY' value={this.state.DOB == null ? null : new Date(this.state.DOB)} />
            </Form.Group>
            <Form.Input fluid label='Phone' placeholder='Last 4 of phone...' value={this.state.Phone ?? ''} name='Phone' onChange={(e, data) => this.setState({ Phone: data.value == '' ? null : NumericDigitsOnly(data.value) })} />
            <Form.Input fluid label='Email' placeholder='Enter Email...' value={this.state.Email ?? ''} name='Email' onChange={(e, data) => this.setState({ Email: data.value == '' ? null : data.value })} />
            <Form.Button style={{visibility: 'hidden', padding:0}}/>
          </Form>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={this.clearFields} disabled={this.state.RunningSearch} basic negative>Clear</Button>
            <Button onClick={this.loadPatients} disabled={this.state.RunningSearch} positive primary>Search</Button>
          </div>
          
          
        </Segment>
        {isEmpty(items) ? <></> : (
          <Segment color='green'>
              {PatientDisplay()}
          </Segment>
        )}
      </>
    );
  }
}

export default PatientSearchBar
