import React, {useState, useEffect, useRef } from 'react'
import { Button, Form, Grid, Header, Icon, Message, Modal, Popup, Segment, Table } from 'semantic-ui-react';
import OrganizationApi, { LocationDetail, NameIdSlim, OrganizationDetail } from '../apiClients/OrganizationApi';
import { States } from '../Data/states';
import { Roles } from '../Enums';
import { AreEqual, HasRole, IsEmpty, IsNullOrUndefined, OnlyForRole } from '../Helpers';
import AddLocationPopup from './AddLocationPopup';
import ConfirmButton from './ConfirmButton';
import { useErrorDisplay } from './ErrorDisplay';
import ScrollView from './ScrollView';

export interface OrganizationViewProps
{
    organizationId: string;
    onChange?: (detail: OrganizationDetail) => Promise<void>
}
const OrganizationView = ({ organizationId, onChange = null }: OrganizationViewProps) => {
    const [busy, setBusy] = useState<boolean>(false);
    const [openAddUser, setOpenAddUser] = useState<boolean>(false);
    const { ErrorDisplay, setError } = useErrorDisplay();
    const currentOrganizationId = useRef<string>();
    const [orgDetail, setOrgDetail] = useState<OrganizationDetail>();
    const [originalOrgDetail, setOriginalOrgDetail] = useState<OrganizationDetail>();
    const [locations, setLocations] = useState<NameIdSlim[]>([]);
    const [selectedLocationId, setSelectedLocationId] = useState<string>();
    const [locationDetail, setLocationDetail] = useState<LocationDetail>();
    const [originalLocationDetail, setOriginalLocationDetail] = useState<LocationDetail>();
    const [showInactive, setShowInactive] = useState<boolean>(false);
    const [orgUsers, setOrgUsers] = useState<NameIdSlim[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string>();
    const [orgPossibleUsers, setOrgPossibleUsers] = useState<NameIdSlim[]>([]);

    const api = new OrganizationApi();

    const isOrganizationModified = !AreEqual(orgDetail, originalOrgDetail);
    const isLocationModified = !AreEqual(locationDetail, originalLocationDetail);
    
    useEffect(() => {
        if(IsEmpty(organizationId) || currentOrganizationId.current === organizationId)
            return;
        currentOrganizationId.current = organizationId;
        setLocationDetail(undefined);
        setSelectedLocationId(undefined);
        load(showInactive);
    });

    const load = async (includeInactive: boolean) => {
        try
        {
            setBusy(true);
            var od = await api.GetOrganizationDetail(organizationId);
            var locList = await api.GetLocationList(organizationId, includeInactive);
            var ou = await api.GetOrganizationUsers(organizationId);
            setOrgUsers(ou);
            setOriginalOrgDetail(od);
            setOrgDetail({...od});
            setLocations(locList);
        }
        catch(error)
        {
            setError(true, error, "Unable to load data");
        }
        finally
        {
            setBusy(false);
        }
    };

    const handleChange = (e, data) => {
        if(!HasRole(Roles.settings_Organization_modify))
            return;
        setOrgDetail({...orgDetail, [data.name]: data.value });
    }

    const handleBooleanChange = (e, data) => {
        if(!HasRole(Roles.settings_Organization_modify))
            return;
        setOrgDetail({...orgDetail, [data.name]: data.checked });
    }

    const handleLocationDetailChange = (e, data) => {
        if(!HasRole(Roles.settings_Organization_modify))
            return;
        setLocationDetail({...locationDetail, [data.name]: data.value });
    }

    const handleLocationDetailBooleanChange = (e, data) => {
        if(!HasRole(Roles.settings_Organization_modify))
            return;
        setLocationDetail({...locationDetail, [data.name]: data.checked });
    }

    const handleSave = async () => {
        if(!isOrganizationModified)
            return;
        try{
            setBusy(true);
            await api.UpdateOrganization(organizationId, { ...orgDetail });
            setOriginalOrgDetail({...orgDetail});
            if(!IsNullOrUndefined(onChange))
                await onChange(orgDetail);
        }
        catch(error)
        {
            setError(true, error, "Failed to save changes");
        }
        finally
        {
            setBusy(false);
        }
    }

    const handleSaveLocation = async () => {
        if(!isLocationModified)
            return;
        try{
            setBusy(true);
            await api.UpdateLocation(selectedLocationId, { ...locationDetail });
            setOriginalLocationDetail({...locationDetail});
            var locList = await api.GetLocationList(organizationId);
            setLocations(locList);
        }
        catch(error)
        {
            setError(true, error, "Failed to save changes");
        }
        finally
        {
            setBusy(false);
        }
    }

    const handleLocationSelection = async (id: string) : Promise<void> =>  {
        if(!HasRole(Roles.settings_Organization_modify))
            return;
        try
        {            
            setBusy(true);
            setSelectedLocationId(id);
            var detail = await api.GetLocationDetail(id);
            setOriginalLocationDetail(detail)
            setLocationDetail({ ...detail });
        }
        catch(error)
        {
            setError(true, error, 'Failed to get location Data');
        }
        finally
        {
            setBusy(false);
        }
    }
    const toggleInactive = async () => {
        let show = !showInactive;
        setShowInactive(show);
        await load(show);
    }
    const onAddLocation = () => load(showInactive);

    const onRemoveUser = async (userId: string) => {
        try {
            setBusy(true);
            await new OrganizationApi().RemoveUserFromOrganization(organizationId, userId)
            await load(showInactive);
        } catch (error) {
            setError(true, error, "Failed to remove the user");
        } finally {
            setBusy(false);
        }
    }

    const handleShowAddUser = async () => {
        
        try {
            setBusy(true);
            setSelectedUserId(null);
            var pu = await new OrganizationApi().GetPossibleOrganizationUsers();
            setOrgPossibleUsers(pu);
            setOpenAddUser(true);
        } catch (error) {
            setError(true, error, "Failed to get possible users");
        } finally {
            setBusy(false);
        }
    }

    const handleAddUser = async () => {
        try {
            setBusy(true);
            await new OrganizationApi().AddUserToOrganization(organizationId, selectedUserId);
            setOpenAddUser(false);
            await load(showInactive);
        } catch (error) {
            setError(true, error, "Failed to add user to organization");
        } finally {
            setBusy(false);
        }
    }

    return IsEmpty(orgDetail) ? null : (
        <>
            <ErrorDisplay />
            <Segment loading={busy} basic>
                <Grid>
                    <Grid.Column width={6}>
                        <Segment color='orange'>
                            <Form>
                                <Form.Input label='Organization Name' name='Name' placeholder='Type new org name here' value={orgDetail.Name ?? ''} onChange={handleChange} />
                                <Form.Checkbox label='Active Organization?' name='IsActive' checked={orgDetail.IsActive} onChange={handleBooleanChange} />
                            </Form>
                            <Segment color='blue'>
                                <ScrollView height='300px' content={
                                    <Table>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='2'>
                                                    Organization Users
                                                    <Button compact basic size='mini' floated='right' icon={{ name: 'add', color: 'blue' }} onClick={handleShowAddUser} />
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {orgUsers.map(ou => (
                                                <Table.Row key={ou.Id}>
                                                    <Table.Cell>{ou.Name}</Table.Cell>
                                                    <Table.Cell style={{ width: '20px'}}><ConfirmButton message='Are you sure you want to remove this user?' onClick={() => onRemoveUser(ou.Id)} hoverContent='Remove user from Organization' icon={{ name: 'delete', color: 'red'}} basic size='mini' compact /></Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                } />
                            </Segment>
                            {isOrganizationModified ? <Message negative content={(
                                <Grid columns={2}>
                                    <Grid.Column>
                                        <Header as='h3'>Please save!</Header>
                                    </Grid.Column>
                                    <Grid.Column textAlign='right'>
                                        <Button width={2} content='Save' color='green' onClick={handleSave} />
                                    </Grid.Column>
                                </Grid>                    
                            )}  /> : null}
                            <Modal open={openAddUser} size='small'>
                                <Modal.Header>Add User to Organization</Modal.Header>
                                <Modal.Content>
                                    {orgPossibleUsers.length > 0 ? 
                                        <ScrollView height='300px' content={
                                            <Table className='TableFixedHeader'>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>Unassigned Users</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {orgPossibleUsers.map(pu => (
                                                        <Table.Row key={pu.Id}>
                                                            <Table.Cell onClick={() => setSelectedUserId(pu.Id)} active={selectedUserId === pu.Id}>{pu.Name}</Table.Cell>
                                                        </Table.Row>
                                                    ))}
                                                    
                                                </Table.Body>
                                            </Table>
                                        } />
                                    : null}
                                    {orgPossibleUsers.length === 0
                                                    ? <Message negative content='No users' />
                                                    : null}
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        content="Add"
                                        onClick={handleAddUser}
                                        positive
                                        basic
                                        disabled={busy || IsEmpty(selectedUserId)}
                                    />
                                    <Button color='red' onClick={() => setOpenAddUser(false)} disabled={busy}>Cancel</Button>
                                </Modal.Actions>
                            </Modal>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Segment color='blue'>
                            <Grid divided>
                                <Grid.Column width={7}>
                                    <ScrollView height='350px' style={{overflowX: 'hidden'}} content={(
                                        <Table className='TableFixedHeader'>
                                            <Table.Header>
                                                <Table.HeaderCell>
                                                <Grid columns={2}>
                                                    <Grid.Column>
                                                        <Header as='h3' >
                                                            <Icon name='building outline' />
                                                            <Header.Content >
                                                                Locations
                                                            </Header.Content>                    
                                                        </Header>
                                                    </Grid.Column>
                                                    <Grid.Column textAlign='right'>
                                                        <Button.Group size='mini' compact basic>                                                            
                                                            <Popup content='Show/Hide Inactive Locations' trigger={<Button toggle compact icon={{ name: 'eye', color: 'orange'}} active={showInactive} onClick={() => toggleInactive()} />} />
                                                            {OnlyForRole(Roles.settings_Organization_create, <AddLocationPopup organizationId={organizationId} onChange={onAddLocation}/>)}
                                                        </Button.Group>
                                                    </Grid.Column>
                                                </Grid>
                                                </Table.HeaderCell>
                                            </Table.Header>
                                            <Table.Body>
                                                {locations.map((item, i) => (
                                                    <Table.Row key={item.Id} active={selectedLocationId === item.Id} onClick={() => handleLocationSelection(item.Id)}>
                                                        <Table.Cell><span style={item.IsActive ? null : { color: 'red' }}>{item.Name}</span></Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    )} />
                                </Grid.Column>
                                <Grid.Column width={9}>
                                    {IsEmpty(locationDetail) ? null : (
                                        <Form loading={busy}>
                                            <Form.Group>
                                                <Form.Input width={12} label='Location Name' name='Name' placeholder='Type new location here' value={locationDetail.Name ?? ''} onChange={handleLocationDetailChange} error={IsEmpty(locationDetail.Name)} />
                                                <Form.Checkbox width={4} label='Active Location?' name='IsActive' checked={locationDetail.IsActive} onChange={handleLocationDetailBooleanChange} />
                                            </Form.Group>                                

                                            <Form.Input label='Street Address' name='Addr1' placeholder='ie... 123 Main St' value={locationDetail.Addr1 ?? ''} onChange={handleLocationDetailChange} />                            
                                            <Form.Input label='Street Address 2' name='Addr2' placeholder='' value={locationDetail.Addr2 ?? ''} onChange={handleLocationDetailChange} />
                                            <Form.Group widths='equal'>
                                                <Form.Input fluid label='City' name='City' placeholder='' value={locationDetail.City ?? ''} onChange={handleLocationDetailChange} />
                                                {/* <Form.Input fluid label='State' name='State' placeholder='' value={locationDetail.State ?? ''} onChange={handleLocationDetailChange} /> */}
                                                <Form.Select label='State' name='State' value={locationDetail.State} options={States.map((s, i) => { return { key: s.abbreviation, text: s.name, value: s.abbreviation }})} onChange={handleLocationDetailChange} />
                                                <Form.Input fluid label='Zip' name='PostalCode' placeholder='' value={locationDetail.PostalCode ?? ''} onChange={handleLocationDetailChange} />
                                            </Form.Group>
                                            <Form.Input label='Country' name='Country' placeholder='' value={locationDetail.Country ?? ''} onChange={handleLocationDetailChange} />
                                            {isLocationModified ? <Message negative content={(
                                                <Grid columns={2}>
                                                    <Grid.Column>
                                                        <Header as='h3'>Please save!</Header>
                                                    </Grid.Column>
                                                    <Grid.Column textAlign='right'>
                                                        <Button width={2} content='Save Changes' color='green' onClick={handleSaveLocation} />
                                                    </Grid.Column>
                                                </Grid>                    
                                            )}  /> : null}
                                        </Form>
                                    )}
                                </Grid.Column>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
                
               
            </Segment>
        </>
    )
}

export default OrganizationView;